import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-diagrams';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import {OrGateModel} from "./OrGateModel";
import {OrGateWidget} from "./OrGateWidget";

export class OrGateNodeFactory extends AbstractReactFactory<OrGateModel, DiagramEngine> {
    constructor() {
        super('xor-gate-node');
    }

    generateModel(initialConfig: any) {
        return new OrGateModel();
    }

    generateReactWidget(event: any): React.ReactElement {
        return <OrGateWidget engine={this.engine as DiagramEngine} node={event.model} />;
    }
}