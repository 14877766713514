import React, {ChangeEvent, FormEvent} from "react";


interface InputProps {
    label: string
    onChange?: (value: string) => void
    validate?: (value: string) => boolean
    type?: string
    placeholder?: string
    initialValue?: string
    value: string
}

interface InputState {
    valid: boolean
}

export class Input extends React.Component<InputProps, InputState> {
    constructor(props) {
        super(props);

        const initialValue = props.initialValue ? props.initialValue : '';

        this.state = {
            valid: props.validate ? props.validate(initialValue) : true
        }
    }

    handleChange(e: FormEvent<HTMLInputElement>) {
        const value = e.target['value'];
        if (this.props.onChange) {
            this.props.onChange(value);
        }
        if (this.props.validate) {
            this.setState({
                valid: this.props.validate(value)
            });
        }
    }

    render() {
        return <div className={'input-container'}>
            <span className={'label'}>{this.props.label}</span>
            <input type={this.props.type} value={this.props.value} className={this.state.valid ? '': 'invalid'}
                   placeholder={this.props.placeholder}
                   onInput={(e) => this.handleChange(e)}/>
        </div>
    }
}

