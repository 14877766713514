// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pipeline-container {
    width: 100%;
    height: 100%;

    background: var(--background);

    display: flex;
    flex-direction: column;
    align-content: stretch;
    align-items: stretch;
}

.pipeline-container .stage-content {
    flex-grow: 1;
    padding: 20px 10px;

    min-height: 0;
}

.pipeline-container .footer {
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;

    height: 40px;

    padding: 20px 10px;
}

.pipeline-container .footer .footer-inner {
    position: absolute;

    display: flex;
    flex-direction: row;

    width: calc(100% - 20px);
    height: 40px;
}

.pipeline-container .footer .back-holder {
    position: absolute;

    margin-left: calc(50% - 142px);
}



`, "",{"version":3,"sources":["webpack://./src/css/pipeline.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;IAEZ,6BAA6B;;IAE7B,aAAa;IACb,sBAAsB;IACtB,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,kBAAkB;;IAElB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,kBAAkB;;IAElB,YAAY;;IAEZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;;IAElB,aAAa;IACb,mBAAmB;;IAEnB,wBAAwB;IACxB,YAAY;AAChB;;AAEA;IACI,kBAAkB;;IAElB,8BAA8B;AAClC","sourcesContent":[".pipeline-container {\r\n    width: 100%;\r\n    height: 100%;\r\n\r\n    background: var(--background);\r\n\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-content: stretch;\r\n    align-items: stretch;\r\n}\r\n\r\n.pipeline-container .stage-content {\r\n    flex-grow: 1;\r\n    padding: 20px 10px;\r\n\r\n    min-height: 0;\r\n}\r\n\r\n.pipeline-container .footer {\r\n    flex-grow: 0;\r\n    flex-shrink: 0;\r\n    position: relative;\r\n\r\n    height: 40px;\r\n\r\n    padding: 20px 10px;\r\n}\r\n\r\n.pipeline-container .footer .footer-inner {\r\n    position: absolute;\r\n\r\n    display: flex;\r\n    flex-direction: row;\r\n\r\n    width: calc(100% - 20px);\r\n    height: 40px;\r\n}\r\n\r\n.pipeline-container .footer .back-holder {\r\n    position: absolute;\r\n\r\n    margin-left: calc(50% - 142px);\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
