import {SchemaEntry} from "../TableSchema";
import React from "react";
import RemoveIcon from "@mui/icons-material/Remove";


interface ColumnDeleteButtonProps {
    // Called when the column is deleted.
    onDeleteColumn: () => void
}


export class ColumnDeleteButton extends React.Component<ColumnDeleteButtonProps> {
    render() {
        return <div className={'delete-button'} onClick={() => this.props.onDeleteColumn()}>
            <div className={'button-body'}>
                <RemoveIcon/>
            </div>
        </div>
    }
}