export enum Height {
    Lowest, Low, Medium, High, Highest
}

export function getSurfaceColor(height: Height): string {
    switch (height) {
        case Height.Lowest:
            return 'var(--surface-container-lowest)'
        case Height.Low:
            return 'var(--surface-container-low)'
        case Height.Medium:
            return 'var(--surface-container)'
        case Height.High:
            return 'var(--surface-container-high)'
        case Height.Highest:
            return 'var(--surface-container-highest)'
    }
}

export function getShadowClass(height: Height): string {
    switch (height) {
        case Height.Lowest:
        case Height.Low:
        case Height.Medium:
            return 'shadow-1'
        case Height.High:
            return 'shadow-2'
        case Height.Highest:
            return 'shadow-3'
    }
}

