import React from "react";
import {PipelineStage, Navigator} from "../navigator";
import {Button, MaterialColor} from "../basic-components";
import { ReactComponent as Logo } from "../assets/logo.svg";


export interface AbstractPipelineState {
    selectedStage: number
    pipelineStages: PipelineStage[]
    canProceed: boolean
}


export abstract class AbstractPipelineWidget<P, S extends AbstractPipelineState>
    extends React.Component<P, S> {
    constructor(props) {
        super(props);

        this.state = this.getInitialState();
        this.updateCanProceed();
    }

    abstract getInitialState(): S

    get numberOfStages() {
        return this.stages.length;
    }
    get currentStage() {
        return this.state.selectedStage;
    }
    get stages() {
        return this.state.pipelineStages;
    }
    get isFirstStage() {
        return this.currentStage === 0;
    }
    get isLastStage() {
        return this.currentStage === this.numberOfStages - 1;
    }
    get canProceed() {
        return this.state.canProceed;
    }

    // Based on the current state, compute if it's possible to proceed.
    // Note that this function must be called to compute its corresponding state value.
    determineCanProceed(): boolean {
        return true;
    }

    updateCanProceed() {
        const canProceed = this.determineCanProceed();

        const stagesCopy = this.stages.map((s) => {
            return {stageName: s.stageName, accessible: s.accessible}
        });

        if (canProceed && !this.isLastStage) {
            stagesCopy[this.currentStage + 1].accessible = true;
        } else {
            for (let i = this.currentStage + 1; i < this.numberOfStages; i++) {
                stagesCopy[i].accessible = false;
            }
        }

        this.setState({
            canProceed: canProceed,
            pipelineStages: stagesCopy
        });
    }

    onStageChange(previous: number, current: number) {

    }

    handleStageChange(index: number) {
        const previousStage = this.state.selectedStage;
        this.setState({
            selectedStage: index
        }, () => {
            this.onStageChange(previousStage, index);
            this.updateCanProceed();
        });
    }

    abstract renderStage(stage: number);

    renderFooter() {
        return <div className={'footer'}>
            <div className={'footer-inner'}>
                {this.isFirstStage ? '' : <Button
                    content={'Previous Stage'}
                    color={MaterialColor.Primary}
                    onClick={() => this.handleStageChange(this.currentStage - 1)}
                />}
                <div style={{flexGrow: 1}}/>
                {this.isLastStage ? '' : <Button
                    content={'Next Stage'}
                    disabled={!this.state.canProceed}
                    color={MaterialColor.Primary}
                    onClick={() => this.handleStageChange(this.currentStage + 1)}
                />}
            </div>
            <div className={'back-holder'}>
                <a href={'/'}>
                    <Logo width={''} height={''} style={{
                        height: '40px'
                    }}/>
                </a>
            </div>
        </div>
    }

    render() {
        return <div className={'pipeline-container'}>
            <Navigator stages={this.stages} selectedStage={this.currentStage}
                       onStageChange={(stage) => this.handleStageChange(stage)}/>
            <div className={'stage-content'}>
                {this.renderStage(this.currentStage)}
            </div>
            {this.renderFooter()}
        </div>
    }
}
