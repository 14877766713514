import {getColorString, MaterialColor} from "./MaterialColor";
import React, {CSSProperties} from "react";
import {Card} from "./Card";
import {Height} from "./Height";


interface ListItemContent {
    color: MaterialColor,
    title: string,
    subtitle?: string
}

interface ListItemProps extends ListItemContent{
    selected: boolean,
    onClick?: () => void
}

class ListItem extends React.Component<ListItemProps> {
    render() {
        return <div className={`list-item ${getColorString(this.props.color)} ${this.props.selected ? 'selected' : ''}`}
                    onClick={() => { if (this.props.onClick) this.props.onClick(); }}>
            <div className={'item-title'}>{this.props.title}</div>
            {this.props.subtitle ? <div className={'item-subtitle'}>{this.props.subtitle}</div> : ''}
        </div>
    }
}

interface ListSelectorProps {
    items: ListItemContent[],
    onSelectionChange?: (selection: number) => void,
    title: string,
    style?: CSSProperties,
    selectedItem: number
}

interface ListSelectorState {
    // -1 if nothing is selected.
}

export class ListSelector extends React.Component<ListSelectorProps, ListSelectorState> {
    constructor(props) {
        super(props);
    }

    handleSelect(index: number) {
        if (this.props.onSelectionChange) {
            this.props.onSelectionChange(index)
        }
    }

    render() {
        return <Card height={Height.Medium} color={MaterialColor.Normal} title={this.props.title}
                    bodyStyle={{ paddingLeft: 0, paddingRight: 0 }} cardStyle={this.props.style}>
            <div className={'list-container'}>
                {this.props.items.map((item, index) =>
                    <ListItem {...item} selected={index === this.props.selectedItem}
                              onClick={() => this.handleSelect(index)}/>)}
            </div>
        </Card>
    }
}


