import React from 'react';
import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {LandingPage} from "./landing/Landing";
import {SelectModelPage} from "./SelectModelPage";
import {DPGAPipelineWidget} from "./dpga-pipeline";
import {WTAPipelineWidget} from "./wta-pipeline";

function App() {
  return <BrowserRouter>
    <Routes>
      <Route path={'/'}>
        <Route index element={<LandingPage/>}/>
        <Route path={'models'} element={<SelectModelPage/>}/>
        <Route path={'dpga'} element={<DPGAPipelineWidget/>}/>
        <Route path={'wta'} element={<WTAPipelineWidget/>}/>
      </Route>
    </Routes>
  </BrowserRouter>;
}

export default App;
