import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-diagrams';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import {XOrGateModel} from "./XOrGateModel";
import {XOrGateWidget} from "./XOrGateWidget";

export class XOrGateNodeFactory extends AbstractReactFactory<XOrGateModel, DiagramEngine> {
    constructor() {
        super('xor-gate-node');
    }

    generateModel(initialConfig: any) {
        return new XOrGateModel();
    }

    generateReactWidget(event: any): React.ReactElement {
        return <XOrGateWidget engine={this.engine as DiagramEngine} node={event.model} />;
    }
}