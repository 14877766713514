import React from "react";
import {Card, ListEditor, MaterialColor} from "../basic-components";
import {Height} from "../basic-components/Height";


const inputsLimit = 8;
const outputsLimit = 4;


interface InputsOutputsFormsProps {
    inputs: string[]
    outputs: string[]
    onInputsUpdate: (inputs: string[]) => void
    onOutputsUpdate: (outputs: string[]) => void
    onValidityUpdate: (valid: boolean) => void
}


interface InputsOutputsFormsState {
    violations: string[]
}


export class InputsOutputsForms extends React.Component<InputsOutputsFormsProps, InputsOutputsFormsState> {
    constructor(props) {
        super(props);

        this.state = {
            violations: []
        };
    }

    static determineViolations(inputs: string[], outputs: string[]): string[] {
        const violations = [];

        if (inputs.length === 0) {
            violations.push('Missing inputs. ');
        }
        if (outputs.length === 0) {
            violations.push('Missing outputs. ');
        }

        const inputsTrimmed = inputs.map((x) => x.trim());
        if (inputsTrimmed.some((x) => x.length === 0)) {
            violations.push('Empty input names. ');
        }

        const outputsTrimmed = outputs.map((x) => x.trim());
        if (outputsTrimmed.some((x) => x.length === 0)) {
            violations.push('Empty output names. ');
        }

        const inputSet = new Set(inputsTrimmed);
        if (inputSet.size < inputsTrimmed.length) {
            violations.push('Duplicate input names.');
        }

        const outputSet = new Set(outputsTrimmed);
        if (outputSet.size < outputsTrimmed.length) {
            violations.push('Duplicate output names.');
        }

        const forbidden = ['and', 'not', 'xor', 'or'];
        if (inputs.concat(outputs).some((x) => forbidden.some((y) => x.toLowerCase().includes(y)))) {
            violations.push('Pin names may not contain any variation of and, not, xor, and or.')
        }

        return violations;
    }

    static getDerivedStateFromProps(props: InputsOutputsFormsProps, state: InputsOutputsFormsState) {
        const violations = InputsOutputsForms.determineViolations(props.inputs, props.outputs);

        if (violations.length !== state.violations.length) {
            props.onValidityUpdate(violations.length === 0);
        }

        return {
            violations: violations
        };
    }

    handleInputsChange(newInputs: string[]) {
        this.props.onInputsUpdate(newInputs);
    }
    handleOutputsChange(newOutputs: string[]) {
        this.props.onOutputsUpdate(newOutputs);
    }

    renderErrorCard() {
        return <Card height={Height.Medium} color={MaterialColor.Error} title={'Invalid Inputs'}>
            <ul style={{marginLeft: '20px'}}>
                {this.state.violations.map((violation) => <li>{violation}</li>)}
            </ul>
        </Card>
    }

    renderInfoBar() {
        return <div style={{
            width: '220px',
            flexGrow: 0,
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            gap: '20px'
        }}>
            <Card height={Height.Medium} color={MaterialColor.Normal} title={'Inputs and Outputs'}>
                Specify the input pins and output pins of your logic circuit.
            </Card>
            {this.state.violations.length > 0 ? this.renderErrorCard() : ''}
        </div>
    }

    render() {
        return <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            alignContent: 'stretch',
            gap: '20px'
        }}>
            <ListEditor items={this.props.inputs} onListChange={(i) => this.handleInputsChange(i)}
                        title={"Circuit Inputs"} maxListSize={inputsLimit} style={{flexGrow: 1}}/>
            <ListEditor items={this.props.outputs} onListChange={(i) => this.handleOutputsChange(i)}
                        title={"Circuit Outputs"} maxListSize={outputsLimit} style={{flexGrow: 1}}/>
            {this.renderInfoBar()}
        </div>
    }
}

