import * as React from "react";
import {DiagramEngine} from "@projectstorm/react-diagrams";

interface ErrorWidgetProps{
    errorID: string;
    errorCode: string;
    engine: DiagramEngine;
    errorString: string;
    removeError: (errorID: string) => void;
}

export class ErrorWidget extends React.Component<ErrorWidgetProps> {
    onClick() {
        this.props.removeError(this.props.errorID);
    }

    componentDidMount() {
        setTimeout(() => {
            this.props.removeError(this.props.errorID);
        }, 5000);
    }

    render() {
        return (
            <div className = "error-widget" onClick={() => this.onClick()}>
                <div className = "error-message">
                    {this.props.errorString}
                </div>
            </div>
        )
    }
}