import React from "react";
import {Button, MaterialColor} from "../basic-components";
import {Height} from "../basic-components/Height";
import {CreditsWidget} from "./CreditsWidget";
import {credits} from "./credits";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as OrgLogo } from "../assets/org_logo.svg";


interface LandingPageProps {}


interface LandingPageState {
    showCredits: boolean
}


export class LandingPage extends React.Component<LandingPageProps, LandingPageState> {
    constructor(props) {
        super(props);

        this.state = {
            showCredits: false
        }
    }

    handleShowCredits() {
        this.setState({
            showCredits: true
        });
    }
    handleHideCredits() {
        this.setState({
            showCredits: false
        })
    }

    renderMainLayer() {
        return <div className={'main-layer'}>
            <div className={'main-layer-content'}>
                <Logo style={{
                    maxWidth: '80%'
                }}/>
                <div className={'subtitle'}>
                    iGEM McGill 2024 Software
                </div>
                <Button content={"Build DNA Circuit"} color={MaterialColor.Primary}
                        height={Height.Highest} href={'/models'}/>
                <Button content={"GitLab"} color={MaterialColor.Secondary}
                        height={Height.Highest} href={'https://gitlab.igem.org/2024/software-tools/mcgill'}/>
                <Button content={"Wiki"} color={MaterialColor.Tertiary}
                        height={Height.Highest} href={'https://2024.igem.wiki/mcgill/software'}/>
            </div>
            <div className={'main-layer-footer'}>
                <div className={'inner-footer'}>
                    <OrgLogo style={{
                        maxHeight: '50px'
                    }}/>
                </div>
                <span className={'credit-button'} onClick={() => this.handleShowCredits()}>
                    Libraries Used
                </span>
            </div>
        </div>
    }

    renderOverlayBackground() {
        return <div className={'overlay-background-layer'} onClick={() => this.handleHideCredits()}/>
    }
    renderOverlayContent() {
        return <CreditsWidget entries={credits}/>
    }

    render() {
        return <div className={'landing-container'}>
            {this.renderMainLayer()}
            {this.state.showCredits ? this.renderOverlayBackground() : ''}
            {this.state.showCredits ? this.renderOverlayContent() : ''}
        </div>
    }
}

