import * as React from 'react';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import {LogicGateModel} from "./LogicGateModel";
import {LogicPortWidget} from "../logic-components/LogicPortWidget";

export interface LogicGateWidgetProps {
    node: LogicGateModel;
    engine: DiagramEngine;
}

export interface LogicGateWidgetState {}

export class LogicGateWidget extends React.Component<LogicGateWidgetProps, LogicGateWidgetState> {
    constructor(props: LogicGateWidgetProps) {
        super(props);
        this.state = {};

        // Update the appearance once the model has updated.
        props.node.registerListener({
            eventDidFire: (event) => {
                if (event.function === 'logicUpdate') {
                    this.forceUpdate();
                }
            },
            selectionChanged: (e) => {
                this.forceUpdate()
            }
        });
    }

    render() {
        return (
            <div className={`logic-gate`}>
                <div className={'body-layer'}>
                    <div className={ `logic-gate-body ${this.props.node.bodyClass} ${this.selectedStyleClass}` } />
                </div>
                <div className={'port-layer'}>
                    <div className="input-ports">
                        <LogicPortWidget engine={this.props.engine} model={this.props.node.in1}/>
                        <div className={"filler"}/>
                        <LogicPortWidget engine={this.props.engine} model={this.props.node.in2}/>
                    </div>
                    <div className={'filler'}/>
                    <div className="output-ports">
                        <LogicPortWidget engine={this.props.engine} model={this.props.node.out}/>
                    </div>
                </div>
            </div>
        );
    }

    get selectedStyleClass(): string {
        if (this.props.node.isSelected()) {
            return 'logic-gate-selected';
        }
        return '';
    }
}