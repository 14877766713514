import React, {CSSProperties, ReactNode} from "react";


interface TabbedViewProps {
    tabNames: string[],
    renderTab: (tab: number) => string | ReactNode | ReactNode[],
    onTabSelected?: (tab: number) => void
    cardStyle?: CSSProperties
    contentStyle?: CSSProperties
}

interface TabbedViewState {
    selectedTab: number
}

export class TabbedView extends React.Component<TabbedViewProps, TabbedViewState> {
    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 0
        }
    }

    handleSelect(index: number) {
        this.setState({
            selectedTab: index
        });
        if (this.props.onTabSelected) {
            this.props.onTabSelected(index);
        }
    }

    renderHeader() {
        return <div className={'title-tabs'}
             style={{
                 backgroundColor: 'var(--surface-bright)',
                 color: 'var(--on-background)'
             }}>
            {this.props.tabNames.map((name, index) =>
                <span className={`tab ${this.state.selectedTab === index ? 'selected' : ''}`}
                      onClick={() => this.handleSelect(index)}>
                    {name}
                </span>)}
        </div>
    }


    render() {
        return <div className={`material-card shadow-3 tabbed`}
                    style={{
                        backgroundColor: 'var(--surface-container)',
                        color: 'var(--on-background)',
                        ...this.props.cardStyle
                    }}>
            {this.renderHeader()}
            <div className={'content'} style={this.props.contentStyle}>
                {this.props.renderTab(this.state.selectedTab)}
            </div>
        </div>
    }
}

