import {DiagramEngine, PortWidget} from "@projectstorm/react-diagrams";
import React from "react";
import {LogicPortModel} from "./LogicPortModel";
import {LogicState} from "../LogicState";

interface LogicPortWidgetProps {
    engine: DiagramEngine,
    model: LogicPortModel
}

interface LogicPortWidgetState {}

export class LogicPortWidget extends React.Component<LogicPortWidgetProps, LogicPortWidgetState>{
    constructor(props: LogicPortWidgetProps) {
        super(props);

        // Update the widget once the model has updated.
        props.model.registerListener({
            eventDidFire: (event) => {
                if (event.function === 'logicUpdate') {
                    this.forceUpdate();
                }
            }
        });
    }

    render(): React.JSX.Element {
        return <PortWidget engine={this.props.engine} port={this.props.model}>
            <div className={`circle-port ${this.getColourClass()}`} />
        </PortWidget>
    }

    getColourClass() {
        switch (this.props.model.logicState) {
            case LogicState.True:
                return 'circle-port-true';
            case LogicState.False:
                return 'circle-port-false';
            case LogicState.Unknown:
                return 'circle-port-unknown';
            case LogicState.Error:
                return 'circle-port-error';
        }
    }
}