// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropzone-body {
    width: 100%;
    height: 100%;

    background-color: var(--surface-container-high);
    border-radius: 20px;

    border: var(--outline) 3px dashed;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    color: var(--on-background);
    font-size: 22px;
    gap: 5px;

    transition-duration: 0.1s;
    -webkit-user-select: none;
            user-select: none;
}

.dropzone-body:hover {
    filter: brightness(85%);
}
.dropzone-body:active {
    filter: brightness(75%);
}

.dropzone-body .mui-icon {
    font-size: 48px;
}

.dropzone-body .accepted-file-types {
    font-size: 18px;
    color: var(--outline);
}
`, "",{"version":3,"sources":["webpack://./src/css/dropzone.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;IAEZ,+CAA+C;IAC/C,mBAAmB;;IAEnB,iCAAiC;IACjC,eAAe;;IAEf,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;;IAEvB,2BAA2B;IAC3B,eAAe;IACf,QAAQ;;IAER,yBAAyB;IACzB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,uBAAuB;AAC3B;AACA;IACI,uBAAuB;AAC3B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,qBAAqB;AACzB","sourcesContent":[".dropzone-body {\r\n    width: 100%;\r\n    height: 100%;\r\n\r\n    background-color: var(--surface-container-high);\r\n    border-radius: 20px;\r\n\r\n    border: var(--outline) 3px dashed;\r\n    cursor: pointer;\r\n\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n\r\n    color: var(--on-background);\r\n    font-size: 22px;\r\n    gap: 5px;\r\n\r\n    transition-duration: 0.1s;\r\n    user-select: none;\r\n}\r\n\r\n.dropzone-body:hover {\r\n    filter: brightness(85%);\r\n}\r\n.dropzone-body:active {\r\n    filter: brightness(75%);\r\n}\r\n\r\n.dropzone-body .mui-icon {\r\n    font-size: 48px;\r\n}\r\n\r\n.dropzone-body .accepted-file-types {\r\n    font-size: 18px;\r\n    color: var(--outline);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
