import {BaseModelOptions, DeserializeEvent} from '@projectstorm/react-diagrams';
import {LogicPortModel, LogicComponent, SerialisedComponent} from "../logic-components";
import {LogicState} from "../LogicState";
import {LogicNodeModel} from "../LogicNodeModel";

export interface LogicGateModelOptions extends BaseModelOptions {
    bodyClass: string
}

export class LogicGateModel extends LogicNodeModel {
    bodyClass: string;

    in1: LogicPortModel;
    in2: LogicPortModel;
    out: LogicPortModel;

    logicState: LogicState;
    next: LogicComponent[];

    constructor(options: LogicGateModelOptions = {bodyClass: ''}) {
        super({
            ...options,
            type: 'logic-gate-node'
        });

        this.setChildren([
            new LogicPortModel({
                in: true,
                name: 'in1'
            }),
            new LogicPortModel({
                in: true,
                name: 'in2'
            })
        ]);

        this.out = new LogicPortModel({
            in: false,
            name: 'out'
        });
        // Port UUID matching. See nucleioapi/logic/README.md
        this.outputPort.uuid = this.uuid;
        this.outputPort.shouldSerialise = false;
        this.next = [this.out]
        this.addPort(this.out);

        this.bodyClass = options.bodyClass;
    }

    updateAndPropagate(): void {
        this.logicState = this.determineLogicStateFromInputs(this.in1.logicState, this.in2.logicState);

        this.out.logicState = this.logicState;
        this.out.updateAndPropagate();
    }

    serialize() {
        return {
            ...super.serialize()
        };
    }

    determineLogicStateFromInputs(in1: LogicState, in2: LogicState): LogicState {
        if (in1 === LogicState.Error || in2 === LogicState.Error) return LogicState.Error;

        return in2;
    }

    deserialize(event: DeserializeEvent<this>): void {
        super.deserialize(event);
    }

    get serialisedType(): string {
        return "logic_gate";
    }

    static deserialiseWithoutChildren(serialised: SerialisedComponent): LogicGateModel {
        return new LogicGateModel()
    }

    get inputPort(): LogicPortModel {
        return null;
    }
    get outputPort(): LogicPortModel {
        return this.out;
    }

    get children(): LogicComponent[] {
        return [this.in1, this.in2];
    }
    setChildren(children: LogicComponent[]) {
        // Remove the old ports.
        if (this.in1) this.removePort(this.in1);
        if (this.in2) this.removePort(this.in2);

        this.in1 = children[0] as LogicPortModel;
        this.in1.next = [this];
        this.addPort(this.in1);

        this.in2 = children[1] as LogicPortModel;
        this.in2.next = [this];
        this.addPort(this.in2);
    }
}