import {
    DefaultDiagramState,
    DefaultLabelFactory,
    DefaultLinkFactory,
    DefaultNodeFactory,
    DefaultPortFactory,
    DiagramEngine, DiagramModel,
    LinkLayerFactory,
    LinkModel,
    NodeLayerFactory,
    NodeModel,
    PathFindingLinkFactory,
    SelectionBoxLayerFactory
} from "@projectstorm/react-diagrams";

import {
    InputPinFactory,
    InputPinModel,
    linkComponents,
    LogicComponent,
    LogicLinkFactory,
    LogicLinkModel,
    LogicPortModel,
    OutputPinFactory,
    OutputPinModel,
    SerialisedComponent,
    SerialisedLink
} from './logic-components'

import {
    AndGateModel,
    AndGateNodeFactory,
    LogicGateNodeFactory,
    NotGateModel,
    NotGateNodeFactory,
    OrGateModel,
    OrGateNodeFactory,
    XOrGateModel,
    XOrGateNodeFactory
} from "./logic-gates";


import {LogicNodeModel} from "./LogicNodeModel";
import {ProtectedDeleteAction} from "./ProtectedDeleteAction";
import {LogicState} from "./LogicState";


export interface SerialisedCircuit {
    components: SerialisedComponent[],
    links: SerialisedLink[]
}


export class LogicDiagramEngine extends DiagramEngine {
    constructor() {
        // Use our very own custom delete action instead.
        super({registerDefaultDeleteItemsAction: false});

        this.getActionEventBus().registerAction(new ProtectedDeleteAction());

        // Default diagram engine factories
        // https://github.com/projectstorm/react-diagrams/blob/999f4902e2c7b35ff9743850b25f8a17c0ed5951/packages/react-diagrams/src/index.ts
        this.getLayerFactories().registerFactory(new NodeLayerFactory());
        this.getLayerFactories().registerFactory(new LinkLayerFactory());
        this.getLayerFactories().registerFactory(new SelectionBoxLayerFactory());

        this.getLabelFactories().registerFactory(new DefaultLabelFactory());
        this.getNodeFactories().registerFactory(new DefaultNodeFactory());
        this.getLinkFactories().registerFactory(new DefaultLinkFactory());
        this.getLinkFactories().registerFactory(new PathFindingLinkFactory());
        this.getPortFactories().registerFactory(new DefaultPortFactory());

        this.getStateMachine().pushState(new DefaultDiagramState());

        // Now for our own components:
        // Use the right angle link factor so all the links that the user creates are right-angled.
        this.getLinkFactories().registerFactory(new LogicLinkFactory());

        this.getNodeFactories().registerFactory(new LogicGateNodeFactory());
        this.getNodeFactories().registerFactory(new InputPinFactory());
        this.getNodeFactories().registerFactory(new AndGateNodeFactory());
        this.getNodeFactories().registerFactory(new OrGateNodeFactory());
        this.getNodeFactories().registerFactory(new NotGateNodeFactory());
        this.getNodeFactories().registerFactory(new XOrGateNodeFactory());
        this.getNodeFactories().registerFactory(new OutputPinFactory());
        
        const state = this.stateMachine.getCurrentState();
        if (state instanceof DefaultDiagramState) {
            state.dragNewLink.config.allowLooseLinks = false;
        }
    }

    setModel(model: DiagramModel) {
        super.setModel(model);

        // Validates the circuit whenever something happens.
        this.getModel().registerListener({
            eventDidFire: (event) => {
                if (event.function === 'validation-valid') return;
                if (event.function === 'validation-invalid') return;

                // Now even when the event does fire, it may not be timed correctly. I.e., this event might be
                // processed *before* the error states show up. There is no good way of hooking the wires up
                // correctly, and we'll simply use a slight delay for this purpose.
                setTimeout(() => {
                    const errorStates = this.getModel().getNodes().filter((x) => this.shouldSerialise(x))
                        // Did you know that typescript can be extremely annoying at times?
                        .some((x) => (x as unknown as LogicComponent).logicState === LogicState.Error);

                    // Did you know that Typescript does not let you dump extra properties onto types since 1.6? This
                    // makes sneaking in additional properties an extremely annoying thing. Instead, here's two separate
                    // events for what could have been one.
                    if (errorStates) {
                        this.getModel().fireEvent({}, 'validation-invalid');
                    } else {
                        this.getModel().fireEvent({}, 'validation-valid');
                    }
                }, 1);
            }
        })
    }

    // For a variety of reasons TypeScript refuses to let us check for interface membership. Here we're only
    // checking one property to be convenient.
    shouldSerialise(component: object): component is LogicComponent {
        return (component as LogicComponent).shouldSerialise;
    }

    // Unfortunately we don't get a fancy type map here because the abstraction is more annoying.
    deserialiseComponent(serialised: SerialisedComponent): LogicComponent {
        switch (serialised.type) {
            case 'input_pin':
                return InputPinModel.deserialiseWithoutChildren(serialised)
            case 'port':
                return LogicPortModel.deserialiseWithoutChildren(serialised)
            case 'output_pin':
                return OutputPinModel.deserialiseWithoutChildren(serialised)
            case 'and_gate':
                return AndGateModel.deserialiseWithoutChildren(serialised)
            case 'or_gate':
                return OrGateModel.deserialiseWithoutChildren(serialised)
            case 'xor_gate':
                return XOrGateModel.deserialiseWithoutChildren(serialised)
            case 'not_gate':
                return NotGateModel.deserialiseWithoutChildren(serialised)
        }
        return null
    }

    serialiseForTransmission(): SerialisedCircuit {
        const components: SerialisedComponent[] = []
        for (const component of this.getModel().getNodes()) {
            if (this.shouldSerialise(component)) {
                components.push(component.serialiseWithoutLinks());
                for (const child of component.children) {
                    components.push(child.serialiseWithoutLinks());
                }
            }
        }

        const links: SerialisedLink[] = []
        for (const link of this.getModel().getLinks()) {
            if (link instanceof LogicLinkModel) {
                links.push({
                    // More typescript shenanigans, whether this is necessary I'll let you be the judge.
                    source: (link.getSourcePort() as unknown as LogicComponent).uuid,
                    target: (link.getTargetPort() as unknown as LogicComponent).uuid
                });
            }
        }
        for (const component of this.getModel().getNodes()) {
            if (component instanceof LogicNodeModel) {
                for (const child of component.children) {
                    // Since we're serialising this for transmission, we'll add links between children and their
                    // parents.
                    links.push({
                        source: child.uuid,
                        target: component.uuid
                    });
                }
            }
        }

        return {
            components: components, links: links
        }
    }

    // This is a mirror image of the corresponding method in the backend. See detailed comments and explanations in
    // nucleioapi/logic/circuit_deserialiser.py
    // Deserialises the circuit and adds the components to the model.
    deserialiseCircuit(serialised: SerialisedCircuit) {
        const deserialisedComponents = new Map<string, LogicComponent>();
        // We'll use a list as the queue with shift as the pop.
        const toDeserialise = [...serialised.components];
        const serialisedMap = new Map<string, SerialisedComponent>();
        for (const component of serialised.components) {
            serialisedMap.set(component.id, component);
        }

        // On top of deserialising the network itself, we'll have to position its elements accordingly.
        // Starting with horizontally. We first construct a map of the connections between the nodes. This serves as
        // a directed graph for traversal. Note that we assume the serialised data to come from the server, where
        // everything has been checked.
        const linkMap = new Map<string, string[]>();

        while (toDeserialise.length > 0) {
            const current = toDeserialise.shift();
            // Skip the component if any of its children hasn't been deserialised.
            if (current.children && current.children.some((x) => !deserialisedComponents.has(x))) {
                toDeserialise.push(current);
                continue;
            }

            const deserialised = this.deserialiseComponent(current);

            if (current.children) {
                const children = current.children.map((x) => deserialisedComponents.get(x));
                deserialised.setChildren(children);

                for (const child of current.children) {
                    linkMap.set(child, [current.id]);
                }
            }

            deserialisedComponents.set(current.id, deserialised);
        }

        const inputPinIDs: string[] = [];
        // See the problem with not having a unified iterator system is that I can't just filter map this.
        serialisedMap.forEach((v, id) => {
            if (v.type === 'input_pin') {
                inputPinIDs.push(id);
            }
        })

        // Populate the link map.
        for (const link of serialised.links) {
            if (linkMap.has(link.source) && !linkMap.get(link.source).includes(link.target)) {
                linkMap.get(link.source).push(link.target);
            } else {
                linkMap.set(link.source, [link.target]);
            }
        }

        // Now starting with the horizontal positions of these nodes. We simply do a traversal starting at the input
        // pins. We assign a "depth" to each node, that is, the *maximum* number of links it takes for any input pin
        // to reach it. This is a natural way of assigning the horizontal distance as the deeper into the graph, the
        // mor it should be to the right.
        const depthMap = new Map<string, number>();
        // Initialise.
        for (const id of deserialisedComponents.keys()) {
            depthMap.set(id, -1);
        }
        for (const pin of inputPinIDs) {
            // Assign depth through DFS.
            LogicDiagramEngine.assignDepth(depthMap, linkMap, deserialisedComponents, pin);
        }

        // Each component with a given depth may occupy one unique vertical position.
        const countMap = new Map<number, number>();
        for (const [id, component] of deserialisedComponents.entries()) {
            if (component instanceof NodeModel) {
                let depth = depthMap.get(id);
                let verticalPosition = 0;
                if (countMap.has(depth)) {
                    countMap.set(depth, countMap.get(depth) + 1);
                    verticalPosition = 100 * countMap.get(depth);
                } else {
                    countMap.set(depth, 0);
                }

                component.setPosition(depthMap.get(id) * 120, verticalPosition)
                verticalPosition += 1
            }
        }

        const links: LinkModel[] = [];
        for (const link of serialised.links) {
            const serialisedTarget = serialisedMap.get(link.target);
            // For the server side, children may link to their parents (ports to gates). Here they may not.
            if (!(serialisedTarget.children && serialisedTarget.children.indexOf(link.source) !== -1)) {
                links.push(linkComponents(
                    deserialisedComponents.get(link.source),
                    deserialisedComponents.get(link.target)
                ));
            }
        }

        // Keep track of the input pins.
        for (const [id, component] of deserialisedComponents.entries()) {
            if (component instanceof NodeModel) {
                this.getModel().addAll(component);
            }
        }


        for (const link of links) {
            this.getModel().addAll(link);
        }
    }

    private static assignDepth(depthMap: Map<string, number>, linkMap: Map<string, string[]>,
                               deserialisedComponents: Map<string, LogicComponent>,
                               current: string, currentDepth: number = 0) {
        // Only update the map when currentDepth exceeds the old depth stored in the map.
        if (depthMap.get(current) >= currentDepth) return;

        depthMap.set(current, currentDepth);

        let depthModifier: number = 1;
        // Ports don't count for horizontal distance.
        if (deserialisedComponents.get(current) instanceof LogicPortModel) {
            depthModifier = 0
        }
        // Due to the sheer length of input pins, components after it will be offset by a solid layer.
        if (deserialisedComponents.get(current) instanceof InputPinModel) {
            depthModifier = 2
        }

        if (!linkMap.has(current)) return;
        for (const target of linkMap.get(current)) {
            this.assignDepth(depthMap, linkMap, deserialisedComponents, target, currentDepth + depthModifier);
        }
    }

    getInputPins(): InputPinModel[] {
        return this.getModel().getNodes().filter((x) => x instanceof InputPinModel)
            .map((x) => x as InputPinModel);
    }
    getOutputPins(): OutputPinModel[] {
        return this.getModel().getNodes().filter((x) => x instanceof OutputPinModel)
            .map((x) => x as OutputPinModel);
    }
}