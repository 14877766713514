import {defaultFactory, LogicDiagramEngine, LogicEditor} from "../logic-builder";
import React from "react";
import {Button, Card, MaterialColor, TabbedView} from "../basic-components";
import {Height} from "../basic-components/Height";
import {Nucleio} from "../nucleio";


interface BoxSplittingViewProps {
    originalCircuit: LogicDiagramEngine
    boxes: LogicDiagramEngine[]
    onBoxesUpdate: (newBoxes: LogicDiagramEngine[]) => void
    boxesValid?: boolean
}

interface BoxSplittingViewState {
    canSplit: boolean;
    error: boolean;
}


export class BoxSplittingView extends React.Component<BoxSplittingViewProps, BoxSplittingViewState> {
    constructor(props) {
        super(props);

        this.state = {
            canSplit: true,
            error: false
        }
    }


    renderCircuit(index: number) {
        return <LogicEditor engine={this.props.boxes[index]} factory={defaultFactory} noEditing={true}/>
    }

    renderCircuitView() {
        return <TabbedView tabNames={this.props.boxes.map((box, index) => `${index + 1}`)}
                           renderTab={(i) => this.renderCircuit(i)}
                           cardStyle={{ flexGrow: 1 }} contentStyle={{ height: '100%' }}
        />
    }

    handleBoxSplit() {
        this.setState({
            canSplit: false,
            error: false
        });
        Nucleio.dpgaify(this.props.originalCircuit)
            .then((circuits) => {
                // Have the engines zoom to fit the new circuit.
                circuits.forEach((circ) => {
                    circ.registerListener({
                        canvasReady: () => circ.zoomToFit()
                    })
                });
                this.props.onBoxesUpdate(circuits);
            })
            .catch(() => {
                this.setState({
                    error: true
                });
            })
            .finally(() => {
                this.setState({
                    canSplit: true
                });
            })
    }

    renderInfobar() {
        return <div style={{
            flexGrow: 0,
            flexShrink: 0,
            width: '220px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            alignContent: 'stretch',
            gap: '20px'
        }}>
            <Card height={Height.Medium} color={MaterialColor.Normal} title={'Split Circuits'}>
                Each DPGA box has a limited number of logic gates and connections. If your circuit is too big, it may
                need to be split into multiple boxes.
            </Card>
            {this.props.boxesValid ? '' :
                <Card height={Height.Medium} color={MaterialColor.Error} title={'Invalid Splits'}>
                    Split circuits do not match the specified inputs and outputs. Please split the circuit again to
                    update the boxes.
                </Card>
            }
            {this.state.error ? <Card height={Height.Medium} color={MaterialColor.Error} title={'Error'}>
                Something went wrong with splitting the circuit.
            </Card> : ''}
            <Button content={'Split Circuits'} disabled={!this.state.canSplit} color={MaterialColor.Secondary}
                    onClick={() => this.handleBoxSplit()}
            />
        </div>
    }

    render() {
        return <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'stretch',
            gap: '20px'
        }}>
            {this.props.boxes.length > 0 ? this.renderCircuitView() : <div style={{flexGrow: 1}}/>}
            {this.renderInfobar()}
        </div>
    }
}

