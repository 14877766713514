import {DefaultLinkPointWidget, DefaultLinkSegmentWidget, DiagramEngine, LinkWidget, PointModel} from "@projectstorm/react-diagrams";
import React from "react";
import {LogicLinkModel} from "./LogicLinkModel";
import {MouseEvent} from "react";
interface LogicLinkWidgetProps {
    engine: DiagramEngine,
    model: LogicLinkModel,
}

interface LogicLinkWidgetState {}

export class LogicLinkWidget extends React.Component<LogicLinkWidgetProps, LogicLinkWidgetState>{
    refPaths: React.MutableRefObject<React.RefObject<SVGPathElement>[]>;
    constructor(props: LogicLinkWidgetProps) {
        super(props);
        this.refPaths = React.createRef<React.RefObject<SVGPathElement>[]>();
        props.model.registerListener({
            eventDidFire: (event) => {
                if (event.function === 'logicUpdate') {
                    this.forceUpdate();
                }
            }
        });
    }

    // no right click context menu for you
    handleContextMenu(event: MouseEvent) {
        event.preventDefault();
    }

	renderPoints() {
		return true;
	}

	generateRef()  {
		const ref = React.createRef<SVGPathElement>();
		this.refPaths.current.push(ref);
		return ref;
	}

	addPointToLink(event: MouseEvent, index: number) {
		if (
			!event.shiftKey &&
            event.button === 2 &&
			!this.props.model.isLocked() &&
			this.props.model.getPoints().length - 1 <= this.props.engine.getMaxNumberPointsPerLink()
		) {
			const position = this.props.engine.getRelativeMousePoint(event);
			const point = this.props.model.point(position.x, position.y, index);
            event.persist();
            event.stopPropagation();    
			this.props.engine.getActionEventBus().fireAction({
				event,
				model: point
			});
		}
	}

    generatePoint(point: PointModel): JSX.Element {
		return (
			<DefaultLinkPointWidget
				key={point.getID()}
				point={point as any}
				colorSelected={"rgb(0,192,255)"}
				color={this.props.model.getOptions().color}
			/>
		);
	}

	generateLink(path: string, extraProps: any, id: string | number): JSX.Element {
		return (
			<DefaultLinkSegmentWidget
				key={`link-${id}`}
				path={path}
				diagramEngine={this.props.engine}
				factory={this.props.engine.getFactoryForLink(this.props.model)}
				link={this.props.model}
				forwardRef={this.generateRef()}
				onSelection={() => {}}
				extras={extraProps}
                selected={false}
			/>
		);
	}

	componentDidMount(): void {
        this.props.model.setRenderedPaths(this.refPaths.current.map((ref) => ref.current).filter(Boolean) as SVGPathElement[]);
    }

    componentWillUnmount(): void {
        this.props.model.setRenderedPaths([]);
    }

    render(): React.JSX.Element {
        // const [selected, setSelected] = React.useState(false);
        const points = this.props.model.getPoints();
    	const paths = [];
	    this.refPaths.current = []; // Reset the refPaths for the current render

        if (points.length === 2) {
            paths.push(
                this.generateLink(
                    this.props.model.getSVGPath(),
                    {
                        onMouseDown: (event: MouseEvent) => {
                            this.addPointToLink(event, 1);
                        }
                    },
                    '0'
                )
            );

            if (this.props.model.getTargetPort() == null) {
                paths.push(this.generatePoint(points[1]));
            }
    	} else {
            for (let j = 0; j < points.length - 1; j++) {
                paths.push(
                    this.generateLink(
                        LinkWidget.generateLinePath(points[j], points[j + 1]),
                        {
                            'data-linkid': this.props.model.getID(),
                            'data-point': j,
                            onMouseDown: (event: MouseEvent) => {
                                this.addPointToLink(event, j + 1);
                            }
                        },
                        j
                    )
                );
            }

            if (this.renderPoints()) {
                for (let i = 1; i < points.length - 1; i++) {
                    paths.push(this.generatePoint(points[i]));
                }

                if (this.props.model.getTargetPort() == null) {
                    paths.push(this.generatePoint(points[points.length - 1]));
                }
            }
        }

	    return <g onContextMenu={this.handleContextMenu} data-default-link-test={this.props.model.getOptions().testName}>{paths}</g>;
    }
}