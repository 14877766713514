import {Cell} from "../Cell";
import React, {ChangeEvent} from "react";
import {Num} from "../validation";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";


const defaultIncrement = 0.1;

interface CellWidgetProps {
    cell: Cell
    editable?: boolean
    onChange?: (unparsedValue: string) => void
}

interface CellWidgetState {
    inputValue: string
    inputValid: boolean
}

export class CellWidget extends React.Component<CellWidgetProps, CellWidgetState> {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: this.cell.toString(),
            inputValid: this.cell.valid
        }
    }

    get cell() {
        return this.props.cell;
    }

    onInputChange(event: ChangeEvent<HTMLInputElement>) {
        const inputValue = event.target.value;
        
        let valid: boolean;
        this.cell.setContentString(inputValue);
        valid = this.cell.valid;

        if (this.props.onChange) {
            this.props.onChange(inputValue);
        }
        this.setState({
            inputValue: inputValue,
            inputValid: valid
        });
    }
    setNumericValue(target: number) {
        let validation = this.cell.validation as Num;
        let clampedTarget = target;
        if (validation.hasUpperBound) {
            clampedTarget = Math.min(clampedTarget, validation.upperBound);
        }
        if (validation.hasLowerBound) {
            clampedTarget = Math.max(clampedTarget, validation.lowerBound);
        }

        this.cell.setContentString(this.cell.validation.formatValue(clampedTarget));
        if (this.props.onChange) {
            this.props.onChange(this.cell.toString());
        }
        this.setState({
            inputValue: this.cell.toString(),
            inputValid: this.cell.valid
        });
    }
    increment() {
        this.setNumericValue(this.cell.getContent() + defaultIncrement);
    }
    decrement() {
        this.setNumericValue(this.cell.getContent() - defaultIncrement);
    }

    get useNumericGradient(): boolean {
        return this.cell.isNumeric && (this.cell.validation as Num).hasBounds;
    }
    /* Percentage of progress between minimum and maximum value.  */
    get gradientPercent(): number {
        const validation = this.cell.validation as Num;
        const rangeSize = validation.upperBound - validation.lowerBound;

        return Math.round((this.cell.getContent() - validation.lowerBound) / rangeSize * 100);
    }

    get validityClass(): string {
        return this.cell.valid ? '' : 'invalid';
    }

    // Can't be edited.
    renderFixedCell() {
        return <td className={`static ${this.validityClass}`}>
            <div className={'cell-body'}>
                <span>
                    {this.cell.toString()}
                </span>
            </div>
        </td>
    }
    // Can't be edited, but has background colour based on the input value.
    renderFixedNumericCell() {
        // Only render the gradient when it's valid and when applicable..
        if (!this.state.inputValid) {
            return this.renderFixedCell();
        }

        return <td className={'numeric'}
                   style={{'--progress': `${this.gradientPercent}%`} as React.CSSProperties}>
            <div className={'cell-body'}>
                <span>
                    {this.cell.toString()}
                </span>
            </div>
        </td>
    }
    // Can be edited.
    renderEditableCell() {
        return <td className={`editable ${this.validityClass}`}>
            <div className={'cell-body'}>
                <input value={this.cell.stringContent}
                    onChange={(e) => this.onInputChange(e)}/>
            </div>
        </td>
    }
    renderEditableNumericCell() {
        const useGradient = this.useNumericGradient && this.state.inputValid;
        const additionalStyle = (useGradient ? {'--progress': `${this.gradientPercent}%`} : {}) as React.CSSProperties;

        return <td className={`editable ${this.validityClass} ${useGradient ? 'numeric' : ''}`}
                   style={additionalStyle}>
            <div className={'cell-body'}>
                <input type={'number'} value={this.cell.stringContent}
                       onChange={(e) => this.onInputChange(e)}/>
                <div className={'arrow-container'}>
                    <div className={'icon-button'} onClick={() => this.increment()}>
                        <ArrowDropUpIcon/>
                    </div>
                    <div className={'icon-button'} onClick={() => this.decrement()}>
                        <ArrowDropDownIcon/>
                    </div>
                </div>
            </div>
        </td>
    }

    render() {
        if (this.props.editable) {
            if (this.cell.isNumeric) {
                return this.renderEditableNumericCell();
            }
            return this.renderEditableCell();
        }
        if (this.cell.isNumeric && this.useNumericGradient) {
            return this.renderFixedNumericCell();
        }
        return this.renderFixedCell();
    }
}
