import {TableCard} from "../data/table-component";
import {Table} from "../data";
import React from "react";
import {Card, MaterialColor} from "../basic-components";
import {Height} from "../basic-components/Height";
import {Nucleio} from "./Nucleio";
import {AlignmentForm, AlignmentOptions, defaultAlignmentOptions} from "./AlignmentForm";


interface StrandAlignmentViewProps {
    table: Table,
    // List of names that should be showing up.
    inputStrandNames: string[]
    onAnyTableUpdate: (table?: Table) => void
    // Called when the table's validity state changes.
    onValidityUpdate?: (valid: boolean) => void
}

interface StrandAlignmentViewState {
    tableFormatViolations: string[],
    canAlign: boolean,
    alignmentOptions: AlignmentOptions,
    alignmentError: boolean
}

const formattingRequirements = `The table must have four columns: strand names corresponding to the previously defined input names, the strand sequences, target regions (separated by semicolons), and helper strands (separated by semicolons). The latter two can be generated from the sequences. If the target regions are left empty, they will not be considered in the strand generation (next section).`


export class StrandAlignmentView extends React.Component<StrandAlignmentViewProps, StrandAlignmentViewState> {
    constructor(props) {
        super(props);

        this.state = {
            tableFormatViolations: [],
            canAlign: false,
            alignmentOptions: defaultAlignmentOptions(),
            alignmentError: false
        }
    }

    static getDerivedStateFromProps(props: StrandAlignmentViewProps, state: StrandAlignmentViewState) {
        let violations = [];
        let canAlign = false;
        if (props.table) {
            violations = StrandAlignmentView.calculateViolations(props.inputStrandNames, props.table);
            canAlign = props.table.column(1).cells.every((c) => c.toString().length > 0) && props.table.numRows > 0;
        } else {
            violations = ['Missing table. '];
        }

        if (props.onValidityUpdate) {
            //props.onValidityUpdate(violations.length === 0);
        }

        return {
            tableFormatViolations: violations,
            canAlign: state.canAlign && canAlign
        };
    }

    get hasViolations() {
        return this.state.tableFormatViolations.length > 0;
    }

    updateViolations(table?: Table) {
        const violations = StrandAlignmentView.calculateViolations(this.props.inputStrandNames, table);

        if (this.props.onValidityUpdate) {
            if (this.state.tableFormatViolations.length !== violations.length) {
                this.props.onValidityUpdate(violations.length === 0);
            }
        }
        this.setState({
            tableFormatViolations: violations
        });
    }

    static calculateViolations(inputStrandNames: string[], table?: Table): string[] {
        if (!table) {
            return ['Missing table. ']
        }

        const violations = [];

        if (!table.valid) {
            violations.push("Can only input letters 'A', 'T', 'C', 'G', 'N'. The Sequence column accepts 'U'.")
        }

        const tableStrandNames = table.column(0).cells.map((cell) => cell.getContent());
        const tableStrandNamesSet = new Set(tableStrandNames);
        const correctStrandNamesSet = new Set(inputStrandNames);

        if (tableStrandNamesSet.size !== tableStrandNames.length) {
            violations.push('Duplicate strand inputs. ')
        }

        const missingStrandNames = [...correctStrandNamesSet].filter(x => !tableStrandNamesSet.has(x));
        const extraStrandNames = [...tableStrandNamesSet].filter(x => !correctStrandNamesSet.has(x));
        /*
        if (missingStrandNames.length > 0) {
            violations.push(`Missing strands: ${missingStrandNames.join(', ')}.`);
        }*/
        if (extraStrandNames.length > 0) {
            violations.push(`Strand names don't correspond to inputs: ${extraStrandNames.join(', ')}.`);
        }

        return violations;
    }

    handleTableUpdate(table?: Table) {
        if (this.props.onAnyTableUpdate) {
            this.props.onAnyTableUpdate(table);
        }
        this.updateViolations(table);
        this.updateCanAlign(table);
    }

    renderErrorCard() {
        return <Card height={Height.Medium} color={MaterialColor.Error} title={'Invalid Table'} cardStyle={{flexShrink: 0}}>
            <ul style={{marginLeft: '20px'}}>
                {this.state.tableFormatViolations.map((violation) => <li>{violation}</li>)}
            </ul>
        </Card>
    }

    renderInfoCard() {
        return <Card height={Height.Medium} color={MaterialColor.Normal} title={'Input Strands for ADC'}>
            If you wish to integrate your circuit with nucleotide inputs, you can add sequences for them, then generate 30nt target regions and 60nt helper strands below. You may directly input target regions and helper strands as a FASTA or CSV file as well, separated by semicolons. These regions will serve to generate analog-to-digital (ADC) DNA gates for detecting the sequences. Note that all requested target regions and helpers may not always be found.
        </Card>
    }

    renderFormattingRequirements() {
        return <Card color={MaterialColor.Normal} height={Height.Medium}
                     title={'Table Format'}>
            {formattingRequirements}
        </Card>
    }

    handleAlign() {
        this.setState({
            canAlign: false
        });
        Nucleio.fillTargetRegions(this.props.table,
            this.state.alignmentOptions.useHelper,
            this.state.alignmentOptions.gcContent,
            this.state.alignmentOptions.avoidSameBaseInARow,
            this.state.alignmentOptions.numRegions).then((result) => {
                this.props.onAnyTableUpdate(result);
                this.setState({
                    alignmentError: false
                });
                this.updateCanAlign(result);
            })
            .catch(() => {
                this.setState({
                    alignmentError: true
                });
            });
    }

    updateCanAlign(table?: Table) {
        if (!table) return false;
        this.setState({
            canAlign: table.column(1).cells.every((cell) => cell.toString().length > 0)
        });
    }

    handleOptionsChange(options: AlignmentOptions) {
        this.setState({
            alignmentOptions: options
        });
    }

    renderInfobar() {
        return <div className={'infobar'}>
            {this.renderInfoCard()}
            {/* {this.renderFormattingRequirements()} */}
            {this.hasViolations ? this.renderErrorCard() : ''}
            {this.state.alignmentError ? <span style={{
                color: 'var(--error)',
                fontSize: '12px'
            }}>
                Something went wrong with the target region generation.
            </span> : ''}
            <AlignmentForm canRun={this.state.canAlign && !this.hasViolations} options={this.state.alignmentOptions}
                           onChange={(options) => this.handleOptionsChange(options)}
                           onRun={() => this.handleAlign()}/>
        </div>
    }

    createTable() {
        const table = Table.schemaOnly(Nucleio.getTargetRegionsSchema());
        for (const name of this.props.inputStrandNames) {
            table.addRowData([name, '', '', '']);
        }
        return table;
    }

    componentDidUpdate(prevProps: Readonly<StrandAlignmentViewProps>, prevState: Readonly<StrandAlignmentViewState>, snapshot?: any) {
        if (prevProps.table !== this.props.table) {
            setTimeout(() => this.updateViolations(this.props.table), 50);
        }
    }

    render() {
        return <div className={'table-editor'}>
            <TableCard table={this.props.table}
                       tableTitle={'Input Strands'}
                       formatRequirements={formattingRequirements}
                       createEmptyTable={() => this.createTable()}
                       allowAddingOrRemovingRows={true}
                       allowAddingColumns={false}
                       onAnythingChanged={(table) => this.handleTableUpdate(table)}
                       allowReplacing={true}
                       onTableCompletelyChanged={(table) => {
                                     if (table) {
                                         table.setSchema(Nucleio.getTargetRegionsSchema())
                                     }
                                     this.handleTableUpdate(table)
                                 }}
                       supportFasta={true}
            />
            {this.renderInfobar()}
        </div>
    }
}

