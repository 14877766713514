import {LogicState} from "../LogicState";
import {LogicGateModel, LogicGateModelOptions} from "./LogicGateModel";
import {SerialisedComponent} from "../logic-components/LogicComponent";


export class OrGateModel extends LogicGateModel {
    constructor(options: LogicGateModelOptions = { bodyClass: 'or-gate' }) {
        super({
            ...options,
            type: 'or-gate-node'
        });
    }

    determineLogicStateFromInputs(in1: LogicState, in2: LogicState): LogicState {
        if (in1 === LogicState.Error || in2 === LogicState.Error) return LogicState.Error;
        if (in1 === LogicState.True || in2 === LogicState.True) return LogicState.True;
        if (in1 === LogicState.False && in2 === LogicState.False) return LogicState.False;
        return LogicState.Unknown;
    }
    static deserialiseWithoutChildren(serialised: SerialisedComponent): OrGateModel {
        return new OrGateModel()
    }

    get serialisedType(): string {
        return 'or_gate'
    }
}