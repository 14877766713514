import React from "react";
import {LogicNodeModel} from "./LogicNodeModel";
import {AndGateModel, OrGateModel, XOrGateModel, NotGateModel} from "./logic-gates";

export interface LogicComponentFactory {
    createComponentWidget(type: string): React.ReactElement
    createComponentModel(type: string): LogicNodeModel
}

function getBodyClass(type: string) {
    // Keeping a literal switch statement here instead of using replace, so my IDE can pick it up if I ever rename
    // the style classes.
    switch (type) {
        case 'and_gate':
            return 'and-gate'
        case 'or_gate':
            return 'or-gate'
        case 'xor_gate':
            return 'xor-gate'
    }
}

export const defaultFactory: LogicComponentFactory = {
    createComponentModel(type: string): LogicNodeModel {
        switch (type) {
            case 'and_gate':
                return new AndGateModel()
            case 'or_gate':
                return new OrGateModel()
            case 'xor_gate':
                return new XOrGateModel()
            case 'not_gate':
                return new NotGateModel()
        }
        return undefined;
    },
    createComponentWidget(type: string): React.ReactElement {
        switch (type) {
            case 'and_gate':
            case 'or_gate':
            case 'xor_gate':
                // Logic components cannot be displayed outside the diagram, so we'll need some ugly duplicate
                // code here.
                return <div className={`logic-gate`}>
                    <div className={'body-layer'}>
                        <div className={ `logic-gate-body ${getBodyClass(type)}` } />
                    </div>
                    <div className={'port-layer'}>
                        <div className="input-ports">
                            <div className={`circle-port circle-port-unknown`} />
                            <div className={"filler"}/>
                            <div className={`circle-port circle-port-unknown`} />
                        </div>
                        <div className={'filler'}/>
                        <div className="output-ports">
                            <div className={`circle-port circle-port-unknown`} />
                        </div>
                    </div>
                </div>
            case 'not_gate':
                return <div className={`logic-gate`}>
                    <div className={'body-layer'}>
                        <div className={ `not-gate-body` } />
                    </div>
                    <div className={'port-layer'}>
                        <div className="input-ports">
                            <div className={`circle-port circle-port-unknown`} />
                        </div>
                        <div className={'filler'}/>
                        <div className="output-ports">
                            <div className={`circle-port circle-port-unknown`} />
                        </div>
                    </div>
                </div>

        }
        return undefined;
    },

}
