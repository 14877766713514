// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle-port {
    width: 10px;
    height: 10px;
    margin: 2px;
    border-radius: 5px;
    background: darkgray;
    cursor: pointer;
}

.circle-port:hover{
    background: mediumpurple;
}

.circle-port-true {
    background-color: var(--true);
}
.circle-port-false {
    background-color: var(--false);
}
.circle-port-unknown {
    background-color: var(--unknown);
}
.circle-port-error {
    background-color: var(--error-logic);
}`, "",{"version":3,"sources":["webpack://./src/css/port.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,6BAA6B;AACjC;AACA;IACI,8BAA8B;AAClC;AACA;IACI,gCAAgC;AACpC;AACA;IACI,oCAAoC;AACxC","sourcesContent":[".circle-port {\r\n    width: 10px;\r\n    height: 10px;\r\n    margin: 2px;\r\n    border-radius: 5px;\r\n    background: darkgray;\r\n    cursor: pointer;\r\n}\r\n\r\n.circle-port:hover{\r\n    background: mediumpurple;\r\n}\r\n\r\n.circle-port-true {\r\n    background-color: var(--true);\r\n}\r\n.circle-port-false {\r\n    background-color: var(--false);\r\n}\r\n.circle-port-unknown {\r\n    background-color: var(--unknown);\r\n}\r\n.circle-port-error {\r\n    background-color: var(--error-logic);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
