import * as React from 'react';
import { LogicGateModel } from './LogicGateModel';
import { LogicGateWidget } from './LogicGateWidget';
import { AbstractReactFactory } from '@projectstorm/react-diagrams';
import { DiagramEngine } from '@projectstorm/react-diagrams';

export class LogicGateNodeFactory extends AbstractReactFactory<LogicGateModel, DiagramEngine> {
    constructor() {
        super('logic-gate-node');
    }

    generateModel(initialConfig: any) {
        return new LogicGateModel();
    }

    generateReactWidget(event: any): React.ReactElement {
        return <LogicGateWidget engine={this.engine as DiagramEngine} node={event.model} />;
    }
}