import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-diagrams';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import {OutputPinModel} from "./OutputPinModel";
import {OutputPinWidget} from "./OutputPinWidget";

export class OutputPinFactory extends AbstractReactFactory<OutputPinModel, DiagramEngine> {
    constructor() {
        super('output-pin-node');
    }

    generateModel(initialConfig: any) {
        return new OutputPinModel();
    }

    generateReactWidget(event: any): React.ReactElement {
        return <OutputPinWidget engine={this.engine as DiagramEngine} model={event.model} />;
    }
}