import React from "react";

export interface TrayItemWidgetProps {
    nodeType: string;
    nodeName: string;
    gateWidget: React.ReactElement;
}

export class TrayItemWidget extends React.Component<TrayItemWidgetProps> {
    render() {
        return (
            <div
                draggable={true}
                onDragStart={(event) => {
                    event.dataTransfer.setData('drop-component', JSON.stringify({
                        type: this.props.nodeType
                    }));
                }}
                className="tray-item">
                {this.props.gateWidget}
                <div className={'tray-item-label'}>
                    {this.props.nodeName}
                </div>
            </div>
        );
    }
}