import {TableRow} from "../TableRow";
import {Cell} from "../Cell";
import RemoveIcon from "@mui/icons-material/Remove";
import React from "react";
import {CellWidget} from "./CellWidget";


interface TableRowWidgetProps {
    index: number // Index of row in the table.
    row: TableRow
    onCellChange: (cell: Cell, columnIndex: number, unparsedValue: string) => void
    // Called when the delete button is clicked.
    handleRowDelete: (row: TableRow, index: number) => void
    // If the row can be deleted. Note that this should be the same across all rows.
    deletable: boolean
}

export class TableRowWidget extends React.Component<TableRowWidgetProps> {
    render() {
        const cells = this.props.row.cells.map((cell, index) =>
            <CellWidget cell={cell} key={index}
                        editable={this.props.row.schema.entries[index].editable}
                        onChange={(unparsedValue => this.props.onCellChange(cell, index, unparsedValue))}/>)

        if (this.props.deletable) {
            return <tr>
                <td className={'delete-button-container'}>
                    <div className={'delete-button'}>
                        <div className={'button-body'} title={'Delete Row'}
                             onClick={() => this.props.handleRowDelete(this.props.row, this.props.index)}>
                            <RemoveIcon/>
                        </div>
                    </div>
                </td>
                {cells}
            </tr>
        }
        return <tr>
            {cells}
        </tr>
    }
}