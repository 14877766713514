import React from "react";
import {Button, MaterialColor} from "../basic-components";

export interface CreditEntry {
    name: string,
    author: string,
    licenseContent: string
}


interface CreditsWidgetProps {
    entries: CreditEntry[]
}

interface CreditsWidgetState {
    selectedEntry: number
}


export class CreditsWidget extends React.Component<CreditsWidgetProps, CreditsWidgetState> {
    constructor(props) {
        super(props);

        this.state = {
            selectedEntry: 0
        }
    }

    incrementSelection() {
        this.setState({
            selectedEntry: this.state.selectedEntry + 1
        });
    }
    decrementSelection() {
        this.setState({
            selectedEntry: this.state.selectedEntry - 1
        });
    }

    get currentEntry() {
        return this.props.entries[this.state.selectedEntry];
    }

    render() {
        return <div className={'credits-widget'}>
            <div className={'credits-header'}>
                <span className={'lib-name'}>
                    {this.currentEntry.name}
                </span>
                <span style={{flexGrow: 1}}/>
                <span className={'lib-author'}>
                    {this.currentEntry.author}
                </span>
            </div>
            <div className={'credits-content'}>
                <code>
                    <span style={{whiteSpace: 'pre-line'}}>
                        {this.currentEntry.licenseContent}
                    </span>
                </code>
            </div>
            <div className={'credits-footer'}>
                {this.state.selectedEntry > 0 ?
                    <Button content={"Previous"} color={MaterialColor.Primary}
                            onClick={() => this.decrementSelection()}
                    /> : ''}
                <span style={{flexGrow: 1}}/>
                {this.state.selectedEntry < this.props.entries.length - 1 ?
                    <Button content={"Next"} color={MaterialColor.Primary}
                            onClick={() => this.incrementSelection()}
                    /> : ''}
            </div>
        </div>
    }
}

