import {
    AbstractModelFactory,
    DefaultPortModel, DefaultPortModelOptions, LinkModel,
    PortModel
} from "@projectstorm/react-diagrams";
import {LogicComponent, SerialisedComponent} from "./LogicComponent";
import {LogicState} from "../LogicState";
import {LogicLinkModel} from "./LogicLinkModel";
import {v4 as uuidv4} from 'uuid';


interface LogicPortModelOptions extends DefaultPortModelOptions {
    isPin?: boolean // See if this belongs to a pin.
}

export class LogicPortModel extends DefaultPortModel implements LogicComponent {
    logicState: LogicState = LogicState.Unknown;
    next: LogicComponent[];
    uuid: string;
    shouldSerialise = true;
    isPin = false;

    constructor(options: LogicPortModelOptions) {
        super(options);

        if (options.isPin) {
            this.isPin = true;
        }

        this.next = [];
        this.uuid = uuidv4();
    }

    // We want to make sure that every input port can only take input from one wire.
    // canLinkToPort is not symmetrical, i.e. it's called from the source node with the parameter being the target
    // node.
    canLinkToPort(port: PortModel): boolean {
        // Since we'll be merging this result with the parent implementation, we can simply assume that we're
        // matching an input port to an output port.)
        const selfHasCapacity: boolean = Object.keys(this.getLinks()).length === 0;
        const otherHasCapacity: boolean = Object.keys(port.getLinks()).length === 0;

        const linkAllowedByCapacity = this.options.in ? selfHasCapacity : otherHasCapacity;

        // Pins may not be connected to pins.
        const linkAllowedByPins = !(this.isPin && port instanceof LogicPortModel && port.isPin);

        const allowLink = super.canLinkToPort(port) && linkAllowedByCapacity && linkAllowedByPins;

        if (!allowLink) this.checkLinkFailReason(port);

        return allowLink;
    }

    checkLinkFailReason(port: PortModel) {
        if (!(port instanceof LogicPortModel)) return;

        // port linking to itself is not really an issue
        if (this === port) return;

        const isThisInput = this.options.in;
        const isPortInput = port.getOptions().in;
        const isPortFull = Object.keys(port.getLinks()).length === 1;
    
        // The link must be made from an output to an input port,
        if (isThisInput && !isPortInput) {
            this.getParentCanvasModel().fireEvent({}, 'InputToOutput');
            return;
        }
        // The input port can only take one input.
        if (isPortInput && isPortFull) {
            this.getParentCanvasModel().fireEvent({}, 'InputFull');
            return;
        }
        // Input and output ports cannot be linked to each other.
        if (isThisInput === isPortInput) {
            const eventType = isThisInput ? 'InputToInput' : 'OutputToOutput';
            this.getParentCanvasModel().fireEvent({}, eventType);
            return;
        }

        if (port.isPin) {
            this.getParentCanvasModel().fireEvent({}, 'PinToPin');
        }
    }

    // Record the link as the next component when added.
    addLink(link: LinkModel) {
        super.addLink(link);

        // Only output ports keep track of their ongoing links.
        if (this.options.in) return;
        if (!(link instanceof LogicLinkModel)) return;

        this.next.push(link);
    }

    removeLink(link: LinkModel) {
        super.removeLink(link);

        // Only output ports keep track of their ongoing links.
        if (this.options.in) return;
        if (!(link instanceof LogicLinkModel)) return;

        const linkIndex = this.next.indexOf(link);
        this.next.splice(linkIndex, 1);
    }

    updateAndPropagate(): void {
        for (const node of this.next) {
            node.updateAndPropagate();
        }
        this.fireEvent({}, 'logicUpdate');
    }

    serialiseWithoutLinks(): SerialisedComponent {
        return {
            id: this.uuid,
            type: 'port'
        }
    }

    createLinkModel(factory?: AbstractModelFactory<LinkModel>): LinkModel {
        return new LogicLinkModel();
    }

    get inputPort(): LogicPortModel {
        return this;
    }
    get outputPort(): LogicPortModel {
        return this;
    }

    get children(): LogicComponent[] {
        return [];
    }
    setChildren(children: LogicComponent[]) {}

    static deserialiseWithoutChildren(serialised: SerialisedComponent): LogicPortModel {
        return new LogicPortModel({
            name: serialised['id'],
            // All serialised ports are input ones.
            in: true
        });
    }
}