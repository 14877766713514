import {DefaultLinkModel} from "@projectstorm/react-diagrams";
import {detectCycle, LogicComponent, SerialisedComponent} from "./LogicComponent";
import {getColour, LogicState} from "../LogicState";
import {LogicPortModel} from "./LogicPortModel";
import {v4 as uuidv4} from 'uuid';


export interface SerialisedLink {
    source: string, target: string
}


export class LogicLinkModel extends DefaultLinkModel implements LogicComponent {
    logicState: LogicState = LogicState.Unknown;
    next: LogicComponent[] = [];
    uuid: string;
    shouldSerialise = false;

    constructor() {
        super({
            type: 'logic-link'
        });

        this.registerListener({
            targetPortChanged: event => {
                // Update the target port once a connection is established.
                if (event.port instanceof LogicPortModel) this.next = [event.port]

                if (!this.updateState()) return;
                this.checkForCycles();
                this.propagate();

                this.setSelected(false);
            }
        });

        this.uuid = uuidv4();
    }

    updateAndPropagate(): void {
        if (!this.updateState()) return;
        this.propagate();
    }

    // Returns whether the signal should propagate.
    updateState(): boolean {
        if (this.sourcePort !== undefined && (this.sourcePort instanceof LogicPortModel)) {
            // The presence of Error state on the current wire AND the port indicates that the error has already
            // propagated through a potential cycle. This means that despite the update, the error is still present.
            if (this.sourcePort.logicState === LogicState.Error && this.logicState === LogicState.Error) return false;

            this.logicState = this.sourcePort.logicState;
        }
        return true;
    }

    propagate(): void {
        this.setColor(this.getLogicStateColour());

        if (this.targetPort !== undefined && (this.targetPort instanceof LogicPortModel)) {
            this.targetPort.logicState = this.logicState;
            this.targetPort.updateAndPropagate();
        }

        this.fireEvent({}, 'logicUpdate');
    }

    getLogicStateColour(): string {
        return getColour(this.logicState);
    }

    // Look for cycles in the system, and send an error signal if found.
    checkForCycles() {
        if (!detectCycle(this)) return;

        // Once a cycle is found, update to the Error status and immediately send an update.
        this.logicState = LogicState.Error;
        this.getParentCanvasModel().fireEvent({}, 'CycleDetected');
    }

    // Once removed, update the target port to unknown and send an update. This allows the circuit to respond to
    // change in wiring, but also recovers circuits from errors.
    remove() {
        if (this.targetPort === undefined || !(this.targetPort instanceof LogicPortModel)) {
            super.remove();
            return;
        }

        // super.remove actually sets this.targetPort to undefined, so we have to save a pointer beforehand.
        const targetPort = this.targetPort;
        targetPort.logicState = LogicState.Unknown;
        super.remove();
        // AFTER the link has been severed, we can call the propagation to prevent infinite recursions.
        targetPort.updateAndPropagate();
    }

    // Links are not components in the backend because they don't need to exist there (no need to show links on the
    // server). Therefore, this is intentionally left unimplemented because they aren't supposed to be serialised in
    // this way anyway.
    serialiseWithoutLinks(): SerialisedComponent {
        throw new Error("Method not implemented.");
    }
    get inputPort(): LogicPortModel {
        return null;
    }
    get outputPort(): LogicPortModel {
        return null;
    }

    get children(): LogicComponent[] {
        return [];
    }
    setChildren(children: LogicComponent[]) {}
}