import {Simulation} from "./Simulation";
import React from "react";
import {Status} from "./Status";
import {Card, MaterialColor, TabbedView} from "../basic-components";
import {Height} from "../basic-components/Height";
import {Chart} from "react-google-charts";
import {TableWidget} from "../data/table-component";
import {defaultFactory, LogicEditor} from "../logic-builder";


interface SimulationWidgetProps extends Simulation {

}

export class SimulationDataView extends React.Component<SimulationWidgetProps> {
    get chartOptions() {
        const titleTextStyle = {
            color: 'white',
            fontName: 'Roboto',
            fontSize: '20px',
            bold: true,
            italic: false
        };

        return {
            chart: {
                title: 'Chart Title'
            },
            backgroundColor: 'none',
            legend: {
                textStyle: {
                    color: 'white',
                }
            },
            hAxis: {
                title: 'Time (s)',
                textStyle: {
                    color: 'white'
                },
                gridlines: {
                    color: 'none'
                },
                baselineColor: 1920301,
                titleTextStyle: titleTextStyle
            },
            vAxis: {
                title: 'Concentration (nM)',
                textStyle: {
                    color: 'white'
                },
                gridlines: {
                    color: 'grey'
                },
                minorGridlines: {
                    color: 'none'
                },
                color: 'red',
                baselineColor: '#fff',
                titleTextStyle: titleTextStyle
            }
        }
    }

    renderGraph() {
        return <Chart
            chartType={'LineChart'}
            width={'100%'}
            height={'500px'}
            data={this.props.concentrations.toGoogleChartsData()}
            options={this.chartOptions}
        />
    }
    renderTable() {
        return <TableWidget allowAddingOrRemovingRows={false} allowAddingColumns={false} table={this.props.concentrations}/>
    }
    renderCode() {
        return <code style={{
            backgroundColor: 'var(--surface-container-low)',
            display: 'block',
            padding: '10px'
        }}>
            <span style={{whiteSpace: 'pre-line'}}>{this.props.dsdCode}</span>
        </code>
    }

    renderTab(index: number) {
        switch (index) {
            case 0:
                return this.renderGraph();
            case 1:
                return this.renderTable();
            case 2:
                return this.renderCode();
            case 3:
                return this.renderSVG();
            case 4:
                return this.renderCircuit();
        }
    }

    renderSVG() {
        return <div className={'initials-svg-holder'} style={{
            flexGrow: 1,
            minHeight: 0,
            overflowX: 'scroll',
            overflowY: 'scroll'
        }} dangerouslySetInnerHTML={{
            __html: this.props.svgContent
        }}>
        </div>
    }

    renderCircuit() {
        return <div style={{
            width: '100%',
            height: '100%'
        }}>
            <LogicEditor engine={this.props.circuit} factory={defaultFactory} noEditing={true}/>
        </div>
    }

    renderPending() {
        return <Card color={MaterialColor.Normal} height={Height.Medium} title={'Simulation pending...'}
                     bodyStyle={{height: '100%'}}>
            {this.props.circuit ? this.renderCircuit() : ''}
        </Card>
    }
    renderSuccess() {
        return <TabbedView tabNames={['Graph', 'Table', 'Code', 'Reactions', ...(this.props.circuit ? ['Circuit'] : [])]}
                           renderTab={(index) => this.renderTab(index)}
                           contentStyle={{height: '100%'}}/>
    }
    renderFailed() {
        return <Card color={MaterialColor.Error} height={Height.Medium} title={'Simulation Failed'}>
            {'Something went wrong. '}
        </Card>
    }
    renderNoConnection() {
        return <Card color={MaterialColor.Error} height={Height.Medium} title={'No Connection'}>
            {'Nucleio API is not available. '}
        </Card>
    }
    renderNotStarted() {
        return <Card color={MaterialColor.Normal} height={Height.Medium} title={'Simulation not started...'}
                     bodyStyle={{height: '100%'}}
        >
            {this.props.circuit ? this.renderCircuit() : ''}
        </Card>
    }


    render() {
        switch (this.props.status) {
            case Status.Pending:
                return this.renderPending();
            case Status.Success:
                return this.renderSuccess();
            case Status.Failed:
                return this.renderFailed();
            case Status.NoConnection:
                return this.renderNoConnection();
            case Status.NotStarted:
                return this.renderNotStarted();
        }
    }
}

