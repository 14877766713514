import {SchemaEntry, Str, Table, TableSchema} from "../data";
import React from "react";
import {TableEditor} from "../data/table-component";


interface WTAMemoriesEditorProps {
    table?: Table
    onTableChange?: (table?: Table) => void
    // Called when the table's validity state changes.
    onValidityUpdate?: (valid: boolean) => void
}

const wtaMemoriesDesc = "Create or upload a CSV file of the memories for your WTA network here. The first column is the name of the memories. The titles for the remaining columns are the input names, and the cells represent the weights corresponding to the inputs. Weights must be between 0 and 1.";
// const wtaFormatRequirements = "The first column is the name of the memories. The titles for the remaining columns are the input names, and the cells represent the weights corresponding to the inputs. Weights must be between 0 and 1."

export class WTAMemoriesEditor extends React.Component<WTAMemoriesEditorProps> {
    checkTable(table: Table) {
        const violations = [];

        if (table.numColumns < 2) {
            violations.push(['There must be at least one input.']);
        }
        if (table.numColumns > 75) {
            violations.push(['There must be less than 75 inputs.']);
        }
        if (table.numRows === 0) {
            violations.push(['No memories.']);
        }
        if (table.numRows > 4) {
            violations.push(['Only four memories or less are supported.'])
        }

        const inputNames = table.schema.entries.filter((x, i) => i > 0).map((x) => x.fieldName);
        if (inputNames.some((name) => name.length === 0)) {
            violations.push(['Empty input names.']);
        }
        const inputNamesSet = new Set(inputNames);
        if (inputNamesSet.size < inputNames.length) {
            violations.push(['Duplicate input names.']);
        }

        const memoryNames = table.column(0).cells.map((cell) => cell.toString());
        if (memoryNames.some((name) => name.length === 0)) {
            violations.push(['Empty memory names.']);
        }
        const memoryNameSet = new Set(memoryNames);
        if (memoryNameSet.size < memoryNames.length) {
            violations.push(['Duplicate memory names.']);
        }

        if (!table.valid) {
            violations.push(['Cells must have value between 0 and 1.'])
        }

        return violations;
    }

    handleTableCompletelyChanged(table?: Table) {
        if (table) {
            const newSchemaEntries = table.schema.entries.map((entry, index) => {
                if (index === 0) {
                    return new SchemaEntry('Memory', new Str({}), false, true);
                } else {
                    return SchemaEntry.num01(entry.fieldName);
                }
            });
            if (newSchemaEntries.length === 0) {
                newSchemaEntries.push(new SchemaEntry('Memory', new Str({}), false, true));
            }

            table.setSchema(new TableSchema(newSchemaEntries));
        }
        this.handleTableUpdate(table);
    }

    handleTableUpdate(table?: Table) {
        if (this.props.onTableChange) {
            this.props.onTableChange(table);
        }
    }

    handleViolationsUpdate(violations: string[]) {
        if (this.props.onValidityUpdate) {
            this.props.onValidityUpdate(violations.length === 0);
        }
    }

    render() {
        return <TableEditor table={this.props.table}
                            infoPanelTitle={"WTA Memories"}
                            infoPanelDesc={wtaMemoriesDesc}
                            checkTable={(t) => this.checkTable(t)}
                            tableTitle={"WTA Memories"}
                            allowReplacing={true}
                            allowAddingOrRemovingRows={true}
                            allowAddingColumns={true}
                            onTableCompletelyChanged={(t) => this.handleTableCompletelyChanged(t)}
                            onAnythingChanged={(t) => this.handleTableUpdate(t)}
                            // formatRequirements={wtaFormatRequirements}
                            createEmptyTable={() => Table.dummyWTATable()}
                            createSchemaEntry={() => SchemaEntry.num01('')}
                            onViolationsUpdate={(violations) => this.handleViolationsUpdate(violations)}
        />
    }
}
