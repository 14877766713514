// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.icon-button {
    border-radius: 50%;
    transition-duration: 0.1s;

    display: flex;

    cursor: pointer;
}

div.icon-button:hover {
    background: var(--surface-container);
}

div.icon-button:active {
    background: var(--surface-container-highest);
}

div.icon-button * {
    font-size: 18px;
}

.material-button {
    border-radius: 5px;
    padding: 5px 15px;

    -webkit-user-select: none;

            user-select: none;

    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

    transition-duration: 0.1s;

    cursor: pointer;

    text-align: center;

    text-decoration: none;
}

.material-button:hover {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    filter: brightness(85%);
}

.material-button:active {
    filter: brightness(75%);
}

.material-button.disabled {
    opacity: 0.5;
    filter: grayscale(50%);

    cursor: not-allowed;
}

.material-button.primary {
    background-color: var(--primary);
    color: var(--on-primary);
}

.material-button.secondary {
    background-color: var(--secondary);
    color: var(--on-secondary);
}

.material-button.tertiary {
    background-color: var(--tertiary);
    color: var(--on-tertiary);
}

`, "",{"version":3,"sources":["webpack://./src/css/button.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,yBAAyB;;IAEzB,aAAa;;IAEb,eAAe;AACnB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,4CAA4C;AAChD;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;;IAEjB,yBAAiB;;YAAjB,iBAAiB;;IAEjB,kEAAkE;;IAElE,yBAAyB;;IAEzB,eAAe;;IAEf,kBAAkB;;IAElB,qBAAqB;AACzB;;AAEA;IACI,kEAAkE;IAClE,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,sBAAsB;;IAEtB,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;IAChC,wBAAwB;AAC5B;;AAEA;IACI,kCAAkC;IAClC,0BAA0B;AAC9B;;AAEA;IACI,iCAAiC;IACjC,yBAAyB;AAC7B","sourcesContent":["div.icon-button {\r\n    border-radius: 50%;\r\n    transition-duration: 0.1s;\r\n\r\n    display: flex;\r\n\r\n    cursor: pointer;\r\n}\r\n\r\ndiv.icon-button:hover {\r\n    background: var(--surface-container);\r\n}\r\n\r\ndiv.icon-button:active {\r\n    background: var(--surface-container-highest);\r\n}\r\n\r\ndiv.icon-button * {\r\n    font-size: 18px;\r\n}\r\n\r\n.material-button {\r\n    border-radius: 5px;\r\n    padding: 5px 15px;\r\n\r\n    user-select: none;\r\n\r\n    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);\r\n\r\n    transition-duration: 0.1s;\r\n\r\n    cursor: pointer;\r\n\r\n    text-align: center;\r\n\r\n    text-decoration: none;\r\n}\r\n\r\n.material-button:hover {\r\n    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);\r\n    filter: brightness(85%);\r\n}\r\n\r\n.material-button:active {\r\n    filter: brightness(75%);\r\n}\r\n\r\n.material-button.disabled {\r\n    opacity: 0.5;\r\n    filter: grayscale(50%);\r\n\r\n    cursor: not-allowed;\r\n}\r\n\r\n.material-button.primary {\r\n    background-color: var(--primary);\r\n    color: var(--on-primary);\r\n}\r\n\r\n.material-button.secondary {\r\n    background-color: var(--secondary);\r\n    color: var(--on-secondary);\r\n}\r\n\r\n.material-button.tertiary {\r\n    background-color: var(--tertiary);\r\n    color: var(--on-tertiary);\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
