import {Table} from "../data";
import {Status} from "./Status";
import {LogicDiagramEngine} from "../logic-builder";

export interface Simulation {
    status: Status,
    dsdCode?: string,
    concentrations?: Table,
    svgContent?: string,
    // Used to display the logic circuit if this is a logic simulation.
    circuit?: LogicDiagramEngine
}

export function emptySimulation() {
    return {
        status: Status.NotStarted
    };
}

export function pendingSimulation() {
    return {
        status: Status.Pending
    }
}

export interface SimulationOptions {
    leaks: boolean,
    time: number
}

export function defaultSimulationOptions() {
    return {
        leaks: false,
        time: 28800
    }
}
