import React from "react";
import {Table} from "../data";
import {
    defaultSimulationOptions,
    emptySimulation, getStatusColor,
    getStatusText,
    Nucleio,
    Simulation,
    SimulationOptions,
    Status
} from "../nucleio";
import {Button, ListSelector, MaterialColor} from "../basic-components";
import {SimulationView} from "../nucleio/SimulationView";


interface WTASimulationManagerProps {
    wtaModel: Table,
    simulationInfos: WTASimulationInfo[],
    simulationsCount: number,
    updateSimulations: (infos: WTASimulationInfo[]) => void,
    updateSimulationCount: (count: number) => void
}

export interface WTASimulationInfo {
    name: string,
    simulation: Simulation,
    options: SimulationOptions,
    canSimulate: boolean,
    inputs: boolean[],
    inputNames: string[]
}

interface WTASimulationManagerState {
    selectedSimulation: number,
}

export class WTASimulationManager extends React.Component<WTASimulationManagerProps, WTASimulationManagerState> {
    constructor(props) {
        super(props);

        this.state = {
            selectedSimulation: -1,
        };
    }

    newSimulationInfo(index: number): WTASimulationInfo {
        return {
            name: `Simulation ${index}`,
            simulation: emptySimulation(),
            options: {
                leaks: false,
                time: 28800
            },
            canSimulate: true,
            inputs: this.getInputNames().map(() => false),
            inputNames: this.getInputNames()
        }
    }

    getInputNames(): string[] {
        return this.props.wtaModel.schema.entries.map((entry) => entry.fieldName)
            .filter((n, i) => i > 0);
    }

    handleSelectionChange(index: number) {
        this.setState({
            selectedSimulation: index
        });
    }

    handleAddSimulation() {
        const newSimulationsCount = this.props.simulationsCount + 1;
        this.props.updateSimulations(
            this.props.simulationInfos.concat([this.newSimulationInfo(newSimulationsCount)])
        );
        this.props.updateSimulationCount(newSimulationsCount);
    }

    handleSimulationUpdate(index: number, simulation: Simulation) {
        this.props.updateSimulations(this.props.simulationInfos.map((sim, i) => {
                if (i === index) {
                    return {
                        ...sim,
                        simulation: simulation,
                        canSimulate: simulation.status !== Status.Pending
                    }
                }
                return sim
            })
        );
    }

    handleOptionsChange(index: number, options: SimulationOptions) {
        this.props.updateSimulations(this.props.simulationInfos.map((info, i) => {
                if (i === index) {
                    return {
                        ...info,
                        options: options
                    }
                }
                return info
            })
        );
    }

    handleInputsChange(index: number, inputs: boolean[]) {
        this.props.updateSimulations(this.props.simulationInfos.map((info, i) => {
            if (i === index) {
                return {
                    ...info,
                    inputs: inputs
                }
            }
            return info
        }));
    }

    getSimulationListItems() {
        return this.props.simulationInfos.map((info) => {
            return {
                title: info.name,
                subtitle: getStatusText(info.simulation.status),
                color: getStatusColor(info.simulation.status)
            }
        })
    }

    handleSimulate(inputs: boolean[], options: SimulationOptions) {
        return Nucleio.runWTASimulation(inputs, this.props.wtaModel, options.leaks, options.time);
    }

    renderSimulationSelector() {
        return <ListSelector items={this.getSimulationListItems()} title={"Simulations"}
                             onSelectionChange={(i) => this.handleSelectionChange(i)}
                             style={{minHeight: 0, flexGrow: 1, flexShrink: 1}}
                             selectedItem={this.state.selectedSimulation}/>
    }

    renderControlBar() {
        return <div style={{
            width: '200px',
            flexGrow: 0,
            flexShrink: 0,
            display: "flex",
            flexDirection: 'column',
            gap: '20px'
        }}>
            {this.renderSimulationSelector()}
            <Button content={'New Simulation'} color={MaterialColor.Primary} style={{flexShrink: 0}}
                    onClick={() => this.handleAddSimulation()}/>
        </div>
    }

    renderSimulationView() {
        const currentSelection = this.state.selectedSimulation;
        if (currentSelection < 0) {
            return <span style={{
                userSelect: 'none',
                color: 'var(--on-background)'
            }}>No simulation selected. </span>
        }

        // If there is a mismatch, the simulation is inoperable.
        const inputsMismatch = JSON.stringify(this.props.simulationInfos[currentSelection].inputNames) !== JSON.stringify(this.getInputNames());

        return <SimulationView key={currentSelection} disabled={inputsMismatch}
                               simulate={(inputs, options) => this.handleSimulate(inputs, options)}
                               onInputsChange={(inputs) => this.handleInputsChange(currentSelection, inputs)}
                               onOptionsChange={(options) => this.handleOptionsChange(currentSelection, options)}
                               onSimulationUpdate={(simulation) => this.handleSimulationUpdate(currentSelection, simulation)}
                               {...this.props.simulationInfos[currentSelection]}/>
    }

    render() {
        return <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            alignItems: 'stretch'
        }}>
            {this.renderControlBar()}
            <div style={{
                flexGrow: 1
            }}>
                {this.renderSimulationView()}
            </div>
        </div>
    }
}

