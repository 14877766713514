import React, {ChangeEvent, CSSProperties} from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import {Card} from "./Card";
import {MaterialColor} from "./MaterialColor";
import {Height} from "./Height";
import AddIcon from '@mui/icons-material/Add';


interface ListEditorProps {
    items: string[];
    onListChange: (newItems: string[]) => void;
    title: string;
    maxListSize: number;
    style?: CSSProperties
}

interface ListItemProps {
    content: string,
    onContentChange: (newContent: string) => void,
    onItemDelete: () => void
}

class ListItem extends React.Component<ListItemProps> {
    handleChange(e: ChangeEvent<HTMLInputElement>) {
        this.props.onContentChange(e.target.value);
    }

    handleDelete() {
        this.props.onItemDelete();
    }

    render() {
        return <div className={'list-editor-item'}>
            <input onChange={(e) => this.handleChange(e)} value={this.props.content}/>
            <div className={'icon-button'} onClick={() => this.handleDelete()}>
                <DeleteIcon/>
            </div>
        </div>
    }
}


export class ListEditor extends React.Component<ListEditorProps> {
    handleContentChange(index: number, newContent: string) {
        this.props.onListChange(this.props.items.map((x, i) => i === index ? newContent : x));
    }

    handleItemDelete(index: number) {
        this.props.onListChange(this.props.items.filter((x, i) => i !== index));
    }

    handleItemAdd() {
        this.props.onListChange(this.props.items.concat(['']))
    }

    renderAddButton() {
        return <div className={'add-button'} onClick={() => this.handleItemAdd()}>
            <AddIcon/>
        </div>
    }

    render() {
        return <Card color={MaterialColor.Normal} height={Height.Medium} title={this.props.title}
                     cardStyle={this.props.style}>
            <div className={'list-editor-inner-container'}>
                {this.props.items.map((item, index) => <ListItem
                    content={item} onItemDelete={() => this.handleItemDelete(index)} key={index}
                    onContentChange={(content) => this.handleContentChange(index, content)}
                />)}
                {this.props.items.length < this.props.maxListSize ? this.renderAddButton() : ''}
            </div>
        </Card>
    }
}

