import {Nucleio} from "./Nucleio";
import React from "react";
import {Card, MaterialColor} from "../basic-components";
import {Height} from "../basic-components/Height";
import {StrandGenerationForm} from "./StrandGenerationForm";
import {TableCard} from "../data/table-component";


interface StrandGenerationViewProps {
    generateStrands: (threshold: boolean) => Promise<Nucleio.StrandsOutput>
    tables?: Nucleio.StrandsOutput
    setTables: (output: Nucleio.StrandsOutput) => void
}

interface StrandGenerationViewState {
    canGenerate: boolean
    error: boolean
}

export class StrandGenerationView extends React.Component<StrandGenerationViewProps, StrandGenerationViewState> {
    constructor(props) {
        super(props);

        this.state = {
            canGenerate: true,
            error: false
        };
    }

    handleGenerateStrands(threshold: boolean) {
        this.setState({
            canGenerate: false
        });

        this.props.generateStrands(threshold)
            .then((result) => {
                this.props.setTables(result)
                this.setState({
                    canGenerate: true,
                    error: false,
                })
            }).catch(() => {
                this.setState({
                    canGenerate: true,
                    error: true
                })
            })
    }


    renderInfobar() {
        return <div style={{
            width: '220px',
            flexShrink: '0',
            flexGrow: '0',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
        }}>
            <Card height={Height.Medium} color={MaterialColor.Normal} title={'Generate Strands'}>
                Generate the strands needed to build your circuit, with instructions for which
                strands to anneal and what concentrations to add.
            </Card>
            <Card height={Height.Medium} color={MaterialColor.Tertiary} title={'ADC Strands'}>
                ADC strands will be generated if the target regions from the previous page were filled.
                You may choose to include thresholding gates for these strands.
            </Card>
            <StrandGenerationForm canGenerate={this.state.canGenerate} error={this.state.error}
                                  generateStrands={threshold => this.handleGenerateStrands(threshold)}/>
        </div>
    }

    renderStrandsTable() {
        return <TableCard tableTitle={"Strands"} allowReplacing={false}
                          table={this.props.tables.strands} style={{flexGrow: 1, minWidth: 0}}
                          allowAddingOrRemovingRows={false} allowAddingColumns={false}/>
    }
    renderInstructionsTable() {
        return <TableCard tableTitle={"Assembly Instructions"} allowReplacing={false}
                          table={this.props.tables.instructions} style={{flexGrow: 1, minWidth: 0}}
                          allowAddingOrRemovingRows={false} allowAddingColumns={false}/>
    }

    render() {
        return <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            alignItems: 'stretch'
        }}>
            {this.renderInfobar()}
            {this.props.tables ? this.renderStrandsTable() : ''}
            {this.props.tables ? this.renderInstructionsTable() : ''}
        </div>
    }
}



