import React from "react";

interface TrayWidgetProps {
    children: React.ReactNode
}


export class TrayWidget extends React.Component<TrayWidgetProps> {
    render() {
        return <div className="drag-and-drop-tray">
            {this.props.children}
        </div>;
    }
}