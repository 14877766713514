import {Cell} from "./Cell";
import {SchemaEntry, TableSchema} from "./TableSchema";
import {TableRow} from "./TableRow";
import {TableColumn} from "./TableColumn";
import {pad} from "./utils";
import Papa from "papaparse";
import {Num} from "./validation";


// Makes sure that the list is long enough, trimming off extras and filling in empty strings if needed.
function ensureListSize(list: string[], targetSize: number) {
    if (list.length >= targetSize) {
        return list.slice(0, targetSize);
    }

    while (list.length < targetSize) {
        list = list.concat(['']);
    }

    return list;
}

export class Table {
    schema: TableSchema;
    // Indexed by rows, then columns.
    private cells: Cell[][];

    // Constructor primarily for internal use. Has no validation.
    private constructor(schema: TableSchema, cells: Cell[][]) {
        this.schema = schema;
        this.cells = cells;
    }

    static empty() {
        return new Table(new TableSchema([]), [])
    }
    static schemaOnly(schema: TableSchema) {
        return new Table(schema, []);
    }
    // Creates a table from raw data (raw meaning that the data is already standardised, numbers are numbers, etc.)
    static fromRaw(schema: TableSchema,
                  // Indexed by rows, then columns
                  content: any[][]) {
        return new Table(
            schema,
            content.map((rowRaw) => schema.createRowCells(rowRaw))
        )
    }
    static fromSchemaAndCSV(schema: TableSchema, csvContent: string) {
        const parsedResult = Papa.parse(csvContent);

        if (parsedResult.errors.length > 0) {
            throw new Error('Invalid CSV structure.');
        }

        return this.fromRaw(schema, (parsedResult.data as any[][])
            .map((line) => ensureListSize(line, schema.numColumns)));
    }
    static fromCSV(csvContent: string) {
        const parsedResult = Papa.parse(csvContent);

        if (parsedResult.errors.length > 0) {
            throw new Error('Invalid CSV structure.');
        }

        const rowsRaw = parsedResult.data as string[][];

        if (rowsRaw.length < 1) {
            throw new Error('Invalid table.');
        }

        const tableWidth = rowsRaw.map((row) => row.length).reduce(
            (prev, curr) => Math.max(prev, curr), 0);
        for (let i = 0; i < rowsRaw.length; i++) {
            rowsRaw[i] = ensureListSize(rowsRaw[i], tableWidth);
        }

        const headers = rowsRaw[0];
        // Cut off the headers
        rowsRaw.splice(0, 1);
        const schemaEntries = headers.map((columnName) => new SchemaEntry(columnName));

        return this.fromStrings(new TableSchema(schemaEntries), rowsRaw);
    }

    // Creates a table from data where each cell is a string (suitable for making tables from CSV data).
    static fromStrings(schema: TableSchema, content: string[][]) {
        return new Table(
            schema,
            content.map((rowStrings) => schema.createRowCellsFromStrings(rowStrings))
        )
    }


    // ------------------------ Row Operations ------------------------
    get numRows(): number {
        return this.cells.length;
    }

    addRow(row: TableRow) {
        this.cells.push(this.schema.createRowCells(row.cells.map((cell) => cell.getContent())))
    }
    addRowData(contents: any[]) {
        this.cells.push(this.schema.createRowCells(contents));
    }
    addRowFromStrings(contents: string[]) {
        this.cells.push(this.schema.createRowCellsFromStrings(contents));
    }
    addEmptyRow() {
        this.addRowData(
            this.schema.entries.map((entry) => entry.validation.getDefaultValue())
        );
    }

    row(index: number) {
        return new TableRow(this.schema, this.cells[index]);
    }
    getRows(): TableRow[] {
        return this.cells.map((rowCells) => new TableRow(this.schema, rowCells))
    }
    // Returns the table row and its index.
    findRow(predicate: (row: TableRow, index: number) => boolean): [number, TableRow] {
        const rows = this.getRows();
        for (let i = 0; i < this.numRows; i++) {
            if (predicate(rows[i], i)) {
                return [i, rows[i]]
            }
        }

        return [-1, null];
    }
    selectRows(predicate: (row: TableRow, index: number) => boolean): Table {
        return new Table(
            this.schema,
            this.getRows().filter((row, index) => predicate(row, index))
                .map((row) => row.cells)
        )
    }

    removeRow(index: number) {
        this.cells.splice(index, 1);
    }


    // ------------------------ Column Operations ------------------------
    get numColumns(): number {
        return this.schema.numColumns;
    }

    addColumnWithData(schemaEntry: SchemaEntry, data: any[]) {
        if (this.numRows === 0) {
            this.cells = data.map(() => []);
        }

        if (data.length !== this.numRows) throw new Error('Wrong number of rows.')

        data.map((content) => schemaEntry.createCell(content)).forEach((cell, index) => {
            this.cells[index].push(cell);
        })
        this.schema.entries.push(schemaEntry);
    }
    // Add a column and fill it based on a given function.
    addColumnFill(schemaEntry: SchemaEntry, filler: (row: TableRow, index: number) => any) {
        this.getRows().map((row, index) => schemaEntry.createCell(filler(row, index)))
            .forEach((cell, index) => {
                this.cells[index].push(cell);
            });
        this.schema.entries.push(schemaEntry);
    }
    addColumn(column: TableColumn) {
        if (this.numRows === 0) {
            this.cells = column.cells.map(() => []);
        }

        if (column.length !== this.numRows) throw new Error('Wrong number of rows.')

        this.cells.forEach((row, index) => {
            row.push(column.cells[index]);
        })
        this.schema.entries.push(column.schema);
    }
    addEmptyColumn(schemaEntry: SchemaEntry) {
        this.addColumnFill(schemaEntry, () => schemaEntry.validation.getDefaultValue());
    }

    // Query a column based on the index in the table or by its name.
    column(index: number | string) {
        let numberIndex: number;

        if ((typeof index) === 'number') numberIndex = index as number;
        if ((typeof index) === 'string') {
            numberIndex = this.schema.entries.map((entry) => entry.fieldName).indexOf(index as string);
        }

        return new TableColumn(
            this.schema.entries[numberIndex],
            this.cells.map((row) => row[numberIndex])
        )
    }
    getColumns(): TableColumn[] {
        return this.schema.entries.map((_, index) => this.column(index));
    }

    selectColumns(...indices: (number | string)[]): Table {
        const columns = indices.map((i) => this.column(i));

        const empty = Table.empty();
        for (const column of columns) {
            empty.addColumn(column);
        }

        return empty;
    }

    removeColumn(index: number | string) {
        let numberIndex: number;
        if ((typeof index) === 'number') numberIndex = index as number;
        if ((typeof index) === 'string') {
            numberIndex = this.schema.entries.map((entry) => entry.fieldName).indexOf(index as string);
        }

        for (const row of this.cells) {
            row.splice(numberIndex, 1);
        }
        this.schema.entries.splice(numberIndex, 1);
    }

    // ------------------------ Table Operations ------------------------
    joinVertical(other: Table) {
        for (const row of other.getRows()) {
            this.addRow(row);
        }
    }

    joinHorizontal(other: Table) {
        for (const column of other.getColumns()) {
            this.addColumn(column);
        }
    }

    prettyPrint() {
        const schemaWidth = this.schema.entries.map((entry) => entry.fieldName.length);
        // Did you know that obsessing over functional programming and spamming filter map reduce reduces your code
        // readability? I, for one, believe that this is the most obvious code ever written.
        // First convert each Cell[] => number[], where each number is the length of the cell's string representation.
        const contentWidth = this.cells.map((row) => row.map((cell) => cell.toString().length))
            // Reduce two number[]s by taking their maxima element wise to get a new number[]
            .reduce(((previousValue, currentValue) =>
                previousValue.map((width, index) => Math.max(width, currentValue[index]))))
        // This results in an array representing the max width of each column. We reduce this further to get the
        // maximum widths over the entire table.
        const widths = schemaWidth.map((width, index) => Math.max(width, contentWidth[index]))

        const headerLine = '|' + this.schema.entries
            .map((entry, index) => ` ${pad(entry.fieldName, widths[index])} |`).join('');
        const dividerLine = '|' + this.schema.entries
            .map((_, index) => `${"-".repeat(widths[index] + 2)}|`).join('');

        const rowLines = this.cells.map((row) =>
            '|' + row.map((cell, index) => ` ${pad(cell.toString(), widths[index])} |`).join(''))
            .join('\n');

        return `${headerLine}\n${dividerLine}\n${rowLines}`;
    }
    clone(): Table {
        return new Table(
            this.schema,
            this.cells.map(rowCells => rowCells.map(cell => cell.clone())) // Clone each cell
        );
    }

    // Updates the current table's schema, adding columns if needed.
    // To preserve the table's structure, no extra columns will be removed.
    setSchema(schema: TableSchema) {
        schema.entries.forEach((entry, index) => {
            if (index < this.schema.numColumns) {
                this.schema.entries[index] = entry;
                for (const rowCells of this.cells) {
                    rowCells[index].validation = entry.validation;
                    // Reparse the items if needed.
                    rowCells[index].setContentString(rowCells[index].toString());
                }
            } else {
                this.addEmptyColumn(entry);
            }
        });
    }

    toCSVText(): string {
        const header = this.schema.entries.map((entry) => entry.fieldName);
        const data = this.cells.map(rowCells => rowCells.map(cell => cell.getContent()));

        return Papa.unparse({
            'fields': header,
            'data': data
        })
    }

    serialise(): any {
        const header = this.schema.entries.map((entry) => entry.fieldName);
        const data = this.cells.map(rowCells => rowCells.map(cell => cell.getContent()));

        return {
            'header': header,
            'content': data
        }
    }
    static deserialiseWithSchema(schema: TableSchema, serialised: any) {
        return Table.fromRaw(schema, serialised['content']);
    }
    static deserialise(serialised: any) {
        const schemaEntries = serialised['header'].map((name) => new SchemaEntry(name));
        return this.deserialiseWithSchema(new TableSchema(schemaEntries), serialised);
    }

    static dummySimulationTable() {
        const concentrationValidation = new Num({
            greaterOrEqual: 0, lessOrEqual: 6
        })

        const schemaEntries = [
            new SchemaEntry('time', new Num({}), false, false),
            new SchemaEntry('in1', concentrationValidation, false, false),
            new SchemaEntry('in2', concentrationValidation, false, false),
            new SchemaEntry('out1', concentrationValidation, false, false),
            new SchemaEntry('out2', concentrationValidation, false, false)
        ]

        return Table.fromRaw(new TableSchema(schemaEntries), [
            [1, 1, 2, 5, 1],
            [2, 2, 3, 4, 4],
            [3, 3, 2, 3, 5],
            [4, 4, 3, 2, 4],
            [5, 5, 2, 1, 5],
            [8, 6, 3, 0, 6],
        ])
    }

    static dummyWTATable() {
        const schemaEntries = [
            new SchemaEntry('Memory'),
            SchemaEntry.num01('A'),
            SchemaEntry.num01('B'),
            SchemaEntry.num01('C'),
        ]

        return Table.fromRaw(new TableSchema(schemaEntries), [
            ['memory1', 1, 0, 0],
            ['memory2', 0, 1, 0],
            ['memory3', 0, 0, 1]
        ]);
    }

    toGoogleChartsData() {
        const header = this.schema.entries.map((entry) => entry.fieldName);
        const data = this.cells.map(rowCells => rowCells.map(cell => cell.getContent()));

        return [header].concat(data);
    }

    get valid(): boolean {
        return this.cells.every((rowCells) => rowCells.every((cell) => cell.valid));
    }
}