import * as React from 'react';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import {XOrGateModel} from "./XOrGateModel";
import {LogicGateWidget} from "./LogicGateWidget";

export interface XOrGateWidgetProps {
    node: XOrGateModel;
    engine: DiagramEngine;
}

export interface XOrGateWidgetState {}

export class XOrGateWidget extends React.Component<XOrGateWidgetProps, XOrGateWidgetState> {
    constructor(props: XOrGateWidgetProps) {
        super(props);
        this.state = {};
    }

    render() {
        return <LogicGateWidget node={this.props.node} engine={this.props.engine}/>;
    }
}