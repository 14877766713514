import {LogicState} from "../LogicState";
import {LogicGateModel, LogicGateModelOptions} from "./LogicGateModel";
import {SerialisedComponent} from "../logic-components/LogicComponent";


export class XOrGateModel extends LogicGateModel {
    constructor(options: LogicGateModelOptions = { bodyClass: 'xor-gate' }) {
        super({
            ...options,
            type: 'or-gate-node'
        });
    }

    determineLogicStateFromInputs(in1: LogicState, in2: LogicState): LogicState {
        if (in1 === LogicState.Error || in2 === LogicState.Error) return LogicState.Error;
        if (in1 === LogicState.Unknown || in2 === LogicState.Unknown) return LogicState.Unknown;

        return in1 === in2 ? LogicState.False : LogicState.True;
    }
    static deserialiseWithoutChildren(serialised: SerialisedComponent): XOrGateModel {
        return new XOrGateModel()
    }

    get serialisedType(): string {
        return 'xor_gate'
    }
}