import React, {CSSProperties} from "react";
import {Card, Checkbox, MaterialColor} from "../basic-components";
import {Height} from "./Height";


export interface CheckboxFormProps {
    title: string,
    inputNames: string[],
    onChange?: (input: boolean[]) => void,
    cardStyle?: CSSProperties,
    inputs: boolean[],
    disabled?: boolean
}


export class CheckboxForm extends React.Component<CheckboxFormProps> {
    constructor(props) {
        super(props);

        this.state = {
            inputs: props.inputNames.map(() => false)
        };
    }

    handleChange(index: number, value: boolean) {
        const newInputs = this.props.inputs.map((v, i) => index === i ? value : v);

        this.setState({
            inputs: newInputs
        });
        if (this.props.onChange) {
            this.props.onChange(newInputs);
        }
    }

    render() {
        return <Card height={Height.Medium} color={MaterialColor.Normal} title={this.props.title}
                     cardStyle={this.props.cardStyle}>
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '5px'
            }}>
                {this.props.inputNames.map((name, index) =>
                    <Checkbox label={name} color={MaterialColor.Primary} key={index} flip={true} disabled={this.props.disabled}
                              onChange={(v) => this.handleChange(index, v)} selected={this.props.inputs[index]}/>)}
            </div>
        </Card>
    }
}

