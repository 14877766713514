import {TableSchema} from "./TableSchema";
import {Cell} from "./Cell";
import {pad} from "./utils";

export class TableRow {
    readonly schema: TableSchema
    readonly cells: Cell[]

    constructor(schema: TableSchema, cells: Cell[]) {
        this.schema = schema;
        this.cells = cells;
    }

    private getCell(index: number | string) {
        if ((typeof index) === "number") {
            return this.cells[index];
        }
        for (let i = 0; i < this.schema.entries.length; i++) {
            if (this.schema.entries[i].fieldName === index) {
                return this.cells[i];
            }
        }
        return null;
    }

    get(i: number | string): any {
        return this.getCell(i).getContent();
    }

    set(i: number | string, content: any) {
        this.getCell(i).setContent(content);
    }

    get width(): number {
        return this.cells.length;
    }

    prettyPrint(): string {
        let result = '';

        const schemaWidths = this.schema.entries.map((entry) => entry.fieldName.length);
        const contentWidths = this.cells.map((cell) => cell.toString().length);
        const widths = schemaWidths.map((width, index) => Math.max(width, contentWidths[index]));

        // Pretty Print Schema
        result += '|'
        for (let i = 0; i < this.width; i++) {
            result += ` ${pad(this.schema.entries[i].fieldName, widths[i])} `;
            result += '|';
        }
        result += '\n'

        // Divider
        result += '|'
        for (let i = 0; i < this.width; i++) {
            result += '-'.repeat(widths[i] + 2);
            result += '|';
        }
        result += '\n'

        // Content
        result += '|';
        for (let i = 0; i < this.width; i++) {
            result += ` ${pad(this.cells[i].toString(), widths[i])} `;
            result += '|';
        }
        
        return result
    }
}