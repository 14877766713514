import {LogicState} from "../LogicState";
import {LogicGateModel, LogicGateModelOptions} from "./LogicGateModel";
import {SerialisedComponent} from "../logic-components/LogicComponent";

export interface AndGateOptions extends LogicGateModelOptions {}

export class AndGateModel extends LogicGateModel {
    constructor(options: AndGateOptions = { bodyClass: 'and-gate' }) {
        super({
            ...options,
            type: 'and-gate-node'
        });
    }

    determineLogicStateFromInputs(in1: LogicState, in2: LogicState): LogicState {
        if (in1 === LogicState.Error || in2 === LogicState.Error) return LogicState.Error;
        if (in1 === LogicState.False || in2 === LogicState.False) return LogicState.False;
        if (in1 === LogicState.True && in2 === LogicState.True) return LogicState.True;

        return LogicState.Unknown;
    }

    get serialisedType(): string {
        return 'and_gate'
    }
    static deserialiseWithoutChildren(serialised: SerialisedComponent): AndGateModel {
        return new AndGateModel()
    }
}