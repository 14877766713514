import {Action, ActionEvent, InputType} from "@projectstorm/react-diagrams";
import React from "react";
import {InputPinModel, OutputPinModel} from "./logic-components";


// Prevents input and output pins from being deleted.
export class ProtectedDeleteAction extends Action {
    constructor() {
        super({
            type: InputType.KEY_DOWN,
            fire: (event: ActionEvent<React.KeyboardEvent>) => {
                if (['Backspace', 'Delete'].includes(event.event.key)) {
                    this.engine.getModel().getSelectedEntities().forEach((m) => {
                        if (m instanceof InputPinModel) return;
                        if (m instanceof OutputPinModel) return;

                        m.remove();
                    });

                    this.engine.repaintCanvas();
                }
            }
        });
    }
}