// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-widget-list {
    position: absolute;
    padding: 10px;
    gap: 10px;

    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;

    z-index: 9999;
}

.error-widget {
    padding: 10px;
    border-radius: 5px;

    background-color: var(--error-container);
    color: var(--on-error-container);
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;

    width: 200px;

    transition-duration: 0.1s;

    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.error-widget:hover {
    filter: brightness(85%);
}

.hint-overlay {
    position: absolute;

    left: 10px;

    z-index: 9999;
}

.hint-overlay .button * {
    color: white;
    font-size: 24px;
}

`, "",{"version":3,"sources":["webpack://./src/css/overlay.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,SAAS;;IAET,QAAQ;;IAER,aAAa;IACb,sBAAsB;IACtB,2BAA2B;IAC3B,oBAAoB;;IAEpB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,kBAAkB;;IAElB,wCAAwC;IACxC,gCAAgC;IAChC,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;;IAEf,YAAY;;IAEZ,yBAAyB;;IAEzB,oEAAoE;AACxE;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;;IAElB,UAAU;;IAEV,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".error-widget-list {\r\n    position: absolute;\r\n    padding: 10px;\r\n    gap: 10px;\r\n\r\n    right: 0;\r\n\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: flex-start;\r\n    align-items: stretch;\r\n\r\n    z-index: 9999;\r\n}\r\n\r\n.error-widget {\r\n    padding: 10px;\r\n    border-radius: 5px;\r\n\r\n    background-color: var(--error-container);\r\n    color: var(--on-error-container);\r\n    user-select: none;\r\n    cursor: pointer;\r\n\r\n    width: 200px;\r\n\r\n    transition-duration: 0.1s;\r\n\r\n    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);\r\n}\r\n\r\n.error-widget:hover {\r\n    filter: brightness(85%);\r\n}\r\n\r\n.hint-overlay {\r\n    position: absolute;\r\n\r\n    left: 10px;\r\n\r\n    z-index: 9999;\r\n}\r\n\r\n.hint-overlay .button * {\r\n    color: white;\r\n    font-size: 24px;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
