// Represents a state of a Logic Component.
import {getCSSColour} from "../Style";

export enum LogicState {
    Unknown, // Not carrying any particular signal
    False, // Carrying a negative signal.
    True, // Carrying a positive signal.

    // When something goes wrong and the state of something cannot be determined.
    // Use unknown when there is insufficient information to determine the output (i.e. logic gate with only one input),
    // or wire with nothing connected to it.
    // Use error when it is impossible to determine the output (i.e. a not gate connected to itself).
    // The error state should always be propagated.
    Error
}


export function negate(state: LogicState): LogicState {
    if (state === LogicState.False) return LogicState.True;
    if (state === LogicState.True) return LogicState.False;

    return LogicState.Error;
}


export function getColour(state: LogicState): string {
    switch (state) {
        case LogicState.Unknown:
            return getCSSColour('unknown');
        case LogicState.Error:
            return getCSSColour('error-logic');
        case LogicState.True:
            return getCSSColour('true');
        case LogicState.False:
            return getCSSColour('false')
    }
}
