import {Any, Num, ValidationScheme} from "./validation";

export class Cell {
    stringContent: string
    private content: any
    validation: ValidationScheme
    valid: boolean

    constructor(content: any, validation: ValidationScheme = new Any()) {
        this.validation = validation;
        this.setContent(content);
    }

    static fromStr(content: string, validation: ValidationScheme = new Any()): Cell {
        const cell = new Cell(content, validation);

        cell.setContentString(content);

        return cell;
    }

    setContent(content: any) {
        if (content instanceof Cell) throw new Error();

        this.content = content;
        try {
            this.valid = this.validation.valid(content);
        } catch (e) {
            this.valid = false;
        }
        this.stringContent = this.toString();
    }

    setContentString(content: string) {
        if (this.validation.validString(content)) {
            this.setContent(this.validation.parseString(content))
        } else {
            this.setContent(content);
            this.valid = false;
        }
        this.stringContent = content;
    }

    getContent(): any {
        return this.content;
    }

    toString(): string {
        try {
            return this.validation.formatValue(this.content);
        } catch (ignored) {
            return `${this.content}`;
        }
    }

    // Clone method to create a deep copy of the cell
    clone(): Cell {
        return new Cell(this.content, this.validation); // Clone cell with the same content and validation
    }

    get isNumeric() {
        return this.validation instanceof Num
    }
}