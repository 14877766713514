import * as React from 'react';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import {OrGateModel} from "./OrGateModel";
import {LogicGateWidget} from "./LogicGateWidget";

export interface OrGateWidgetProps {
    node: OrGateModel;
    engine: DiagramEngine;
}

export interface OrGateWidgetState {}

export class OrGateWidget extends React.Component<OrGateWidgetProps, OrGateWidgetState> {
    constructor(props: OrGateWidgetProps) {
        super(props);
        this.state = {};
    }

    render() {
        return <LogicGateWidget node={this.props.node} engine={this.props.engine}/>;
    }
}