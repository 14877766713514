import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-diagrams';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import {AndGateModel} from "./AndGateModel";
import {AndGateWidget} from "./AndGateWidget";

export class AndGateNodeFactory extends AbstractReactFactory<AndGateModel, DiagramEngine> {
    constructor() {
        super('add-gate-node');
    }

    generateModel(initialConfig: any) {
        return new AndGateModel();
    }

    generateReactWidget(event: any): React.ReactElement {
        return <AndGateWidget engine={this.engine as DiagramEngine} node={event.model} />;
    }
}