import {MaterialColor} from "../basic-components";

export enum Status {
    NotStarted, Pending, Success, Failed, NoConnection
}

export function parseStatus(status: string): Status {
    switch (status.toLowerCase()) {
        case 'pending':
            return Status.Pending;
        case 'success':
            return Status.Success;
        case 'error':
            return Status.Failed;
    }
    return Status.NoConnection;
}


export function getStatusText(status: Status) {
    switch (status) {
        case Status.Failed:
            return 'Failed'
        case Status.NoConnection:
            return 'No Connection'
        case Status.Success:
            return 'Completed'
        case Status.Pending:
            return 'Pending'
        case Status.NotStarted:
            return 'Not Started'
    }
}


export function getStatusColor(status: Status) {
    switch (status) {
        case Status.Failed:
            return MaterialColor.Error
        case Status.NoConnection:
            return MaterialColor.Error
        case Status.Success:
            return MaterialColor.Secondary
        case Status.Pending:
            return MaterialColor.Tertiary
        case Status.NotStarted:
            return MaterialColor.Normal
    }
}

