import React from "react";
import {Button, Card, Checkbox, MaterialColor} from "../basic-components";
import {Height} from "../basic-components/Height";
import {Input} from "../basic-components/Input";


export interface AlignmentOptions {
    useHelper: boolean,
    gcContent: boolean,
    avoidSameBaseInARow: boolean,
    numRegions: number,
    numRegionsText: string,
}

export function defaultAlignmentOptions(): AlignmentOptions {
    return {
        useHelper: true,
        gcContent: true,
        avoidSameBaseInARow: true,
        numRegions: 3,
        numRegionsText: '3'
    }
}


interface AlignmentFormProps {
    canRun: boolean // Whether it's possible to start a new simulation.
    onChange?: (options: AlignmentOptions) => void
    onRun?: (options: AlignmentOptions) => void,
    options: AlignmentOptions
}

interface AlignmentFormState {
    valid: boolean
}


export class AlignmentForm extends React.Component<AlignmentFormProps, AlignmentFormState>{
    constructor(props) {
        super(props);

        this.state = {
            valid: true
        }
    }


    validateNumberOfRegions(input: string) {
        const value = parseInt(input);
        if (isNaN(value)) return false;
        if (value < 1) return false;
        if (value > 3) return false;

        return true;
    }

    handleHelperChange(v: boolean) {
        this.sendUpdate({
            ...this.props.options,
            useHelper: v
        });
    }

    handleGCChange(v: boolean) {
        this.sendUpdate({
            ...this.props.options,
            gcContent: v
        });
    }

    handleRepeatChange(v: boolean) {
        this.sendUpdate({
            ...this.props.options,
            avoidSameBaseInARow: v
        });
    }

    handleNumberOfRegionChange(v: string) {
        if (this.validateNumberOfRegions(v)) {
            this.sendUpdate({
                ...this.props.options,
                numRegions: parseInt(v),
                numRegionsText: v
            });
        } else {
            this.sendUpdate({
                ...this.props.options,
                numRegionsText: v
            });
        }
        this.setState({
            valid: this.validateNumberOfRegions(v)
        })
    }

    handleRun() {
        if (this.props.onRun) {
            this.props.onRun(this.props.options);
        }
    }

    sendUpdate(newOptions: AlignmentOptions) {
        if (this.props.onChange) {
            this.props.onChange(newOptions);
        }
    }

    render() {
        return <Card height={Height.Medium} color={MaterialColor.Normal} title={"Configurations"}
                     cardStyle={{ flexShrink: '0' }}>
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '5px'
            }}>
                <Checkbox label={"Use Helper Strands"} color={MaterialColor.Secondary}
                          selected={this.props.options.useHelper}
                          flip={true} initialValue={true} onChange={(v) => this.handleHelperChange(v)}/>
                <Checkbox label={"GC Content"} color={MaterialColor.Secondary}
                          selected={this.props.options.gcContent}
                          flip={true} initialValue={true} onChange={(v) => this.handleGCChange(v)}/>
                <Checkbox label={"Avoid Repeated Bases"} color={MaterialColor.Secondary}
                          selected={this.props.options.avoidSameBaseInARow}
                          flip={true} initialValue={true} onChange={(v) => this.handleRepeatChange(v)}/>
                <Input label={"Number of Targets"} initialValue={'3'} type={'number'}
                       placeholder={''} value={this.props.options.numRegionsText}
                       validate={(i) => this.validateNumberOfRegions(i)}
                       onChange={(v) => this.handleNumberOfRegionChange(v)}/>
                {
                    this.state.valid ? '' : <span style={{
                        color: 'var(--error)',
                        fontSize: '16px'
                    }}>
                        Number of target regions must be an integer between 1 and 3.
                    </span>
                }
                <Button content={"Find Target Regions"} color={MaterialColor.Secondary}
                        disabled={!(this.state.valid && this.props.canRun)}
                        onClick={() => this.handleRun()}/>
            </div>
        </Card>
    }
}