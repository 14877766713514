import React, {ChangeEvent} from "react";
import {SchemaEntry} from "../TableSchema";


interface HeaderCellWidgetProps {
    // The schema entry associated with its column.
    schemaEntry: SchemaEntry
    columnIndex: number
    onColumnRename?: (newName: string) => void
}

interface HeaderCellWidgetState {
    inputValue: string
}

export class HeaderCellWidget extends React.Component<HeaderCellWidgetProps, HeaderCellWidgetState> {
    constructor(props) {
        super(props);

        this.state = {
            inputValue: props.schemaEntry.fieldName
        }
    }

    get validityClass(): string {
        return this.props.schemaEntry.fieldName === '' ? 'invalid' : '';
    }

    onInputChange(event: ChangeEvent<HTMLInputElement>) {
        const newName = event.target.value;

        this.props.schemaEntry.fieldName = newName;
        this.props.onColumnRename(newName)
        this.setState({
            inputValue: newName
        });
    }

    renderFixedCell() {
        return <th className={`header static ${this.validityClass}`}>
            <div className={'cell-body'}>
                <span>
                    {this.state.inputValue}
                </span>
            </div>
        </th>
    }
    renderEditableCell() {
        return <th className={`header editable ${this.validityClass}`}>
            <div className={'cell-body'}>
                <div>
                    <input value={this.props.schemaEntry.fieldName}
                           onChange={(e) => this.onInputChange(e)}/>
                </div>
            </div>
        </th>
    }

    render() {
        if (this.props.schemaEntry.renamable) {
            return this.renderEditableCell();
        }
        return this.renderFixedCell();
    }
}
