import * as React from 'react';
import {DefaultLinkFactory, DiagramEngine} from '@projectstorm/react-diagrams';
import {LogicLinkModel} from "./LogicLinkModel";
import {LogicLinkWidget} from "./LogicLinkWidget";

export class LogicLinkFactory extends DefaultLinkFactory<LogicLinkModel> {
    constructor() {
        super('logic-link');
    }

    generateModel(initialConfig: any) {
        return new LogicLinkModel();
    }

    generateReactWidget(event: any): React.ReactElement {
        return <LogicLinkWidget engine={this.engine as DiagramEngine} model={event.model} />;
    }
}