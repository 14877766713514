import {BaseModelOptions, DeserializeEvent} from '@projectstorm/react-diagrams';
import {LogicPortModel} from "./LogicPortModel";
import {LogicComponent, SerialisedComponent} from "./LogicComponent";
import {LogicState} from "../LogicState";
import {LogicNodeModel} from "../LogicNodeModel";

export interface InputPinModelOptions extends BaseModelOptions {
    label?: string;
}

export class InputPinModel extends LogicNodeModel {
    label: string;
    logicState: LogicState;
    outputPort: LogicPortModel;
    next: LogicComponent[];

    constructor(options: InputPinModelOptions = {}) {
        super({
            ...options,
            type: 'input-pin-node'
        });
        this.label = options.label || this.uuid;

        this.outputPort = new LogicPortModel({
            in: false,
            name: 'out',
            isPin: true
        });
        // Port UUID matching. See nucleioapi/logic/README.md
        this.outputPort.uuid = this.uuid;
        this.outputPort.shouldSerialise = false;
        this.next = [this.outputPort];
        this.addPort(this.outputPort);

        this.logicState = LogicState.False;
    }

    serialize() {
        return {
            ...super.serialize(),
            key: this.label
        };
    }

    deserialize(event: DeserializeEvent<this>): void {
        super.deserialize(event);
        this.label = event.data.key;
    }

    updateAndPropagate(): void {
        this.outputPort.logicState = this.logicState;
        this.outputPort.updateAndPropagate();
    }

    get serialisedType(): string {
        return 'input_pin';
    }

    serialiseWithoutLinks(): SerialisedComponent {
        return Object.assign(super.serialiseWithoutLinks(), {
            label: this.label
        });
    }

    get inputPort(): LogicPortModel {
        return null
    }

    static deserialiseWithoutChildren(serialised: SerialisedComponent): InputPinModel {
        return new InputPinModel({
            label: serialised['label']
        })
    }
}