import * as React from 'react';
import {DiagramEngine} from '@projectstorm/react-diagrams';
import {OutputPinModel} from "./OutputPinModel";
import {LogicPortWidget} from "./LogicPortWidget";
import {LogicPortModel} from "./LogicPortModel";

export interface OutputPinWidgetProps {
    model: OutputPinModel;
    engine: DiagramEngine;
}

export interface OutputPinWidgetState {}

export class OutputPinWidget extends React.Component<OutputPinWidgetProps, OutputPinWidgetState> {
    constructor(props: OutputPinWidgetProps) {
        super(props);
        this.state = {};

        props.model.registerListener({
            eventDidFire: (event) => {
                if (event.function === 'logicUpdate') {
                    this.forceUpdate();
                }
            }
        });
    }


    render() {
        return (
            <div className={`output-pin ${this.selectedStyleClass}`}
                onMouseUp={() => this.props.model.setSelected(false)}>
                <div className={'body-layer'}>
                    <div className="output-pin-edge"/>
                    <div className="output-pin-body">
                        {this.props.model.label}
                    </div>
                </div>
                <div className={'port-layer'}>
                    <div className="input-ports">
                        <LogicPortWidget engine={this.props.engine}
                                         model={this.props.model.getPort('in') as LogicPortModel}/>
                    </div>
                    <div className={'filler'}/>
                </div>
            </div>
        );
    }


    get selectedStyleClass(): string {
        if (this.props.model.isSelected()) return 'output-pin-selected';
        return '';
    }
}