import * as React from 'react';
import { AbstractReactFactory } from '@projectstorm/react-diagrams';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import {InputPinModel} from "./InputPinModel";
import {InputPinWidget} from "./InputPinWidget";

export class InputPinFactory extends AbstractReactFactory<InputPinModel, DiagramEngine> {
    constructor() {
        super('input-pin-node');
    }

    generateModel(initialConfig: any) {
        return new InputPinModel();
    }

    generateReactWidget(event: any): React.ReactElement {
        return <InputPinWidget engine={this.engine as DiagramEngine} model={event.model} />;
    }
}