// A logic component is *any* part in a logic circuit that can carry a
import {LogicState} from "../LogicState";
import {LogicPortModel} from "./LogicPortModel";
import {LinkModel} from "@projectstorm/react-diagrams";


// Serialised representation of a logic component.
export interface SerialisedComponent {
    id: string,
    type: string,
    // ID of the component's children.
    children?: string[]
}

/**
 * Basic unit of the logic system. Each logic component keeps track of its state, the next one in line, and will
 * propagate signals through the system.
 *
 * @member logicState The current state of the component.
 * @member next The component that will be updated after the current component.
 */
export interface LogicComponent {
    logicState: LogicState;
    next: LogicComponent[];
    // ID for differentiating nodes.
    uuid: string;

    /**
     * Updates the current component based on the state of its parts or previous components, and sends an update to
     * the next component.
     */
    updateAndPropagate(): void

    serialiseWithoutLinks(): SerialisedComponent

    get inputPort(): LogicPortModel
    get outputPort(): LogicPortModel
    // Whether to serialise this component.
    shouldSerialise: boolean;
    get children(): LogicComponent[]
    setChildren(children: LogicComponent[]): void
}

/**
 * Looks for cycles, starting from the current component.
 *
 * @param component
 * @returns Whether this component is involved in a cycle.
 */
export function detectCycle(component: LogicComponent): boolean {
    let toVisit = [component];
    // Using a property on the component interface would be more efficient, but that would require clearing it after
    // the search, and implementing it on all specific components.
    let visitedSelf = false;
    let visited: Set<LogicComponent> = new Set<LogicComponent>();

    while (toVisit.length > 0) {
        let current = toVisit.pop();

        if (current === component) {
            if (visitedSelf) return true;
            visitedSelf = true;
        }

        if (visited.has(current)) continue;

        for (let child of current.next) {
            toVisit.push(child);
        }
        visited.add(current)
    }

    return false;
}


export function linkComponents(source: LogicComponent, target: LogicComponent): LinkModel {
    return source.outputPort.link(target.inputPort);
}
