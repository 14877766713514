import {BaseModelOptions, DeserializeEvent} from '@projectstorm/react-diagrams';
import {LogicPortModel} from "./LogicPortModel";
import {LogicComponent, SerialisedComponent} from "./LogicComponent";
import {LogicState} from "../LogicState";
import {LogicNodeModel} from "../LogicNodeModel";

export interface OutputPinModelOptions extends BaseModelOptions {
    label?: string;
}

export class OutputPinModel extends LogicNodeModel {
    label: string;
    logicState: LogicState;
    inputPort: LogicPortModel;
    next: LogicComponent[] = [];

    constructor(options: OutputPinModelOptions = {}) {
        super({
            ...options,
            type: 'output-pin-node'
        });
        this.label = options.label || this.uuid;

        this.inputPort = new LogicPortModel({
            in: true,
            name: 'in',
            isPin: true
        });
        // Port UUID matching. See nucleioapi/logic/README.md
        this.inputPort.uuid = this.uuid;
        this.inputPort.shouldSerialise = false;
        this.addPort(this.inputPort);
        this.inputPort.next = [this];

        this.logicState = LogicState.False;
    }

    serialize() {
        return {
            ...super.serialize(),
            label: this.label
        };
    }

    determineLogicState(): LogicState {
        return this.inputPort.logicState;
    }

    deserialize(event: DeserializeEvent<this>): void {
        super.deserialize(event);
        this.label = event.data.label;
    }

    get serialisedType(): string {
        return "output_pin";
    }
    serialiseWithoutLinks(): SerialisedComponent {
        return Object.assign(super.serialiseWithoutLinks(), {
            label: this.label
        });
    }
    get outputPort(): LogicPortModel {
        return null;
    }
    static deserialiseWithoutChildren(serialised: SerialisedComponent): OutputPinModel {
        return new OutputPinModel({
            label: serialised['label']
        });
    }
}