import React from "react";
import Dropzone, {Accept} from "react-dropzone";
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';


interface FileDropzoneProps {
    currentFile?: File
    onFileDropped: (file: File) => void
    acceptedFileTypes: string[]
    acceptedFileTypeSpecs?: Accept
    hintText: string
}

export class FileDropzone extends React.Component<FileDropzoneProps> {
    handleFileDrop(files: File[]) {
        const acceptedFile = files[0];

        this.props.onFileDropped(acceptedFile);
    }

    render() {
        return <Dropzone onDropAccepted={(files) => this.handleFileDrop(files)}
                        multiple={false} accept={this.props.acceptedFileTypeSpecs}>
            {({getRootProps, getInputProps}) => (
                <div {...getRootProps()} className={'dropzone-container'}>
                    <input {...getInputProps()} />
                    <div className={'dropzone-body'}>
                        <DriveFileMoveIcon className={'mui-icon'}/>
                        <p>Drag and drop a file here or click to select files.</p>
                        <p className={'accepted-file-types'}>
                            Accepted File Types: {this.props.acceptedFileTypes.join(', ')}
                        </p>
                    </div>
                </div>
            )}
        </Dropzone>
    }
}
