import {ValidationScheme} from "./ValidationScheme";


export class Str extends ValidationScheme {
    alphaNumeric: boolean;
    oneOf: string[];

    constructor({
        alphaNumeric = false,
        oneOf = []
    } : {
        alphaNumeric?: boolean;
        oneOf?: string[];
    }) {
        super();

        this.alphaNumeric = alphaNumeric;
        this.oneOf = oneOf;
    }

    isAlphaNumeric(str: string): boolean {
        for (let i = 0; i < str.length; i++) {
            let code = str.charCodeAt(i);
            if (!(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)) { // lower alpha (a-z)
                return false;
            }
        }
        return true;
    }

    valid(entry: any): boolean {
        if ((typeof entry) !== 'string') return false;
        if (this.alphaNumeric && !this.isAlphaNumeric(entry)) return false;
        if (this.oneOf.length > 0 && !this.oneOf.includes(entry)) return false;

        return true;
    }

    validString(entry: string): boolean {
        return true;
    }
}


export class DNA extends ValidationScheme {
    valid(entry: any): boolean {
        return this.validString(`${entry}`);
    }

    validString(entry: string): boolean {
        for (let i = 0; i < entry.length; i++) {
            if ('GACT'.indexOf(entry[i]) < 0) {
                return false;
            }
        }
        return true;
    }
}


// Allows DNA, RNA, with 'N's or 'U's mixed in between.
export class EasyDNA extends ValidationScheme {
    valid(entry: any): boolean {
        return this.validString(`${entry}`);
    }

    validString(entry: string): boolean {
        for (let i = 0; i < entry.length; i++) {
            if ('GACUTN'.indexOf(entry[i]) < 0) {
                return false;
            }
        }
        return true;
    }
}

// Allows DNA with no 'U's mixed in between.
export class EasyDNA2 extends ValidationScheme {
    valid(entry: any): boolean {
        return this.validString(`${entry}`);
    }

    validString(entry: string): boolean {
        for (let i = 0; i < entry.length; i++) {
            if ('GACTN'.indexOf(entry[i]) < 0) {
                return false;
            }
        }
        return true;
    }
}


// List of RNA strands separated by semicolons.
export class StrandsList extends ValidationScheme {
    valid(entry: any): boolean {
        return this.validString(`${entry}`);
    }

    validString(entry: string): boolean {
        const RNAValidation = new EasyDNA2();
        return entry.split(';').map((e) => e.trim())
            .every((strand) => RNAValidation.valid(strand));
    }
}
