// Type of data validation for the table.
export abstract class ValidationScheme {
    abstract valid(entry: any): boolean
    abstract validString(entry: string): boolean

    parseString(entry: string): any {
        return entry;
    }

    formatValue(value: any): string {
        return `${value}`
    }

    getDefaultValue(): any {
        return ''
    }
}

export class Any extends ValidationScheme {
    valid(entry: any): boolean {
        return true;
    }
    validString(entry: string): boolean {
        return true;
    }
}


export class OneOf extends ValidationScheme {
    conditions: ValidationScheme[]

    constructor(conditions: ValidationScheme[]) {
        super();

        this.conditions = conditions;
    }

    valid(entry: any): boolean {
        for (const cond of this.conditions) {
            if (cond.valid(entry)) {
                return true;
            }
        }
        return false;
    }

    validString(entry: string): boolean {
        for (const cond of this.conditions) {
            if (cond.valid(entry)) {
                return true;
            }
        }
        return false;
    }

    parseString(entry: string): any {
        return this.conditions[0].parseString(entry);
    }
}


export class AllOf extends ValidationScheme {
    conditions: ValidationScheme[]

    constructor(conditions: ValidationScheme[]) {
        super();

        this.conditions = conditions;
    }

    valid(entry: any): boolean {
        for (const cond of this.conditions) {
            if (!cond.valid(entry)) {
                return false;
            }
        }
        return true;
    }

    validString(entry: string): boolean {
        for (const cond of this.conditions) {
            if (!cond.valid(entry)) {
                return false;
            }
        }
        return true;
    }

    parseString(entry: string): any {
        return this.conditions[0].parseString(entry);
    }
}
