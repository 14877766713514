import {ValidationScheme} from "./ValidationScheme";


export class Num extends ValidationScheme {
    defaultValue = 0

    integer: boolean;
    greaterOrEqual: number;
    lessOrEqual: number;
    greaterThan: number;
    lessThan: number;
    precision: number;

    constructor({
        integer = false,
        greaterOrEqual = -Infinity,
        lessOrEqual = Infinity,
        greaterThan = -Infinity,
        lessThan = Infinity,
        precision = 3
    }:{
        integer?: boolean,
        greaterOrEqual?: number,
        lessOrEqual?: number,
        greaterThan?: number,
        lessThan?: number,
        precision?: number
    }) {
        super();

        this.integer = integer;
        this.greaterOrEqual = greaterOrEqual;
        this.lessOrEqual = lessOrEqual;
        this.greaterThan = greaterThan;
        this.lessThan = lessThan;
        this.precision = precision
    }

    valid(entry: any): boolean {
        if ((typeof entry) !== 'number') return false;
        if (this.integer && !Number.isInteger(entry)) return false;

        if (entry < this.greaterOrEqual) return false;
        if (entry <= this.greaterThan) return false;
        if (entry > this.lessOrEqual) return false;
        if (entry >= this.lessThan) return false;

        return true;
    }

    validString(entry: string): boolean {
        const num = parseFloat(entry);

        if (isNaN(num)) return false;

        return this.valid(num);
    }

    parseString(entry: string): any {
        return parseFloat(entry);
    }

    formatValue(value: any): string {
        if ((typeof value) !== 'number' || this.precision === Infinity) return super.formatValue(value);

        let multiplier = Math.pow(10, this.precision);

        return `${Math.round(value * multiplier) / multiplier}`
    }

    getDefaultValue(): any {
        if (this.hasLowerBound) {
            return this.lowerBound
        }
        return 0
    }

    get upperBound(): number {
        return Math.min(this.lessOrEqual, this.lessThan);
    }
    get lowerBound(): number {
        return Math.max(this.greaterThan, this.greaterOrEqual);
    }
    get hasUpperBound(): boolean {
        return this.upperBound < Infinity
    }
    get hasLowerBound(): boolean {
        return this.lowerBound > -Infinity
    }
    get hasBounds(): boolean {
        return this.hasUpperBound && this.hasLowerBound;
    }
}