import {ValidationScheme} from "./ValidationScheme";

export class Bool extends ValidationScheme {
    defaultValue = false;

    valid(entry: any): boolean {
        return (typeof entry) === 'boolean'
    }

    validString(entry: string): boolean {
        // Numbers can all be parsed.
        const number = parseFloat(entry);
        if (!isNaN(number)) return true;

        if (['true', 'false', 'yes', 'no', 'y', 'n', 't', 'f'].includes(entry.toLowerCase())) {
            return true;
        }

        return false;
    }

    parseString(entry: string): any {
        const number = parseFloat(entry);
        if (!isNaN(number)) return number !== 0;

        if (['true', 'yes', 'y', 't'].includes(entry.toLowerCase())) {
            return true;
        }
        return false;
    }
}