import {TableCard, ExtendedTableWidgetProps} from "./TableCard";
import React from "react";
import {Table} from "../Table";
import {Card, MaterialColor} from "../../basic-components";
import {Height} from "../../basic-components/Height";


interface TableEditorProps extends ExtendedTableWidgetProps {
    // Describes the desired table format.
    infoPanelTitle: string
    infoPanelDesc: string
    // Checks the current table and finds a list of format violations.
    checkTable: (table: Table) => string[]
    onViolationsUpdate?: (violations: string[]) => void
}

interface TableEditorState {
    tableFormatViolations: string[]
}


export class TableEditor extends React.Component<TableEditorProps, TableEditorState> {
    constructor(props) {
        super(props);

        this.state = {
            tableFormatViolations: []
        }
    }


    /* This likes to cause an infinite recursion.
    static getDerivedStateFromProps(props: TableEditorProps, state: TableEditorState) {
        let violations = [];
        if (props.table) {
            violations = props.checkTable(props.table);
        } else {
            violations = ['Missing table. '];
        }

        if (props.onViolationsUpdate) {
            //props.onViolationsUpdate(violations);
        }

        return {
            tableFormatViolations: violations
        };
    }*/

    get hasViolations() {
        return this.state.tableFormatViolations.length > 0;
    }
    get hasFormattingRequirements() {
        return !!this.props.formatRequirements
    }

    updateViolations(table?: Table) {
        const violations = this.calculateViolations(table);

        if (this.props.onViolationsUpdate) {
            this.props.onViolationsUpdate(violations);
        }

        this.setState({
            tableFormatViolations: violations
        });
    }

    calculateViolations(table?: Table): string[] {
        if (table) {
            return this.props.checkTable(table);
        }
        return ['Missing table. ']
    }

    handleTableUpdate(table: Table) {
        if (this.props.onAnythingChanged) {
            this.props.onAnythingChanged(table);
        }
        this.updateViolations(table);
    }

    handleTableCompletelyChanged(table?: Table) {
        if (this.props.onTableCompletelyChanged) {
            this.props.onTableCompletelyChanged(table);
        }
        this.updateViolations(table);
    }

    renderErrorCard() {
        return <Card height={Height.Medium} color={MaterialColor.Error} title={'Invalid Table'}>
            <ul style={{marginLeft: '20px'}}>
                {this.state.tableFormatViolations.map((violation) => <li>{violation}</li>)}
            </ul>
        </Card>
    }

    renderInfobar() {
        return <div className={'infobar'}>
            <Card color={MaterialColor.Normal} height={Height.Medium} title={this.props.infoPanelTitle}>
                {this.props.infoPanelDesc}
            </Card>
            {this.hasFormattingRequirements ? <Card color={MaterialColor.Normal} height={Height.Medium}
                                                    title={'Table Format'}>
                {this.props.formatRequirements}
            </Card> : ''}
            {this.hasViolations ? this.renderErrorCard() : ''}
        </div>
    }

    render() {
        return <div className={'table-editor'}>
            <TableCard {...this.props}
                       onAnythingChanged={(table) => this.handleTableUpdate(table)}
                       onTableCompletelyChanged={(table) => this.handleTableCompletelyChanged(table)}/>
            {this.renderInfobar()}
        </div>
    }
}

