import React, {CSSProperties} from "react";
import {MaterialColor, getColorString} from "./MaterialColor";
import {getShadowClass, Height} from "./Height";


interface ButtonProps {
    content: string,
    color: MaterialColor,
    height?: Height,
    onClick?: (event: React.MouseEvent) => void,
    disabled?: boolean,
    fontSize?: string,
    style?: CSSProperties,
    href?: string
}


export class Button extends React.Component<ButtonProps, any> {
    handleClick(event: React.MouseEvent) {
        if (this.props.onClick && !this.props.disabled) {
            this.props.onClick(event);
        }
    }

    get disabledClass() {
        return this.props.disabled ? 'disabled' : '';
    }

    get colourClass() {
        return getColorString(this.props.color);
    }

    get fontSize() {
        if (this.props.fontSize) {
            return this.props.fontSize;
        }
        return '24px'
    }

    get heightClass() {
        if (!this.props.height) return '';
        return getShadowClass(this.props.height);
    }

    render() {
        return <a className={`material-button ${this.colourClass} ${this.disabledClass} ${this.heightClass}`}
                    style={{...this.props.style, fontSize: this.fontSize}} href={this.props.href}
                    onClick={(event => this.handleClick(event))}>
            {this.props.content}
        </a>
    }
}
