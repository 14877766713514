import {SimulationOptions} from "./Simulation";
import React from "react";
import {Button, Card, Checkbox, MaterialColor} from "../basic-components";
import {Height} from "../basic-components/Height";
import {Input} from "../basic-components/Input";


interface SimulationFormProps {
    canRun: boolean // Whether it's possible to start a new simulation.
    onChange?: (options: SimulationOptions) => void
    onRun?: (options: SimulationOptions) => void,
    options: SimulationOptions
}

interface SimulationFormState {
    valid: boolean
}


export class SimulationForm extends React.Component<SimulationFormProps, SimulationFormState>{
    constructor(props) {
        super(props);

        this.state = {
            valid: true
        }
    }


    validateTime(input: string) {
        const value = parseInt(input);
        if (isNaN(value)) return false;
        if (value < 1) return false;
        if (value > 65536) return false;

        return true;
    }

    handleLeaksChange(v: boolean) {
        this.sendUpdate({
            ...this.props.options,
            leaks: v
        });
    }

    handleTimeChange(v: string) {
        if (this.validateTime(v)) {
            this.sendUpdate({
                ...this.props.options,
                time: parseInt(v)
            });
        }
        this.setState({
            valid: this.validateTime(v)
        })
    }

    handleRun() {
        if (this.props.onRun) {
            this.props.onRun(this.props.options);
        }
    }

    sendUpdate(newOptions: SimulationOptions) {
        if (this.props.onChange) {
            this.props.onChange(newOptions);
        }
    }

    render() {
        return <Card height={Height.Medium} color={MaterialColor.Normal} title={"Configurations"}
                     cardStyle={{ flexShrink: '0' }}>
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '5px'
            }}>
                <Checkbox label={"Leaks"} color={MaterialColor.Secondary}
                          selected={this.props.options.leaks}
                          flip={true} initialValue={true} onChange={(v) => this.handleLeaksChange(v)}/>
                <Input label={"Time (s)"} initialValue={'28800'} type={'number'}
                       placeholder={'Seconds'} value={this.props.options.time.toString()}
                       validate={(i) => this.validateTime(i)}
                       onChange={(v) => this.handleTimeChange(v)}/>
                {
                    this.state.valid ? '' : <span style={{
                        color: 'var(--error)',
                        fontSize: '16px'
                    }}>
                        Time must be an integer between 1 and 65536.
                    </span>
                }
                <Button content={"Run"} color={MaterialColor.Secondary}
                        disabled={!(this.state.valid && this.props.canRun)}
                        onClick={() => this.handleRun()}/>
            </div>
        </Card>
    }
}