// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simulation-view {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: row;
    gap: 20px;
}

.simulation-view .inputs {
    width: 220px;

    flex-grow: 0;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.simulation-view .data-view-container {
    flex-grow: 1;
}

.simulation-view .data-view-container .material-card {
    width: 100%;
    height: 100%;
}

.initials-svg-holder svg text {
    fill: var(--on-background) !important;
}
`, "",{"version":3,"sources":["webpack://./src/css/simulation.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,YAAY;;IAEZ,YAAY;IACZ,cAAc;;IAEd,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,qCAAqC;AACzC","sourcesContent":[".simulation-view {\r\n    width: 100%;\r\n    height: 100%;\r\n\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 20px;\r\n}\r\n\r\n.simulation-view .inputs {\r\n    width: 220px;\r\n\r\n    flex-grow: 0;\r\n    flex-shrink: 0;\r\n\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 10px;\r\n}\r\n\r\n.simulation-view .data-view-container {\r\n    flex-grow: 1;\r\n}\r\n\r\n.simulation-view .data-view-container .material-card {\r\n    width: 100%;\r\n    height: 100%;\r\n}\r\n\r\n.initials-svg-holder svg text {\r\n    fill: var(--on-background) !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
