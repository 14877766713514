import {BaseModelOptions, DeserializeEvent} from '@projectstorm/react-diagrams';
import {LogicPortModel} from "../logic-components";
import {LogicState} from "../LogicState";
import {LogicComponent, SerialisedComponent} from "../logic-components";
import {LogicNodeModel} from "../LogicNodeModel";

export interface NotGateModelOptions extends BaseModelOptions {}

export class NotGateModel extends LogicNodeModel {
    in: LogicPortModel;
    out: LogicPortModel;

    logicState: LogicState;
    next: LogicComponent[] = [];

    constructor(options: NotGateModelOptions = {}) {
        super({
            ...options,
            type: 'not-gate-node'
        });

        this.in = new LogicPortModel({
            in: true,
            name: 'in1'
        });
        this.in.next = [this];
        this.addPort(this.in);

        this.out = new LogicPortModel({
            in: false,
            name: 'out'
        });
        this.next = [this.out]
        this.addPort(this.out);
        this.out.uuid = this.uuid;
    }

    updateAndPropagate(): void {
        this.logicState = this.determineLogicState();

        this.out.logicState = this.logicState;
        this.out.updateAndPropagate();
    }

    serialize() {
        return {
            ...super.serialize()
        };
    }

    determineLogicGateState(in1: LogicState): LogicState {
        if (in1 === LogicState.Error) return LogicState.Error;
        if (in1 === LogicState.True) return LogicState.False;
        if (in1 === LogicState.False) return LogicState.True;
        return LogicState.Unknown;
    }

    determineLogicState(): LogicState {
        return this.determineLogicGateState(this.in.logicState);
    }

    deserialize(event: DeserializeEvent<this>): void {
        super.deserialize(event);
    }

    get inputPort(): LogicPortModel {
        return this.in;
    }

    get outputPort(): LogicPortModel {
        return this.out;
    }

    get serialisedType(): string {
        return "not_gate";
    }

    get children(): LogicComponent[] {
        return [this.in];
    }

    setChildren(children: LogicComponent[]) {
        if (this.in) {
            this.removePort(this.in);
        }

        this.in = children[0] as LogicPortModel;
        this.in.next = [this];
        this.addPort(this.in);
    }

    static deserialiseWithoutChildren(serialised: SerialisedComponent) {
        return new NotGateModel();
    }
}