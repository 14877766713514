import './Style'
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from "./App";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

window.onbeforeunload = () => {
    console.log(window.location.href)
    if (window.location.href.includes('dpga') || window.location.href.includes('wta')) {
        return "You may have unsaved work in this page. Are you sure you want to leave?"
    }
}
