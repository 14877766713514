import {Num, EasyDNA, SchemaEntry, Str, StrandsList, Table, TableSchema} from "../data";
import {Simulation} from "./Simulation";
import {parseStatus, Status} from "./Status";
import {createProjectEngine, LogicDiagramEngine, SerialisedCircuit} from "../logic-builder";

export namespace Nucleio {
    const host = `${window.location.protocol}//${window.location.hostname}:8000`;

    const wtaCodeEndpoint = `${host}/wta2dsd`
    const dpgaCodeEndpoint = `${host}/dpga2dsd`
    const dsdEndpoint = `${host}/dsd`
    const pingEndpoint = `${host}/ping`
    const findTargetRegionsEndpoint = `${host}/find_target_regions`
    const wtaToStrandsEndpoint = `${host}/wta2strands`
    const circuitToStrandsEndpoint = `${host}/circuit2strands`
    const dpgaifyEndpoint = `${host}/dpgaify`
    const fastaEndpoint = `${host}/fasta`

    const jobEndpoint = `${host}/jobs`

    const jsonHeaders = {
        "Content-Type": "application/json",
    };


    interface JobDump<O> {
        id: string,
        status: Status,
        input: any,
        output?: O,
        error?: any
    }

    interface DSDOutput {
        crn_code: string
        initials_svg: string
        concentrations: any
    }

    interface ADCOutput {
        strands: any
    }

    export interface StrandsOutput {
        strands: Table,
        instructions: Table
    }

    export async function ping() {
        try {
            const response = await fetch(pingEndpoint);
            if (response.ok) {
                return true;
            }
            return false;
        }catch (e) {
            return false;
        }
    }

    export async function getJob(id: string) {
        return await fetch(`${jobEndpoint}/${id}`)
    }

    export async function pollJob<O>(id: string) {
        let response: {status: any} = {
            'status': 'pending'
        };

        while (response['status'] === 'pending') {
            response = await (await getJob(id)).json();
            // Sleep for a second.
            await new Promise(r => setTimeout(r, 1000));
        }

        response['status'] = parseStatus(response.status);

        return response as JobDump<O>;
    }

    async function post(endpoint: string, body: any) {
        return await fetch(endpoint, {
            method: 'POST',
            headers: jsonHeaders,
            body: JSON.stringify(body)
        });
    }

    // inputs: dictionary mapping each input to a boolean value.
    // memories: WTA memories.
    export async function getWTASimulationCode(
        inputs: boolean[],
        memories: Table,
        leak: boolean,
        time: number
    ): Promise<string> {
        const response = await post(wtaCodeEndpoint, {
            'inputs': inputs,
            'memories': memories.serialise(),
            'leaks': leak,
            'time': time
        });

        if (response.ok) {
            return (await response.json())['code'];
        }

        throw new Error('Something went wrong. ')
    }

    export async function getDPGASimulationCode(
        inputs: boolean[],
        circuit: LogicDiagramEngine,
        leak: boolean,
        time: number
    ): Promise<string> {
        const inputDictionary = {};
        const inputLabels = circuit.getInputPins().map((pin) => pin.label);

        for (let i = 0; i < inputs.length; i++) {
            inputDictionary[inputLabels[i]] = inputs[i];
        }

        const response = await post(dpgaCodeEndpoint, {
            'inputs': inputDictionary,
            'circuit': circuit.serialiseForTransmission(),
            'leaks': leak,
            'time': time
        });

        if (response.ok) {
            return (await response.json())['code'];
        }

        throw new Error('Something went wrong. ')
    }

    export async function scheduleDSD(code: string): Promise<string> {
        const response = await post(dsdEndpoint, {
            'code': code,
            "simulator": "deterministic"
        });

        if (response.ok) {
            return (await response.json())['id'];
        }

        throw new Error('Something went wrong. ')
    }

    export async function runDSD(code: string): Promise<Simulation> {
        const id = await scheduleDSD(code);
        const jobResult = await pollJob<DSDOutput>(id);

        if (jobResult.status === Status.Success) {
            const concentrations = Table.deserialise(jobResult.output.concentrations);
            const schema = createConcentrationTableSchema(concentrations);

            concentrations.setSchema(schema);

            return {
                status: jobResult.status,
                dsdCode: code,
                concentrations: concentrations,
                svgContent: jobResult.output.initials_svg
            }
        }

        return {
            status: jobResult.status,
            dsdCode: code
        }
    }

    // The table given from the DSD simulation won't have a specified schema.
    // Create one where the table will be coloured based on the concentrations.
    function createConcentrationTableSchema(table: Table): TableSchema {
        // First extract the maximum and minimum.
        // Skip the first column since it's the time series.
        const maxConcentration: number = table.getRows()
            .map((row) => row.cells.map(
                (cell, index) => index > 0 ? cell.getContent() : 0)
                .reduce((prev, curr) => Math.max(prev, curr), 0))
            .reduce((prev, curr) => Math.max(prev, curr), 0);

        const minNonZeroConcentration: number = table.getRows()
            .map((row) => row.cells.map(
                (cell, index) => index > 0 ? cell.getContent() : 0)
                .filter((content) => content > 0) // Only consider contents strictly greater than 0.
                .reduce((prev, curr) => Math.min(prev, curr), Infinity))
            .reduce((prev, curr) => Math.min(prev, curr), Infinity);

        const decimalPoints = Math.max(0, Math.ceil(-Math.log10(minNonZeroConcentration)));

        const concentrationValidation = new Num({
            greaterOrEqual: 0, // Minimum possible concentration is 0 usually.
            lessOrEqual: maxConcentration,
            precision: decimalPoints
        })

        const schemaEntries = table.schema.entries.map((entry, index) => {
            return new SchemaEntry(entry.fieldName,
                index === 0 ? new Num({}) : concentrationValidation,
                false, false
            )
        });

        return new TableSchema(schemaEntries);
    }

    export async function runWTASimulation(
        inputs: boolean[],
        memories: Table,
        leak: boolean,
        time: number
    ) : Promise<Simulation> {
        if (!(await ping())) return { status: Status.NoConnection }

        try {
            const simulationCode = await getWTASimulationCode(inputs, memories, leak, time);
            const simulationResult = await runDSD(simulationCode);

            // If the simulation succeeds, we can try to recover the output names.
            if (simulationResult.status !== Status.Success) return simulationResult;

            // Try to recover the header here. We simply give up fitting the header if this strategy doesn't work.
            try {
                memories.column(0).cells.map((x) => x.toString())
                    .forEach((x, index) => {
                        simulationResult.concentrations.schema.entries[index + 1].fieldName = x;
                    });
            } catch (e) {}

            return simulationResult;
        } catch (e) {
            return {
                status: Status.Failed
            }
        }
    }


    export async function runDPGASimulation(
        inputs: boolean[],
        circuit: LogicDiagramEngine,
        leak: boolean,
        time: number
    ): Promise<Simulation> {
        if (!(await ping())) return { status: Status.NoConnection }

        try {
            const simulationCode = await getDPGASimulationCode(inputs, circuit, leak, time);
            const simulationResult = await runDSD(simulationCode)

            if (simulationResult.status !== Status.Success) return simulationResult;

            // Try to recover the header here. We simply give up fitting the header if this strategy doesn't work.
            try {
                circuit.getOutputPins().map((x) => x.label)
                    .forEach((x, index) => {
                        simulationResult.concentrations.schema.entries[2 * index + 1].fieldName = x + '_h';
                        simulationResult.concentrations.schema.entries[2 * index + 2].fieldName = x + '_l';
                    });
            } catch (e) {}

            return {
                ...simulationResult,
                circuit: circuit
            };
        } catch (e) {
            return {
                status: Status.Failed
            }
        }
    }


    // As a function because multiple copies of this will need to be created.
    export function getTargetRegionsSchema() {
        return new TableSchema([
            new SchemaEntry('Strand Name', new Str({}), false, true),
            new SchemaEntry('Sequence', new EasyDNA(), false, true),
            new SchemaEntry('Target Regions', new StrandsList(), false, true),
            new SchemaEntry('Helper Strands', new StrandsList(), false, true)
        ]);
    }

    export async function fillTargetRegions(
        strands: Table,
        useHelper: boolean,
        gcContent: boolean,
        avoidSameBaseInARow: boolean,
        numRegions: number
    ): Promise<Table> {
        const response = await post(findTargetRegionsEndpoint, {
            strands: strands.serialise(),
            use_helper_strands: useHelper,
            gc_content: gcContent,
            avoid_same_base_in_a_row: avoidSameBaseInARow,
            num_regions: numRegions
        });

        if (!response.ok) {
            throw new Error('Something went wrong. ');
        }

        const id = (await response.json())['id'];

        const jobResult = await pollJob<ADCOutput>(id);

        if (jobResult.status !== Status.Success) {
            throw new Error('Something went wrong. ');
        }

        const newTable = Table.deserialise(jobResult.output.strands);
        newTable.setSchema(getTargetRegionsSchema());

        return newTable;
    }

    export function getStrandsTableSchema() {
        return new TableSchema([
            new SchemaEntry('Name', new Str({}), false, false),
            new SchemaEntry('Sequence', new Str({}), false, false)
        ]);
    }

    export function getInstructionsTableSchema() {
        return new TableSchema([
            new SchemaEntry('ID', new Str({}), false, false),
            new SchemaEntry('Type', new Str({}), false, false),
            new SchemaEntry('Strands Required', new Str({}), false, false),
            new SchemaEntry('Concentration (nM)', new Str({}), false, false)
        ]);
    }

    async function extractStrandsTable(response: Response): Promise<StrandsOutput>{
        if (!response.ok) {
            throw new Error('Something went wrong. ');
        }

        const responseContent = await response.json();

        const strandsTable = Table.deserialise(responseContent.strands);
        const assemblyTable = Table.deserialise(responseContent.assembly);

        strandsTable.setSchema(getStrandsTableSchema());
        assemblyTable.setSchema(getInstructionsTableSchema());

        return {
            strands: strandsTable,
            instructions: assemblyTable
        }
    }

    export async function getWTAStrands(
        memories: Table,
        strands: Table,
        threshold: boolean
    ): Promise<StrandsOutput> {
        return await extractStrandsTable(await post(wtaToStrandsEndpoint, {
            memories: memories.serialise(),
            strands: strands.serialise(),
            threshold: threshold
        }));
    }

    export async function getCircuitStrands(
        circuits: LogicDiagramEngine[],
        strands: Table,
        threshold: boolean
    ) {
        return await extractStrandsTable(await post(circuitToStrandsEndpoint, {
            circuits: circuits.map((circ) => circ.serialiseForTransmission()),
            strands: strands.serialise(),
            threshold: threshold
        }));
    }

    export async function scheduleDPGAify(circuit: LogicDiagramEngine): Promise<string> {
        const response = await post(dpgaifyEndpoint, {
            'circuit': circuit.serialiseForTransmission()
        });

        if (response.ok) {
            return (await response.json())['id'];
        }

        throw new Error('Something went wrong. ')
    }

    interface DPGAifyOutput {
        circuits: SerialisedCircuit[]
    }

    export async function dpgaify(circuit: LogicDiagramEngine): Promise<LogicDiagramEngine[]> {
        const jobID = await scheduleDPGAify(circuit);
        const jobResult = await pollJob<DPGAifyOutput>(jobID);

        if (jobResult.status !== Status.Success) {
            throw new Error('Something went wrong. ');
        }

        return jobResult.output.circuits.map((x) => {
            const [engine, _] = createProjectEngine();
            engine.deserialiseCircuit(x);
            return engine
        });
    }

    export async function loadFasta(content: string): Promise<Table> {
        const serialised = await post(fastaEndpoint, {
            'content': content
        });

        return Table.deserialise(await serialised.json())
    }
}
