import React from "react";
import {Button, Card, Checkbox, MaterialColor} from "../basic-components";
import {Height} from "../basic-components/Height";


interface StrandGenerationFormProps {
    canGenerate: boolean
    generateStrands: (threshold: boolean) => void
    error: boolean
}


interface StrandGenerationFormState {
    threshold: boolean
}


export class StrandGenerationForm extends React.Component<StrandGenerationFormProps, StrandGenerationFormState> {
    constructor(props) {
        super(props);

        this.state = {
            threshold: false
        };
    }

    handleThresholdUpdate(v: boolean) {
        this.setState({
            threshold: v
        });
    }

    handleGenerate() {
        this.props.generateStrands(this.state.threshold);
    }

    render() {
        return <Card height={Height.Medium} color={MaterialColor.Normal} title={"Configurations"}>
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '5px'
            }}>
                <Checkbox label={"Thresholding"} color={MaterialColor.Secondary} selected={this.state.threshold}
                          onChange={(v) => this.handleThresholdUpdate(v)}/>
                {this.props.error ? <span style={{
                    color: 'var(--error)',
                    fontSize: '12px'
                }}>Something went wrong.</span> : ''}
                <Button content={'Generate'} color={MaterialColor.Secondary} disabled={!this.props.canGenerate}
                        onClick={() => this.handleGenerate()}/>
            </div>
        </Card>
    }
}
