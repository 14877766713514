import * as React from 'react';
import {NavigatorButton} from './NavigatorButton';
import {PipelineStage} from "./PipelineStage";


export interface NavigatorListWidgetProps {
    stages: PipelineStage[];
    selectedStage: number;
    onStageChange?: (number) => void;
}


export class Navigator extends React.Component<NavigatorListWidgetProps> {
    get stagesProps() {
        return this.props.stages.map((stage, index) => {
            return {
                stageDescription: stage.stageName,
                isSelected: this.props.selectedStage === index,
                isAccessible: stage.accessible
            }
        })
    }

    onStageClickedListener(target: number) {
        return () => {
            if (!this.stagesProps[target].isAccessible) return;
            if (target === this.props.selectedStage) return;

            this.stagesProps.forEach((button, index) => {
                button.isSelected = index === target;
            });

            if (this.props.onStageChange) {
                this.props.onStageChange(target);
            }

            this.setState({
                selectedStage: target
            });
        }
    }

    render() {
        return (
            <div className='navigator-list-container shadow-3'>
                <div className="navigator-list">
                    {this.stagesProps.map((buttonProps, index) => {
                        return (
                            <div className="navigator-list-element" onClick={this.onStageClickedListener(index)} key={index}>
                                <NavigatorButton
                                    stageDescription={buttonProps.stageDescription}
                                    isSelected={buttonProps.isSelected}
                                    isAccessible={buttonProps.isAccessible}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }
}
