import {AbstractPipelineState, AbstractPipelineWidget} from "../pipeline-component";
import {PipelineStage} from "../navigator";
import {Table} from "../data";
import {Nucleio, StrandAlignmentView} from "../nucleio";
import {WTAMemoriesEditor} from "./WTAMemoriesEditor";
import {WTASimulationInfo, WTASimulationManager} from "./WTASimulationManager";
import {StrandGenerationView} from "../nucleio/StrandGenerationView";


const WTAPipelineStages: PipelineStage[] = [
    {
        stageName: 'Create Memories',
        accessible: true
    },
    {
        stageName: 'Simulate',
        accessible: true
    },
    {
        stageName: 'Input Strands',
        accessible: false
    },
    {
        stageName: 'Generate Strands',
        accessible: false
    }
];

const memoryStage = 0;
const simulateStage = 1;
const inputStage = 2;
const strandsStage = 3;


interface WTAPipelineProps {

}

interface WTAPipelineState extends AbstractPipelineState {
    // Memory Stage
    memoriesTable?: Table,
    memoriesTableValid: boolean,
    // Simulation Stage
    simulationInfos: WTASimulationInfo[],
    simulationsCount: number,
    // Strands Upload
    strandsTable?: Table,
    strandsTableValid: boolean
    // Strand Generation
    outputStrands?: Nucleio.StrandsOutput
}


export class WTAPipelineWidget extends AbstractPipelineWidget<WTAPipelineProps, WTAPipelineState> {
    getInitialState(): WTAPipelineState {
        return {
            selectedStage: 0,
            pipelineStages: WTAPipelineStages,
            canProceed: true,
            memoriesTable: Table.dummyWTATable(),
            memoriesTableValid: true,
            strandsTable: Table.schemaOnly(Nucleio.getTargetRegionsSchema()),
            strandsTableValid: true,
            simulationInfos: [],
            simulationsCount: 0
        }
    }

    handleMemoryTableUpdate(table?: Table) {
        this.setState({
            memoriesTable: table
        });
    }
    handleMemoryValidityUpdate(validity: boolean) {
        this.setState({
            memoriesTableValid: validity
        }, () => this.updateCanProceed());
    }

    handleStrandsTableUpdate(table?: Table) {
        this.setState({
            strandsTable: table
        });
    }
    handleStrandsTableValidityUpdate(validity: boolean) {
        this.setState({
            strandsTableValid: validity
        }, () => this.updateCanProceed());
    }

    generateStrands(threshold: boolean) {
        return Nucleio.getWTAStrands(this.state.memoriesTable, this.state.strandsTable, threshold);
    }

    renderMemoriesStage() {
        return <WTAMemoriesEditor table={this.state.memoriesTable}
                                  onTableChange={(t) => this.handleMemoryTableUpdate(t)}
                                  onValidityUpdate={(v) => this.handleMemoryValidityUpdate(v)}
        />
    }
    renderSimulateStage() {
        return <WTASimulationManager wtaModel={this.state.memoriesTable}
                                     updateSimulations={(i) => this.setState({simulationInfos: i})}
                                     simulationInfos={this.state.simulationInfos}
                                     updateSimulationCount={(c) => this.setState({simulationsCount: c})}
                                     simulationsCount={this.state.simulationsCount}
        />
    }

    getInputStrandNames() {
        return this.state.memoriesTable.schema.entries.filter((_, index) => index > 0)
            .map((entry) => entry.fieldName);
    }

    renderInputStrandsStage() {
        return <StrandAlignmentView table={this.state.strandsTable}
                                    inputStrandNames={this.getInputStrandNames()}
                                    onAnyTableUpdate={(t) => this.handleStrandsTableUpdate(t)}
                                    onValidityUpdate={(v) => this.handleStrandsTableValidityUpdate(v)}
        />
    }
    renderStrandsGenerationStage() {
        return <StrandGenerationView generateStrands={(t) => this.generateStrands(t)}
                                     tables={this.state.outputStrands}
                                     setTables={(t) => this.setState({outputStrands: t})}/>
    }

    // Initialises or updates the strands table upon stage selection.
    getUpdatedStrandsTable() {
        if (this.state.strandsTable) {
            const inputStrandNames = this.getInputStrandNames();
            for (const name of inputStrandNames) {
                // Check if the corresponding row is already present.
                const [index, row] = this.state.strandsTable.findRow((row) => row.get(0) === name);
                if (index < 0) {
                    this.state.strandsTable.addRowData([name, '', '', '']);
                }
            }
            return this.state.strandsTable.selectRows(
                (row) => inputStrandNames.includes(row.get(0)));
        } else {
            const table = Table.schemaOnly(Nucleio.getTargetRegionsSchema());
            for (const name of this.getInputStrandNames()) {
                table.addRowData([name, '', '', '']);
            }
            return table;
        }
    }

    onStageChange(previous: number, current: number) {
        if (current === inputStage) {
            this.handleStrandsTableUpdate(this.getUpdatedStrandsTable());
        }
    }

    renderStage(stage: number) {
        switch (stage) {
            case memoryStage:
                return this.renderMemoriesStage();
            case simulateStage:
                return this.renderSimulateStage();
            case inputStage:
                return this.renderInputStrandsStage();
            case strandsStage:
                return this.renderStrandsGenerationStage();
        }
    }

    determineCanProceed(): boolean {
        switch (this.state.selectedStage) {
            case memoryStage:
                return this.state.memoriesTableValid;
            case inputStage:
                return this.state.strandsTableValid;
            case simulateStage:
                return true;
            case strandsStage:
                return true;
        }
    }
}
