import {DefaultNodeModelOptions, NodeModel} from "@projectstorm/react-diagrams";
import {LogicComponent, SerialisedComponent} from "./logic-components";
import {LogicState} from "./LogicState";
import {v4 as uuidv4} from 'uuid'
import {LogicPortModel} from "./logic-components";


// Mirror image of the logic component in the backend.
export abstract class LogicNodeModel extends NodeModel implements LogicComponent {
    logicState: LogicState = LogicState.Unknown;
    next: LogicComponent[];
    uuid: string;
    shouldSerialise: boolean

    constructor(options: DefaultNodeModelOptions) {
        super(options);
        this.next = []
        this.uuid = uuidv4();
        this.shouldSerialise = true;
    }

    abstract get inputPort(): LogicPortModel
    abstract get outputPort(): LogicPortModel

    abstract get serialisedType(): string;

    serialiseWithoutLinks(): SerialisedComponent {
        const children = this.children;
        if (children.length > 0) {
            return {
                id: this.uuid,
                type: this.serialisedType,
                children: children.map((child) => child.uuid)
            }
        }

        return {
            id: this.uuid,
            type: this.serialisedType
        };
    }

    determineLogicState(): LogicState {
        return this.logicState;
    }

    updateAndPropagate(): void {
        this.logicState = this.determineLogicState()

        this.fireEvent({}, 'logicUpdate');

        for (const next of this.next) {
            next.logicState = this.logicState;
            next.updateAndPropagate();
        }
    }

    get children(): LogicComponent[] {
        return [];
    }
    // The fact that this is an explicit setter method is to imply that swapping children requires some rewiring and
    // isn't as simple as just setting one property.
    setChildren(children: LogicComponent[]) {}
}