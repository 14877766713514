import * as React from 'react';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import {AndGateModel} from "./AndGateModel";
import {LogicGateWidget} from "./LogicGateWidget";

export interface ExampleGateWidgetProps {
    node: AndGateModel;
    engine: DiagramEngine;
}

export interface ExampleGateWidgetState {}

export class AndGateWidget extends React.Component<ExampleGateWidgetProps, ExampleGateWidgetState> {
    constructor(props: ExampleGateWidgetProps) {
        super(props);
        this.state = {};
    }

    render() {
        return <LogicGateWidget node={this.props.node} engine={this.props.engine}/>;
    }
}