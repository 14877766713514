import './css/index.css'
import './css/logic.css'
import './css/input-pin.css'
import './css/port.css'
import './css/overlay.css'
import './css/output-pin.css'
import './css/gate.css'
import './css/table.css'
import './css/button.css'
import './css/navigator-list.css'
import './css/dropzone.css'
import './css/tooltip.css'
import './css/pipeline.css'
import './css/shadow.css'
import './css/card.css'
import './css/tickbox.css'
import './css/input.css'
import './css/simulation.css'
import './css/list.css'
import './css/list-editor.css'
import './css/landing.css'

import './css/colours/dark.css'
import './css/colours/light.css'


const root = document.getElementById('root') as HTMLElement;


// Extracts primary/secondary colours and stuff from the root using CSS variable names.
export function getCSSColour(name: string): string {
    const style = getComputedStyle(root);

    return style.getPropertyValue(`--${name}`);
}
