import React from "react";
import {Button, Card, MaterialColor} from "../basic-components";
import {Height} from "../basic-components/Height";


interface CreateSimulationFormProps {
    numberOfCircuits: number
    handleCreateSimulation: (selection: number) => void
}


interface CreateSimulationFormState {
    selectedCircuit: number
}


export class CreateSimulationForm extends React.Component<CreateSimulationFormProps, CreateSimulationFormState> {
    constructor(props) {
        super(props);

        this.state = {
            selectedCircuit: 0
        };
    }

    handleSelect(event: React.ChangeEvent<HTMLSelectElement>) {
        this.setState({
            selectedCircuit: event.target.selectedIndex
        });
    }

    handleCreate() {
        this.props.handleCreateSimulation(this.state.selectedCircuit);
    }

    render() {
        return <Card height={Height.Medium} color={MaterialColor.Normal} title={'Create Simulation'}
                     cardStyle={{flexShrink: 0}}
        >
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                alignItems: 'stretch',
                alignContent: 'stretch'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'stretch',
                    alignContent: 'stretch',
                    height: '30px'
                }}>
                    <span style={{
                        userSelect: 'none',
                        flexGrow: 1,
                        fontSize: '20px',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        alignContent: 'center'
                    }}>Circuit: </span>
                    <select style={{
                        border: '2px solid var(--outline)',
                        padding: '5px',
                        background: 'none',
                        color: 'var(--on-background)',
                        borderRadius: '5px'
                    }} onChange={(x) => this.handleSelect(x)}>
                        {[...Array(this.props.numberOfCircuits)].map((_, index) =>
                            <option value={index} selected={index === this.state.selectedCircuit}
                                    style={{
                                        background: 'var(--background)'
                                    }}
                            >
                                {index + 1}
                            </option>)}
                    </select>
                </div>
                <Button content={"Create"} color={MaterialColor.Secondary}
                        onClick={() => this.handleCreate()}
                />
            </div>
        </Card>
    }
}

