import {PipelineStage} from "../navigator";
import {AbstractPipelineState, AbstractPipelineWidget} from "../pipeline-component";
import {InputsOutputsForms} from "./InputsOutputsForms";
import {
    createProjectEngine,
    defaultFactory,
    LogicDiagramEngine,
    LogicEditor
} from "../logic-builder";
import {DiagramModel} from "@projectstorm/react-diagrams";
import {InputPinModel, OutputPinModel} from "../logic-builder/logic-components";
import {BoxSplittingView} from "./BoxSplittingView";
import {DPGASimulationInfo, DPGASimulationManager} from "./DPGASimulationManager";
import {Table} from "../data";
import {Nucleio, StrandAlignmentView} from "../nucleio";
import {StrandGenerationView} from "../nucleio/StrandGenerationView";
import StrandsOutput = Nucleio.StrandsOutput;


const DPGAPipelineStages: PipelineStage[] = [
    {
        stageName: 'Inputs and Outputs',
        accessible: true
    },
    {
        stageName: 'Create Circuit',
        accessible: false
    },
    {
        stageName: 'Split Circuits',
        accessible: false
    },
    {
        stageName: 'Simulate',
        accessible: false
    },
    {
        stageName: 'Input Strands',
        accessible: false
    },
    {
        stageName: 'Generate Strands',
        accessible: false
    }
];

const ioStage = 0;
const circuitStage = 1;
const splitStage = 2;
const simulationStage = 3;
const inputStrandsStage = 4;
const outputStrandsStage = 5;

const logicFactory = defaultFactory;


interface DPGAPipelineProps {

}

interface DPGAPipelineState extends AbstractPipelineState {
    // IO Stage
    inputNames: string[]
    outputNames: string[]
    ioValid: boolean
    // Editor Stage
    editorEngine: LogicDiagramEngine
    editorModel: DiagramModel
    circuitValid: boolean
    // Box splitting stage
    boxes: LogicDiagramEngine[],
    boxesValid: boolean
    // Simulation Stage
    simulationsCount: number,
    simulations: DPGASimulationInfo[]
    // Strands Upload
    strandsTable?: Table,
    strandsTableValid: boolean
    // Strand Generation
    outputStrands?: Nucleio.StrandsOutput
}


export class DPGAPipelineWidget extends AbstractPipelineWidget<DPGAPipelineProps, DPGAPipelineState> {
    getInitialState(): DPGAPipelineState {
        const [engine, model] = createProjectEngine();

        return {
            selectedStage: 0,
            pipelineStages: DPGAPipelineStages,
            canProceed: false,
            // IO stage
            inputNames: [],
            outputNames: [],
            ioValid: false,
            // Editor Stage
            editorEngine: engine,
            editorModel: model,
            circuitValid: true,
            // Box splitting stage
            boxes: [],
            boxesValid: false,
            // Simulation Stage
            simulationsCount: 0,
            simulations: [],
            strandsTable: Table.schemaOnly(Nucleio.getTargetRegionsSchema()),
            strandsTableValid: true,
            // Strand generation
            outputStrands: null
        }
    }

    // Whenever the inputs and outputs are changed, later stages will be invalidated.
    blockLateStages() {
        this.setState({
            pipelineStages: this.state.pipelineStages.map((stage, index) =>
                index > circuitStage ? { stageName: stage.stageName, accessible: false} : stage)
        });
    }

    handleInputsUpdate(newInputs: string[]) {
        this.setState({
            inputNames: newInputs
        });
        this.blockLateStages();
    }

    handleOutputsUpdate(newOutputs: string[]) {
        this.setState({
            outputNames: newOutputs
        });
        this.blockLateStages();
    }

    handleIOValidityUpdate(validity: boolean) {
        this.setState({
            ioValid: validity
        }, () => this.updateCanProceed());
    }

    handleCircuitValidityUpdate(validity: boolean) {
        this.setState({
            circuitValid: validity
        }, () => this.updateCanProceed());
    }

    boxesValid(boxes: LogicDiagramEngine[]) {
        // Generated logic circuits shouldn't have too many problems. All we need to do is see if there's any
        // mismatch between our specified inputs and outputs.
        const inputPinLabels = boxes.map((box) => box.getInputPins().map((pin) => pin.label))
            .reduce((x, y) => x.concat(y), []);
        const outputPinLabels = boxes.map((box) => box.getOutputPins().map((pin) => pin.label))
            .reduce((x, y) => x.concat(y), []);

        // Since TypeScript doesn't support set difference, I won't even bother constructing the sets. We'll check
        // for missing input pins and output pins.
        if (this.state.inputNames.some((x) => !inputPinLabels.includes(x))) {
            return false;
        }
        if (this.state.outputNames.some((x) => !outputPinLabels.includes(x))) {
            return false;
        }
        return true;
    }

    handleBoxesUpdate(boxes: LogicDiagramEngine[]) {
        this.setState({
            boxes: boxes,
            boxesValid: this.boxesValid(boxes)
        }, () => this.updateCanProceed())
    }

    handleSimulationCountUpdate(newCount: number) {
        this.setState({
            simulationsCount: newCount
        });
    }

    handleSimulationsUpdate(simulations: DPGASimulationInfo[]) {
        this.setState({
            simulations: simulations
        })
    }

    handleStrandsTableUpdate(table: Table) {
        this.setState({
            strandsTable: table
        });
    }

    handleStrandsTableValidityUpdate(validity: boolean) {
        this.setState({
            strandsTableValid: validity
        }, () => this.updateCanProceed());
    }

    generateStrands(threshold: boolean) {
        return Nucleio.getCircuitStrands(this.state.boxes, this.state.strandsTable, threshold);
    }

    handleGeneratedStrandsTablesUpdate(result: StrandsOutput) {
        this.setState({
            outputStrands: result
        });
    }

    // Initialises or updates the strands table upon stage selection. Makes sure that the row names line up with the
    // inputs.
    getUpdatedStrandsTable() {
        if (this.state.strandsTable) {
            const inputStrandNames = this.state.inputNames;
            for (const name of inputStrandNames) {
                // Check if the corresponding row is already present.
                const [index, row] = this.state.strandsTable.findRow((row) => row.get(0) === name);
                if (index < 0) {
                    this.state.strandsTable.addRowData([name, '', '', '']);
                }
            }
            return this.state.strandsTable.selectRows(
                (row) => inputStrandNames.includes(row.get(0)));
        } else {
            const table = Table.schemaOnly(Nucleio.getTargetRegionsSchema());
            for (const name of this.state.inputNames) {
                table.addRowData([name, '', '', '']);
            }
            return table;
        }
    }

    ensureInputPins() {
        // Skip the concurrent modification by using a filter.
        this.state.editorEngine.getInputPins()
            // Choose pins that have "expired".
            .filter((x) => !this.state.inputNames.includes(x.label))
            // Then delete them.
            .forEach((x) => x.remove());

        // We now add all the missing pins.
        const presentPinLabels = this.state.editorEngine.getInputPins()
            // Unfortunately TypeScript isn't smart enough to skip this cast.
            .map((x) => (x as InputPinModel).label);

        this.state.inputNames.filter((x) => !presentPinLabels.includes(x))
            .forEach((x, index) => {
                const newPin = new InputPinModel({label: x});
                newPin.setPosition(100, 50 * index);
                this.state.editorModel.addNode(newPin);
            });
    }

    ensureOutputPins() {
        // Skip the concurrent modification by using a filter.
        this.state.editorEngine.getOutputPins()
            // Choose pins that have "expired".
            .filter((x) => !this.state.outputNames.includes(x.label))
            // Then delete them.
            .forEach((x) => x.remove());

        // We now add all the missing pins.
        const presentPinLabels = this.state.editorEngine.getOutputPins()
            // Unfortunately TypeScript isn't smart enough to skip this cast.
            .map((x) => (x as OutputPinModel).label);

        this.state.outputNames.filter((x) => !presentPinLabels.includes(x))
            .forEach((x, index) => {
                const newPin = new OutputPinModel({label: x});
                newPin.setPosition(300, 50 * index);
                this.state.editorModel.addNode(newPin);
            });
    }

    // Ensures that the user's circuit has the correct pins.
    ensurePins() {
        this.ensureInputPins();
        this.ensureOutputPins();
    }

    onStageChange(previous: number, current: number) {
        // After setting the inputs and outputs, update the pins.
        if (previous === ioStage && current === circuitStage) {
            this.ensurePins();
        }
        // Update the strands table to contain the desired rows whenever we enter its stage.
        if (current === inputStrandsStage) {
            this.handleStrandsTableUpdate(this.getUpdatedStrandsTable());
        }
    }

    determineCanProceed(): boolean {
        switch (this.state.selectedStage) {
            case ioStage:
                return this.state.ioValid;
            case circuitStage:
                return this.state.circuitValid;
            case splitStage:
                return this.state.boxesValid;
            case simulationStage:
                return true;
            case inputStrandsStage:
                return this.state.strandsTableValid;
            case outputStrandsStage:
                return false;
        }
    }

    renderStage(stage: number) {
        switch (this.state.selectedStage) {
            case ioStage:
                return <InputsOutputsForms inputs={this.state.inputNames} outputs={this.state.outputNames}
                                           onInputsUpdate={(i) => this.handleInputsUpdate(i)}
                                           onOutputsUpdate={(i) => this.handleOutputsUpdate(i)}
                                           onValidityUpdate={(v) => this.handleIOValidityUpdate(v)}
                />
            case circuitStage:
                return <LogicEditor engine={this.state.editorEngine} factory={logicFactory}
                                    onValidityUpdate={(validity) => this.handleCircuitValidityUpdate(validity)}
                />
            case splitStage:
                return <BoxSplittingView originalCircuit={this.state.editorEngine} boxes={this.state.boxes} boxesValid={this.state.boxesValid}
                                  onBoxesUpdate={(s) => this.handleBoxesUpdate(s)}/>
            case simulationStage:
                return <DPGASimulationManager boxes={this.state.boxes} simulationInfos={this.state.simulations}
                                              simulationsCount={this.state.simulationsCount}
                                              updateSimulationCount={(x) => this.handleSimulationCountUpdate(x)}
                                              updateSimulations={(x) => this.handleSimulationsUpdate(x)}
                />
            case inputStrandsStage:
                return <StrandAlignmentView table={this.state.strandsTable}
                                            inputStrandNames={this.state.inputNames}
                                            onAnyTableUpdate={(x) => this.handleStrandsTableUpdate(x)}
                                            onValidityUpdate={(x) => this.handleStrandsTableValidityUpdate(x)}
                />
            case outputStrandsStage:
                return <StrandGenerationView generateStrands={(x) => this.generateStrands(x)}
                                             tables={this.state.outputStrands}
                                             setTables={(x) => this.handleGeneratedStrandsTablesUpdate(x)}/>;
        }
    }
}

