// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-holder {
    position: relative;
    overflow: visible;

    cursor: help !important;
}

.tooltip-holder .tooltip {
    display: none;
    opacity: 0;

    transition-duration: 0.1s;

    position: absolute;
    top: 50px;

    padding: 5px;

    font-size: 14px !important;

    border-radius: 5px;

    background-color: rgba(0, 0, 0, 0.7);
    color: var(--on-background);

    width: 150px;
    overflow-wrap: break-word;

    z-index: 999;
}

.tooltip-holder:hover .tooltip {
    display: block;
    opacity: 1;
}

`, "",{"version":3,"sources":["webpack://./src/css/tooltip.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;;IAEjB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,UAAU;;IAEV,yBAAyB;;IAEzB,kBAAkB;IAClB,SAAS;;IAET,YAAY;;IAEZ,0BAA0B;;IAE1B,kBAAkB;;IAElB,oCAAoC;IACpC,2BAA2B;;IAE3B,YAAY;IACZ,yBAAyB;;IAEzB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,UAAU;AACd","sourcesContent":[".tooltip-holder {\r\n    position: relative;\r\n    overflow: visible;\r\n\r\n    cursor: help !important;\r\n}\r\n\r\n.tooltip-holder .tooltip {\r\n    display: none;\r\n    opacity: 0;\r\n\r\n    transition-duration: 0.1s;\r\n\r\n    position: absolute;\r\n    top: 50px;\r\n\r\n    padding: 5px;\r\n\r\n    font-size: 14px !important;\r\n\r\n    border-radius: 5px;\r\n\r\n    background-color: rgba(0, 0, 0, 0.7);\r\n    color: var(--on-background);\r\n\r\n    width: 150px;\r\n    overflow-wrap: break-word;\r\n\r\n    z-index: 999;\r\n}\r\n\r\n.tooltip-holder:hover .tooltip {\r\n    display: block;\r\n    opacity: 1;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
