import React, {CSSProperties, ReactNode} from "react";
import {getShadowClass, getSurfaceColor, Height} from "./Height";
import {
    getContainerColor,
    getHighContainerColor,
    getOnContainerColorString,
    getOnHighContainerColor,
    MaterialColor
} from "./MaterialColor";


interface CardProps {
    children: ReactNode | ReactNode[],
    height: Height,
    color: MaterialColor,
    title?: string | ReactNode | ReactNode[],
    cardStyle?: CSSProperties,
    bodyStyle?: CSSProperties
}


export class Card extends React.Component<CardProps> {
    get backgroundColor() {
        if (this.props.color === MaterialColor.Normal) {
            return getSurfaceColor(this.props.height);
        }
        return getContainerColor(this.props.color);
    }

    renderTitle() {
        return <div className={'title'}
                    style={{
                        backgroundColor: getHighContainerColor(this.props.color),
                        color: getOnHighContainerColor(this.props.color)
                    }}
        >{this.props.title}</div>
    }

    render() {
        return <div className={`material-card ${getShadowClass(this.props.height)}`}
                    style={{
                        backgroundColor: this.backgroundColor,
                        color: getOnContainerColorString(this.props.color),
                        ...this.props.cardStyle
                    }}>
            {this.props.title ? this.renderTitle() : ''}
            <div className={'content'} style={this.props.bodyStyle}>
                {this.props.children}
            </div>
        </div>
    }
}