import {TableWidget, TableWidgetProps} from "./TableWidget";
import {Table} from "../Table";
import React, {CSSProperties} from "react";
import Dropzone from "react-dropzone";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpIcon from "@mui/icons-material/Help";
import RuleIcon from "@mui/icons-material/Rule";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DownloadIcon from '@mui/icons-material/Download';
import {FileDropzone} from "../../dropzone";
import {saveFile} from "../../file";
import {Nucleio} from "../../nucleio";


export interface ExtendedTableWidgetProps extends TableWidgetProps {
    // When an entirely new table is created or uploaded, modify its schema to fit certain specs.
    // Note that this is responsible for setting the table prop on table changes.
    onTableCompletelyChanged?: (table?: Table) => void
    formatRequirements?: string
    tableTitle: string
    // Makes a new table, used if the table is reset.
    createEmptyTable?: () => Table
    allowReplacing: boolean
    style?: CSSProperties
    supportFasta?: boolean
}

interface ExtendedTableWidgetState {
    // Decorative state. Used to force updates.
    hasTable: boolean
}


export class TableCard extends React.Component<ExtendedTableWidgetProps, ExtendedTableWidgetState> {
    get hasTable() {
        if (this.props.table) return true;
        return false;
    }

    get supportedInputs() {
        if (this.props.supportFasta) {
            return {
                'text/csv': ['.csv'],
                'text/plain': ['.fasta']
            }
        }
        return {
            'text/csv': ['.csv']
        }
    }

    tableEditable() {
        if (this.props.allowReplacing || this.props.allowAddingColumns || this.props.allowAddingOrRemovingRows) return true;
        if (!this.hasTable) return true;
        if (this.props.table.schema.entries.some((x) => x.renamable || x.editable)) return true;
        return false;
    }

    resetTable() {
        const newTable = this.props.createEmptyTable();
        if (this.props.onTableCompletelyChanged) {
            this.props.onTableCompletelyChanged(newTable);
        }
        this.setState({
            hasTable: true
        })
    }

    deleteTable() {
        if (this.props.onTableCompletelyChanged) {
            this.props.onTableCompletelyChanged(null);
        }
        this.setState({
            hasTable: false
        });
    }

    saveTable() {
        saveFile(this.props.table.toCSVText(), 'text/csv', `${this.props.tableTitle}.csv`)
    }

    loadTable(file: File) {
        // Try to read the file.
        file.text().then(content => {
            if (file.name.toLowerCase().endsWith('.fasta')) {
                Nucleio.loadFasta(content)
                    .then((table) => {
                        if (this.props.onTableCompletelyChanged) {
                            this.props.onTableCompletelyChanged(table);
                            this.props.onAnythingChanged(table);
                        }
                        this.setState({
                            hasTable: true
                        });
                    })
                    //.catch(() => {})
            } else {
                const table = Table.fromCSV(content);
                if (this.props.onTableCompletelyChanged) {
                    this.props.onTableCompletelyChanged(table);
                    this.props.onAnythingChanged(table);
                }
                this.setState({
                    hasTable: true
                });
            }
        });
    }

    renderUploadButton() {
        return <Dropzone accept={this.supportedInputs} onDropAccepted={(files) => this.loadTable(files[0])} multiple={false}>
            {({getRootProps, getInputProps}) => (
                <span {...getRootProps()}>
                    <input {...getInputProps()}/>
                    <span className={'button'} title={'Upload File'}>
                        <UploadIcon/>
                    </span>
                </span>
            )}
        </Dropzone>
    }

    renderToolbar() {
        return <div className={'toolbar'}>
            <span className={'title'}>{this.props.tableTitle}</span>
            {(this.props.allowReplacing && !!this.props.createEmptyTable) ?
                <span className={'button'} title={'Reset Table'} onClick={() => this.resetTable()}>
                    <RestartAltIcon/>
                </span> : ''}
            {this.props.allowReplacing ? <span className={'button'} title={'Delete Table'}
                                               onClick={() => this.deleteTable()}>
                <DeleteIcon/>
            </span> : ''}
            {this.props.allowReplacing ? this.renderUploadButton() : ''}
            {this.props.table ? <span className={'button'} title={'Download Table'}
                                      onClick={() => this.saveTable()}>
                <DownloadIcon/>
            </span> : ''}
            {this.tableEditable() ? <span className={'button tooltip-holder'} title={'Help'}>
                <HelpIcon/>
                <div className={'tooltip'}>
                    Click on cells to edit them. Use arrow keys to quickly increment or decrement cell values.
                </div>
            </span> : ''}
            {this.props.formatRequirements ? <span className={'button tooltip-holder'} title={'Format Requirements'}>
                <RuleIcon/>
                <div className={'tooltip'}>
                    {this.props.formatRequirements}
                </div>
            </span> : ''}
        </div>
    }

    renderDropZone() {
        return <FileDropzone acceptedFileTypes={['.csv'].concat(this.props.supportFasta ? ['.fasta'] : [])}
                             acceptedFileTypeSpecs={this.supportedInputs}
                             hintText={'Drag and drop files here, or click to select files. '}
                             onFileDropped={(file) => this.loadTable(file)}/>
    }

    renderTable() {
        return <TableWidget {...this.props}/>
    }

    renderContent() {
        return <div className={'content'}>
            {this.hasTable ? this.renderTable() : this.renderDropZone()}
        </div>
    }

    render() {
        return <div className={'extended-table-container'} style={this.props.style}>
            {this.renderToolbar()}
            {this.renderContent()}
        </div>
    }
}

