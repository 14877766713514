import * as React from "react";
import {DiagramEngine, CanvasWidget } from "@projectstorm/react-diagrams";
import {TrayWidget} from "./TrayWidget";
import {TrayItemWidget} from "./TrayItemWidget";
import {LogicComponentFactory} from "./LogicComponentFactory";
import { ErrorWidgetList } from "./overlay/ErrorOverlay";
import HelpIcon from "@mui/icons-material/Help";

export interface LogicEditorProps {
    engine: DiagramEngine;
    factory: LogicComponentFactory;
    noEditing?: boolean;
    // Called when the circuit's validity changes.
    onValidityUpdate?: (valid: boolean) => void;
}

export class LogicEditor extends React.Component<LogicEditorProps> {
    constructor(props) {
        super(props);

        this.props.engine.getModel().registerListener({
            eventDidFire: (event) => {
                if (!this.props.onValidityUpdate) return;

                if (event.function === 'validation-valid') {
                    this.props.onValidityUpdate(true);
                }
                if (event.function === 'validation-invalid') {
                    this.props.onValidityUpdate(false);
                }
            }
        })
    }

    renderHintOverlay() {
        return <div className={'hint-overlay'}>
            <span className={'button tooltip-holder'} title={'Help'}>
                <HelpIcon/>
                <div className={'tooltip'}>
                    Drag gates at the bottom into the diagram to add them to your circuit.
                    Drag between ports to create links.
                    Click on gates or links to select them. Press the Del key to delete selected items.
                    Drag gates in the diagram to move them.
                </div>
            </span>
        </div>
    }

    render() {
        return <div className={`builder-container ${this.props.noEditing ? 'disabled' : ''}`}>
            <ErrorWidgetList engine={this.props.engine}/>
            {this.props.noEditing ? '' : this.renderHintOverlay()}
            <div
                className="diagram-container"
                onDragOver={ (event) => {
                    // Preventing the default action for onDragOver indicates that the thing is indeed supposed to
                    // be dragged here.
                    // https://developer.mozilla.org/en-US/docs/Web/API/HTML_Drag_and_Drop_API/Drag_operations#specifying_drop_targets
                    event.preventDefault();
                } }
                onDrop={ (event) => {
                    this.onTrayItemDrop(event)
                } }
            >
                <CanvasWidget className="diagram" engine={this.props.engine} />
            </div>
            {this.props.noEditing ? '' : <TrayWidget>
                {this.getWidgetFor('and_gate', 'AND')}
                {this.getWidgetFor('or_gate', 'OR')}
                {this.getWidgetFor('xor_gate', 'XOR')}
                {this.getWidgetFor('not_gate', 'NOT')}
            </TrayWidget>}
        </div>;
    }


    getWidgetFor(type: string, name: string) {
        return <TrayItemWidget gateWidget={this.props.factory.createComponentWidget(type)} nodeType={type} nodeName={name}/>
    }

    onTrayItemDrop(event: React.DragEvent<HTMLDivElement>) {
        const type = JSON.parse(event.dataTransfer.getData('drop-component')).type;

        const node = this.props.factory.createComponentModel(type);

        let point = this.props.engine.getRelativeMousePoint(event);
        node.setPosition(point);
        this.props.engine.getModel().addNode(node);
        this.forceUpdate();
    }
}