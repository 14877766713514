// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-container {
    height: 30px;

    display: flex;
    flex-direction: row;
    align-content: stretch;
    align-items: stretch;
    gap: 5px;

    font-size: 20px;
    -webkit-user-select: none;
            user-select: none;

    color: var(--on-background);
}

.input-container .label {
    flex-grow: 1;
}

.input-container input {
    outline: none;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 2px solid var(--outline);

    font-size: 20px;
    min-width: 0;
    width: 100px;

    color: var(--on-background);
    font-family: 'Roboto', sans-serif;

    transition-duration: 0.1s;
    background: none;
    -webkit-backdrop-filter: brightness(85%);
            backdrop-filter: brightness(85%);

    text-align: right;

    padding: 5px;
}

.input-container input.invalid {
    border-bottom: 2px solid var(--error);
}

.input-container input:focus {
    border-bottom: 2px solid var(--on-background);
}

`, "",{"version":3,"sources":["webpack://./src/css/input.css"],"names":[],"mappings":"AAAA;IACI,YAAY;;IAEZ,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,oBAAoB;IACpB,QAAQ;;IAER,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;;IAEjB,2BAA2B;AAC/B;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,uCAAuC;;IAEvC,eAAe;IACf,YAAY;IACZ,YAAY;;IAEZ,2BAA2B;IAC3B,iCAAiC;;IAEjC,yBAAyB;IACzB,gBAAgB;IAChB,wCAAgC;YAAhC,gCAAgC;;IAEhC,iBAAiB;;IAEjB,YAAY;AAChB;;AAEA;IACI,qCAAqC;AACzC;;AAEA;IACI,6CAA6C;AACjD","sourcesContent":[".input-container {\r\n    height: 30px;\r\n\r\n    display: flex;\r\n    flex-direction: row;\r\n    align-content: stretch;\r\n    align-items: stretch;\r\n    gap: 5px;\r\n\r\n    font-size: 20px;\r\n    user-select: none;\r\n\r\n    color: var(--on-background);\r\n}\r\n\r\n.input-container .label {\r\n    flex-grow: 1;\r\n}\r\n\r\n.input-container input {\r\n    outline: none;\r\n    border-left: none;\r\n    border-right: none;\r\n    border-top: none;\r\n    border-bottom: 2px solid var(--outline);\r\n\r\n    font-size: 20px;\r\n    min-width: 0;\r\n    width: 100px;\r\n\r\n    color: var(--on-background);\r\n    font-family: 'Roboto', sans-serif;\r\n\r\n    transition-duration: 0.1s;\r\n    background: none;\r\n    backdrop-filter: brightness(85%);\r\n\r\n    text-align: right;\r\n\r\n    padding: 5px;\r\n}\r\n\r\n.input-container input.invalid {\r\n    border-bottom: 2px solid var(--error);\r\n}\r\n\r\n.input-container input:focus {\r\n    border-bottom: 2px solid var(--on-background);\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
