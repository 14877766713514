import {Table} from "../Table";
import React from "react";
import {Cell} from "../Cell";
import {TableRow} from "../TableRow";
import {TableHeaderWidget} from "./HeaderWidget";
import {TableRowWidget} from "./RowWidget";
import {TableColumn} from "../TableColumn";
import AddIcon from '@mui/icons-material/Add';
import {SchemaEntry} from "../TableSchema";


export interface TableWidgetProps {
    table?: Table,
    onCellChange?: (cell: Cell, rowIndex: number, columnIndex: number, unparsedValue: string) => void
    onColumnRename?: (columnIndex: number, newName: string) => void
    onRowAdded?: () => void
    onRowRemoved?: (row: TableRow, oldIndex: number) => void
    onColumnAdded?: (column: TableColumn) => void
    onColumnRemoved?: (column: TableColumn, oldIndex: number) => void

    // Called if the table is changed for any reason.
    onAnythingChanged?: (table: Table) => void
    // Called when adding a new column.
    createSchemaEntry?: (table: Table) => SchemaEntry
    allowAddingOrRemovingRows: boolean
    allowAddingColumns: boolean
}

export class TableWidget extends React.Component<TableWidgetProps> {
    handleAnyChange() {
        if (this.props.onAnythingChanged) {
            this.props.onAnythingChanged(this.props.table);
        }
    }

    handleColumnRename(columnIndex: number, newName: string) {
        if (this.props.onColumnRename) {
            this.props.onColumnRename(columnIndex, newName);
        }
        this.handleAnyChange();
        // No need to rerender since the table structure didn't change.
    }

    handleColumnDelete(columnIndex: number) {
        const column = this.props.table.column(columnIndex);
        this.props.table.removeColumn(columnIndex);
        if (this.props.onColumnRemoved) {
            this.props.onColumnRemoved(column, columnIndex);
        }
        this.handleAnyChange();
        // Rerender since table structure has changed.
        this.forceUpdate();
    }

    handleCellChange(cell: Cell, rowIndex: number, columnIndex: number, unparsedValue: string) {
        if (this.props.onCellChange) {
            this.props.onCellChange(cell, rowIndex, columnIndex, unparsedValue);
        }
        this.handleAnyChange();
    }

    handleRowDelete(row: TableRow, rowIndex: number) {
        this.props.table.removeRow(rowIndex);
        if (this.props.onRowRemoved) {
            this.props.onRowRemoved(row, rowIndex);
        }
        this.handleAnyChange();
        // Rerender since table structure has changed.
        this.forceUpdate();
    }

    handleAddRow() {
        this.props.table.addEmptyRow();
        if (this.props.onRowAdded) {
            this.props.onRowAdded();
        }
        this.handleAnyChange();
        this.forceUpdate();
    }

    handleAddColumn() {
        if (this.props.createSchemaEntry) {
            this.props.table.addEmptyColumn(this.props.createSchemaEntry(this.props.table));
        } else {
            this.props.table.addEmptyColumn(new SchemaEntry(''));
        }
        if (this.props.onColumnAdded) {
            this.props.onColumnAdded(this.props.table.column(this.props.table.numColumns - 1));
        }
        this.handleAnyChange();
        this.forceUpdate();
    }

    renderRows() {
        return this.props.table.getRows().map((row, index) =>
            <TableRowWidget index={index} row={row} key={index}
                            onCellChange={(cell, columnIndex, unparsedValue) => {
                                this.handleCellChange(cell, index, columnIndex, unparsedValue);
                            }}
                            handleRowDelete={(row, index) => this.handleRowDelete(row, index)}
                            deletable={this.props.allowAddingOrRemovingRows}/>)
    }

    renderAddRowButtonRow() {
        if (this.props.allowAddingOrRemovingRows) {
            return <tr>
                <td style={{border: 'none'}}/>
                <td colSpan={this.props.table.numColumns} className={'add-row-button-container'}>
                    <div className={'add-row-button'} onClick={() => this.handleAddRow()}>
                        <AddIcon/>
                    </div>
                </td>
            </tr>
        }
        return <tr/>
    }

    get hasRemovableColumns() {
        return this.props.table.schema.entries.some((e) => e.renamable);
    }

    renderAddColumnBar() {
        if (this.props.allowAddingColumns) {
            return <div className={'add-column-bar'}>
                <div className={this.hasRemovableColumns ? 'filler-remove-column-button' : ''}/>
                <div className={'remove-column-button'} onClick={() => this.handleAddColumn()}>
                    <AddIcon/>
                </div>
                <div className={this.props.allowAddingOrRemovingRows ? 'filler-add-row-button' : ''}/>
            </div>
        }
        return <div/>
    }

    renderTable() {
        return <table className={'editable-table'}>
            <TableHeaderWidget schema={this.props.table.schema}
                               onColumnRename={(i, newName) => this.handleColumnRename(i, newName)}
                               handleColumnDelete={(i) => this.handleColumnDelete(i)}
                               rowsDeletable={this.props.allowAddingOrRemovingRows}/>
            <tbody>
            {this.renderRows()}
            {this.renderAddRowButtonRow()}
            </tbody>
        </table>
    }

    render() {
        return <div className={`table-container ${this.props.allowAddingOrRemovingRows ? 'allow-remove-rows' : ''}`}>
            <div className={'table-body-container'}>
                {this.renderTable()}
            </div>
            {this.renderAddColumnBar()}
        </div>
    }
}
