import {defaultSimulationOptions, pendingSimulation, Simulation, SimulationOptions} from "./Simulation";
import React from "react";
import {Button, Card, CheckboxForm, MaterialColor} from "../basic-components";
import {SimulationForm} from "./SimulationForm";
import {SimulationDataView} from "./SimulationDataView";
import {Status} from "./Status";
import {Height} from "../basic-components/Height";
import {saveFile} from "../file";


interface SimulationViewProps {
    inputNames: string[]
    simulate: (inputs: boolean[], options: SimulationOptions) => Promise<Simulation>
    onOptionsChange?: (options: SimulationOptions) => void,
    onSimulationUpdate?: (simulation: Simulation) => void,
    onInputsChange?: (inputs: boolean[]) => void,
    simulation: Simulation,
    inputs: boolean[],
    options: SimulationOptions,
    canSimulate: boolean,
    disabled?: boolean
}

interface SimulationViewState {
}

export class SimulationView extends React.Component<SimulationViewProps, SimulationViewState> {
    constructor(props) {
        super(props);
    }

    handleInputsUpdate(newInputs: boolean[]) {
        if (this.props.onInputsChange) {
            this.props.onInputsChange(newInputs);
        }
    }

    handleOptionsUpdate(options: SimulationOptions) {
        if (this.props.onOptionsChange) {
            this.props.onOptionsChange(options);
        }
    }

    runSimulation(options: SimulationOptions) {
        this.handleOptionsUpdate(options);
        if (this.props.onSimulationUpdate) {
            this.props.onSimulationUpdate(pendingSimulation());
        }

        this.props.simulate(this.props.inputs, this.props.options)
            .then((result) => {
                if (this.props.onSimulationUpdate) {
                    this.props.onSimulationUpdate(result);
                }
            });
    }

    downloadConcentrationsTable() {
        saveFile(
            this.props.simulation.concentrations.toCSVText(),
            'text/csv',
            'concentrations.csv'
        );
    }

    downloadDSDCode() {
        saveFile(
            this.props.simulation.dsdCode,
            'text/txt',
            'code.txt'
        );
    }

    downloadInitials() {
        saveFile(
            this.props.simulation.svgContent,
            'image/svg+xml',
            'initials.svg'
        )
    }

    renderDownloadForm() {
        return <Card height={Height.Medium} color={MaterialColor.Normal} title={'Download'}>
            <div style={{
                flexGrow: 0,
                flexShrink: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                alignContent: 'stretch',
                alignItems: 'stretch'
            }}>
                <Button content={'Concentrations'} color={MaterialColor.Secondary}
                        onClick={() => this.downloadConcentrationsTable()}
                />
                <Button content={'DSD Code'} color={MaterialColor.Secondary}
                        onClick={() => this.downloadDSDCode()}
                />
                <Button content={'Reactions'} color={MaterialColor.Secondary}
                        onClick={() => this.downloadInitials()}
                />
            </div>
        </Card>
    }

    render() {
        return <div className={'simulation-view'}>
            <div className={'inputs'}>
                <CheckboxForm title={"Inputs"} inputNames={this.props.inputNames} inputs={this.props.inputs}
                              onChange={(i) => this.handleInputsUpdate(i)} disabled={this.props.disabled}/>
                <SimulationForm canRun={this.props.canSimulate && !this.props.disabled}
                                onChange={(i) => this.handleOptionsUpdate(i)}
                                onRun={(i) => this.runSimulation(i)} options={this.props.options}/>
                {this.props.simulation.status === Status.Success ? this.renderDownloadForm() : ''}
            </div>
            <div className={'data-view-container'}>
                <SimulationDataView {...this.props.simulation}/>
            </div>
        </div>
    }
}
