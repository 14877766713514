import {getColorString, MaterialColor} from "./MaterialColor";
import React from "react";
import CheckIcon from '@mui/icons-material/Check';


interface LabeledTickboxProps {
    label: string,
    color: MaterialColor,
    onChange?: (newState: boolean) => void,
    flip?: boolean,
    initialValue?: boolean,
    selected: boolean,
    disabled?: boolean
}

export class Checkbox extends React.Component<LabeledTickboxProps> {
    toggleSelect() {
        if (this.props.disabled) return;

        if (this.props.onChange) {
            this.props.onChange(!this.props.selected);
        }
        this.setState({
            selected: !this.props.selected
        });
    }

    renderBody() {
        return <span className={`tickbox-body ${getColorString(this.props.color)} ${this.props.selected ? 'selected' : ''} ${this.props.disabled ? 'disabled' : ''}`}
                     onClick={() => this.toggleSelect()}>
            <CheckIcon className={'check-icon'}/>
        </span>
    }

    renderLabel() {
        return <span className={'tickbox-label'}>
                {this.props.label}
            </span>
    }

    render() {
        if (this.props.flip) {
            return <div className={`tickbox-container`}>
                {this.renderLabel()}
                {this.renderBody()}
            </div>
        }
        return <div className={`tickbox-container`}>
            {this.renderBody()}
            {this.renderLabel()}
        </div>
    }
}

