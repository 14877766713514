import {DiagramModel} from "@projectstorm/react-diagrams";
import {LogicDiagramEngine} from "./LogicDiagramEngine";
import {InputPinModel, OutputPinModel} from "./logic-components";


export function createProjectEngine(): [LogicDiagramEngine, DiagramModel] {
    const engine = new LogicDiagramEngine();
    const model = new DiagramModel();

    engine.setModel(model);

    return [engine, model];
}


export function createDummyEngine(): [LogicDiagramEngine, DiagramModel] {
    const engine = new LogicDiagramEngine();
    const model = new DiagramModel();

    engine.setModel(model);

    const inp = new InputPinModel({
        label: 'Dummy In'
    });
    const outp = new OutputPinModel({
        label: 'Dummy Out'
    });

    model.addAll(inp, outp);

    return [engine, model];
}
