import * as React from 'react';
import { DiagramEngine } from '@projectstorm/react-diagrams';
import {NotGateModel} from "./NotGateModel";
import {LogicPortWidget} from "../logic-components/LogicPortWidget";

export interface NotGateWidgetOptions {
    node: NotGateModel;
    engine: DiagramEngine;
}

export interface NotGateWidgetState {}

export class NotGateWidget extends React.Component<NotGateWidgetOptions, NotGateWidgetState> {
    constructor(props: NotGateWidgetOptions) {
        super(props);
        this.state = {};

        // Update the appearance once the model has updated.
        props.node.registerListener({
            eventDidFire: (event) => {
                if (event.function === 'logicUpdate') {
                    this.forceUpdate();
                }
            },
            selectionChanged: (e) => {
                this.forceUpdate()
            }
        });
    }

    render() {
        return (
            <div className={`logic-gate ${this.selectedStyleClass}`}>
                <div className={"body-layer"}>
                    <div className={`not-gate-body ${this.selectedStyleClass}`} />
                </div>
                <div className={"port-layer"}>
                    <div className="input-ports">
                        <LogicPortWidget engine={this.props.engine} model={this.props.node.in}/>
                    </div>
                    <div className={'filler'}/>
                    <div className="output-ports">
                        <LogicPortWidget engine={this.props.engine} model={this.props.node.out}/>
                    </div>
                </div>
            </div>
        );
    }

    get selectedStyleClass(): string {
        if (this.props.node.isSelected()) {
            return 'logic-gate-selected';
        }
        return '';
    }
}