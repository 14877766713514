import * as React from "react";

export interface NavigatorButtonWidgetProps {
    stageDescription: string;
    isSelected: boolean;
    isAccessible: boolean;
}

export interface NavigatorButtonWidgetState {
}

export class NavigatorButton extends React.Component<NavigatorButtonWidgetProps, NavigatorButtonWidgetState> {
    constructor(props: NavigatorButtonWidgetProps) {
        super(props);

        this.state = {
            isSelected: false,
            isAccessible: false,
        }
    }

    get selected() {
        return this.props.isSelected
    }
    get accessible() {
        return this.props.isAccessible
    }

    getClass = () => {
        if (this.selected) {
            return 'selected'
        } else if (this.accessible) {
            return 'selectable'
        } else {
            return 'disabled'
        }
    }

    render() {
        return (
            <div className={`navigator-button-container ${this.getClass()}`}>
                <div className={`navigator-button-display`}>
                    <div className="navigator-button-tail-container">
                        <div className="navigator-button-tail-top"/>
                        <div className="navigator-button-tail-bottom"/>
                    </div>
                    <div className={"navigator-button-body"}>
                        {this.props.stageDescription}
                    </div>
                    <div className="navigator-button-head-container">
                        <div className="navigator-button-head"/>
                    </div>
                </div>
            </div>  
        )
    }
}
