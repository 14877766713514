import * as React from 'react';
import {DiagramEngine} from '@projectstorm/react-diagrams';
import {InputPinModel} from "./InputPinModel";
import {negate} from "../LogicState";
import {LogicPortWidget} from "./LogicPortWidget";
import {LogicPortModel} from "./LogicPortModel";

export interface InputPinWidgetProps {
    model: InputPinModel;
    engine: DiagramEngine;
}

export interface InputPinWidgetState {}

export class InputPinWidget extends React.Component<InputPinWidgetProps, InputPinWidgetState> {
    constructor(props: InputPinWidgetProps) {
        super(props);
        this.state = {};

        this.props.model.registerListener({
            selectionChanged: (e) => this.forceUpdate()
        })
    }

    lastMouseDown: number = 0;
    mouseDown() {
        this.lastMouseDown = Date.now();
    }

    mouseUp() {
        this.props.model.setSelected(false);

        // Only toggle when the click is short.
        if (Date.now() - this.lastMouseDown > 150) return;

        this.toggle();
    }

    render() {
        return (
            <div className={`input-pin ${this.selectedStyleClass}`}
                 onMouseDown={ () => this.mouseDown() }
                 onMouseUp={ () => this.mouseUp() } >
                <div className={'body-layer'}>
                    <div className="input-pin-body">
                        {this.props.model.label}
                    </div>
                    <div className="input-pin-edge"/>
                </div>
                <div className={'port-layer'}>
                    <div className={'filler'}/>
                    <div className="output-ports">
                        <LogicPortWidget engine={this.props.engine} model={this.props.model.getPort('out') as LogicPortModel}/>
                    </div>
                </div>
            </div>
        );
    }

    get selectedStyleClass(): string {
        if (this.props.model.isSelected()) return 'input-pin-selected';
        return '';
    }

    toggle() {
        const model = this.props.model;

        model.logicState = negate(model.logicState);
        model.updateAndPropagate();

        this.forceUpdate();
    }
}