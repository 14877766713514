import {SchemaEntry} from "./TableSchema";
import {Cell} from "./Cell";
import {pad} from "./utils";

export class TableColumn {
    readonly schema: SchemaEntry
    readonly cells: Cell[]

    constructor(schema: SchemaEntry, cells: Cell[]) {
        this.schema = schema;
        this.cells = cells;
    }

    get length(): number {
        return this.cells.length;
    }

    get name(): string {
        return this.schema.fieldName;
    }

    get(i: number): any {
        return this.cells[i].getContent();
    }

    set(i: number, content: any) {
        this.cells[i].setContent(content);
    }

    prettyPrint(): string {
        const headerWidth = this.schema.fieldName.length;
        const maxCellWidth = this.cells.map((cell) => cell.toString().length)
            .reduce((x, y) => Math.max(x, y));

        const width = Math.max(maxCellWidth, headerWidth);

        const header = `| ${pad(this.schema.fieldName, width)} |`;
        const divider = `|${"-".repeat(width + 2)}|`;
        const content = this.cells.map((cell) => `| ${pad(cell.toString(), width)} |`).join('\n');

        return `${header}\n${divider}\n${content}`;
    }
}


