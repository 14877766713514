import {Any, Num, ValidationScheme} from "./validation";
import {Cell} from "./Cell";

export class SchemaEntry {
    fieldName: string
    // If the entry is renamable then it's also assumed to be deletable.
    renamable: boolean
    editable: boolean
    readonly validation: ValidationScheme

    constructor(name: string, validation: ValidationScheme = new Any(),
                renameable: boolean = true, editable: boolean = true) {
        this.fieldName = name;
        this.validation = validation;
        this.renamable = renameable;
        this.editable = editable;
    }

    static num(name: string) {
        return new SchemaEntry(name, new Num({}));
    }

    static num01(name: string) {
        return new SchemaEntry(name, new Num({greaterOrEqual: 0, lessOrEqual: 1}));
    }

    validate(content: any): boolean {
        return this.validation.valid(content);
    }

    validateString(content: any): boolean {
        return this.validation.validString(content);
    }

    createCell(content: any): Cell {
        return new Cell(content, this.validation);
    }

    createCellFromString(content: any): Cell {
        if (!this.validateString(content)) throw new Error('Invalid content.');

        return Cell.fromStr(content, this.validation);
    }
}

export class TableSchema {
    entries: SchemaEntry[]

    constructor(entries: SchemaEntry[]) {
        this.entries = entries;
    }

    get numColumns(): number {
        return this.entries.length;
    }

    validateRow(content: any[]) {
        if (content.length !== this.numColumns) return false;

        for (let i = 0; i < this.numColumns; i++) {
            if (!this.entries[i].validate(content[i])) return false;
        }

        return true;
    }

    validateRowString(content: string[]) {
        if (content.length !== this.numColumns) return false;

        for (let i = 0; i < this.numColumns; i++) {
            if (!this.entries[i].validateString(content[i])) return false;
        }

        return true;
    }

    createRowCells(content: any[]): Cell[] {
        return content.map((value, i) => this.entries[i].createCell(value));
    }

    createRowCellsFromStrings(content: string[]): Cell[] {
        return content.map((value, i) => this.entries[i].createCellFromString(value));
    }
}
