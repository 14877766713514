import React, {ReactNode} from "react";
import dummy from './assets/dummy_pic.png'
import { ReactComponent as DPGALogo } from "./assets/dpga.svg";
import { ReactComponent as WTALogo } from "./assets/wta.svg";
import { ReactComponent as Logo } from "./assets/logo.svg";
import {Button, MaterialColor} from "./basic-components";
import {Height} from "./basic-components/Height";


interface ModelOptionProps {
    image: ReactNode,
    title: string,
    children?: string | ReactNode | ReactNode[],
    url?: string
}


class ModelOption extends React.Component<ModelOptionProps> {
    render() {
        return <a className={'model-option'} href={this.props.url}>
            {this.props.image}
            <div className={'model-title'}>
                {this.props.title}
            </div>
            <div className={'model-description'}>
                {this.props.children}
            </div>
        </a>
    }
}


export class SelectModelPage extends React.Component {
    renderMainLayer() {
        return <div className={'model-selection-container'} style={{position: 'absolute', bottom: 30}}>
            <div className={'model-options-container'}>
                <ModelOption image={<WTALogo width={''} height={''} style={{
                    minWidth: '100%'
                }}/>} title={"WTA Network"} url={'/wta'}>
                    A one layer neural network where input patterns are compared to individual memories.
                    The memory most similar to the pattern will win.
                    <div style={{ flexGrow: 1 }}/>
                    <div style={{
                        fontSize: '12px',
                        opacity: 0.7
                    }}>
                        Maximum Inputs: 75
                        <br/>
                        Maximum Memories: 4
                    </div>
                </ModelOption>
                <ModelOption image={<DPGALogo width={''} height={''} style={{
                    minWidth: '100%'
                }}/>} title={"DPGA Circuit"} url={'/dpga'}>
                    A circuit made out of DNA logic gates.
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <div style={{ flexGrow: 1 }}/>
                    <div style={{
                        fontSize: '12px',
                        opacity: 0.7
                    }}>
                        Maximum Input Pins: 8
                        <br/>
                        Maximum Output Pins: 4
                    </div>
                </ModelOption>
                <div style={{
                    position: 'absolute',
                    bottom: 96,
                    justifyContent: 'center',
                    justifyItems: 'center',
                    justifyTracks: 'center',
                    textAlign: 'center'
                    }}>
                    <Button content={"Learn More"} color={MaterialColor.Secondary}
                    height={Height.Highest} href={'https://2024.igem.wiki/mcgill/guide-to-dna-computing'}/>
                </div>
            </div>

        </div>
    }

    renderLogo() {
        return <div style={{
            position: "absolute",
            bottom: 0,
            width: '100%',
            height: '40px',
            padding: '20px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            justifyItems: 'center'
        }}>
            <a href={'/'}>
                <Logo width={''} height={''} style={{
                    height: '40px'
                }}/>
            </a>
        </div>
    }

    render() {
        return <div className={'landing-container'}>
            {this.renderMainLayer()}
            {this.renderLogo()}
        </div>
    }
}