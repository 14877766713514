import * as React from 'react';
import { NotGateModel } from './NotGateModel';
import { NotGateWidget } from './NotGateWidget';
import { AbstractReactFactory } from '@projectstorm/react-diagrams';
import { DiagramEngine } from '@projectstorm/react-diagrams';

export class NotGateNodeFactory extends AbstractReactFactory<NotGateModel, DiagramEngine> {
    constructor() {
        super('not-gate-node');
    }

    generateModel(initialConfig: any) {
        return new NotGateModel();
    }

    generateReactWidget(event: any): React.ReactElement {
        return <NotGateWidget engine={this.engine as DiagramEngine} node={event.model} />;
    }
}