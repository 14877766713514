import React from "react";
import {
    defaultSimulationOptions,
    emptySimulation, getStatusColor,
    getStatusText,
    Nucleio,
    Simulation,
    SimulationOptions, Status,
} from "../nucleio";
import {ListSelector} from "../basic-components";
import {SimulationView} from "../nucleio/SimulationView";
import {LogicDiagramEngine} from "../logic-builder";
import {CreateSimulationForm} from "./CreateSimulationForm";


interface DPGASimulationManagerProps {
    boxes: LogicDiagramEngine[],
    simulationInfos: DPGASimulationInfo[],
    simulationsCount: number,
    updateSimulations: (infos: DPGASimulationInfo[]) => void,
    updateSimulationCount: (count: number) => void
}

export interface DPGASimulationInfo {
    name: string,
    simulation: Simulation,
    options: SimulationOptions,
    canSimulate: boolean,
    inputs: boolean[],
    circuit: LogicDiagramEngine,
    circuitIndex: number
}


interface DPGASimulationManagerState {
    selectedSimulation: number,
}


export class DPGASimulationManager extends React.Component<DPGASimulationManagerProps, DPGASimulationManagerState> {
    constructor(props) {
        super(props);

        this.state = {
            selectedSimulation: -1,
        };
    }

    newSimulationInfo(newIndex: number, circuitIndex: number): DPGASimulationInfo {
        return {
            name: `Simulation ${newIndex}`,
            simulation: {
                ...emptySimulation(),
                circuit: this.props.boxes[circuitIndex]
            },
            options: {
                leaks: false,
                time: 10800
            },
            canSimulate: true,
            inputs: this.props.boxes[circuitIndex].getInputPins().map((x) => false),
            circuit: this.props.boxes[circuitIndex],
            circuitIndex: circuitIndex
        }
    }

    handleSelectionChange(index: number) {
        this.setState({
            selectedSimulation: index
        });
    }

    handleAddSimulation(circuitIndex: number) {
        const newSimulationsCount = this.props.simulationsCount + 1;
        this.props.updateSimulations(
            this.props.simulationInfos.concat([this.newSimulationInfo(newSimulationsCount, circuitIndex)])
        );
        this.props.updateSimulationCount(newSimulationsCount);
    }

    handleSimulationUpdate(index: number, simulation: Simulation) {
        this.props.updateSimulations(this.props.simulationInfos.map((sim, i) => {
                if (i === index) {
                    return {
                        ...sim,
                        simulation: simulation,
                        canSimulate: simulation.status !== Status.Pending
                    }
                }
                return sim
            })
        );
    }

    handleOptionsChange(index: number, options: SimulationOptions) {
        this.props.updateSimulations(this.props.simulationInfos.map((info, i) => {
                if (i === index) {
                    return {
                        ...info,
                        options: options
                    }
                }
                return info
            })
        );
    }

    handleInputsChange(index: number, inputs: boolean[]) {
        this.props.updateSimulations(this.props.simulationInfos.map((info, i) => {
            if (i === index) {
                return {
                    ...info,
                    inputs: inputs
                }
            }
            return info
        }));
    }

    getSimulationListItems() {
        return this.props.simulationInfos.map((info) => {
            const oldCircuit = info.circuitIndex >= this.props.boxes.length ||
                this.props.boxes[info.circuitIndex] !== info.circuit;

            return {
                title: info.name,
                subtitle: `${oldCircuit ? 'OLD' : ''} Circuit ${info.circuitIndex + 1} - ${getStatusText(info.simulation.status)}`,
                color: getStatusColor(info.simulation.status)
            }
        })
    }

    handleSimulate(inputs: boolean[], circuit: LogicDiagramEngine, options: SimulationOptions) {
        return Nucleio.runDPGASimulation(inputs, circuit, options.leaks, options.time);
    }

    renderSimulationSelector() {
        return <ListSelector items={this.getSimulationListItems()} title={"Simulations"}
                             onSelectionChange={(i) => this.handleSelectionChange(i)}
                             style={{minHeight: 0, flexGrow: 1, flexShrink: 1}}
                             selectedItem={this.state.selectedSimulation}/>
    }

    renderControlBar() {
        return <div style={{
            width: '200px',
            flexGrow: 0,
            flexShrink: 0,
            display: "flex",
            flexDirection: 'column',
            gap: '20px'
        }}>
            {this.renderSimulationSelector()}
            {<CreateSimulationForm numberOfCircuits={this.props.boxes.length}
                                   handleCreateSimulation={(i) => this.handleAddSimulation(i)}/>}
        </div>
    }

    renderSimulationView() {
        const currentSelection = this.state.selectedSimulation;
        if (currentSelection < 0) {
            return <span style={{
                userSelect: 'none',
                color: 'var(--on-background)'
            }}>No simulation selected. </span>
        }

        return <SimulationView key={currentSelection} disabled={false}
                               inputNames={this.props.simulationInfos[currentSelection].circuit.getInputPins().map((x) => x.label)}
                               simulate={(inputs, options) =>
                                   this.handleSimulate(inputs, this.props.simulationInfos[currentSelection].circuit, options)}
                               onInputsChange={(inputs) => this.handleInputsChange(currentSelection, inputs)}
                               onOptionsChange={(options) => this.handleOptionsChange(currentSelection, options)}
                               onSimulationUpdate={(simulation) => this.handleSimulationUpdate(currentSelection, simulation)}
                               {...this.props.simulationInfos[currentSelection]}/>
    }

    render() {
        return <div style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'row',
            gap: '20px',
            alignItems: 'stretch'
        }}>
            {this.renderControlBar()}
            <div style={{
                flexGrow: 1
            }}>
                {this.renderSimulationView()}
            </div>
        </div>
    }
}