import {TableSchema} from "../TableSchema";
import React from "react";
import {HeaderCellWidget} from "./HeaderCellWidget";
import {ColumnDeleteButton} from "./ColumnDeleteButton";


interface TableHeaderWidgetProps {
    schema: TableSchema
    onColumnRename: (columnIndex: number, newName: string) => void
    handleColumnDelete: (columnIndex: number) => void
    // If *rows* may be deleted. Used to align the content properly.
    rowsDeletable: boolean
}


export class TableHeaderWidget extends React.Component<TableHeaderWidgetProps> {
    get canDeleteColumns() {
        return this.props.schema.entries.length > 1;
    }

    renderCells() {
        return this.props.schema.entries.map(
            (entry, index) => <HeaderCellWidget schemaEntry={entry} columnIndex={index} key={index}
                                                onColumnRename={newName => this.props.onColumnRename(index, newName)}/>)
    }
    renderDeleteButtons() {
        return this.props.schema.entries.map(
            (entry, index) => entry.renamable && this.canDeleteColumns ? (<th key={index}>
                <ColumnDeleteButton key={index} onDeleteColumn={() => {
                this.props.handleColumnDelete(index)
            }}/></th>) : (<th/>)
        )
    }

    render() {
        // Add an extra column in this case to match the delete buttons.
        if (this.props.rowsDeletable) {
            return <thead>
            <tr>
                <th/>
                {this.renderDeleteButtons()}
            </tr>
            <tr>
                <th/>
                {this.renderCells()}
                <th rowSpan={20}/>
            </tr>
            </thead>
        }
        return <thead>
        <tr>
            {this.renderDeleteButtons()}
        </tr>
        <tr>
            {this.renderCells()}
        </tr>
        </thead>
    }
}
