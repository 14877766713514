import * as React from "react";
import {DiagramEngine} from "@projectstorm/react-diagrams";
import {ErrorWidget} from "./ErrorWidget";

interface ErrorWidgetListProps {
    engine: DiagramEngine;
}

interface ErrorWidgetListState {
    hintList: JSX.Element[]
}

interface Dictionary {
    [key: string]: string;
}

const errorMessageDictionary: Dictionary = {
    "CycleDetected": "Components may not be connected in cycles. ",
    "InputToOutput": "Input ports may not be connected to output ports. Please connect in the other direction instead.",
    "InputToInput": "Input ports may not be connected to other input ports. ",
    "OutputToOutput": "Output ports may not be connected to other output ports. ",
    "InputFull": "Each input port may only receive one connection. ",
    "PinToPin": "Pins may not be connected directly to pins. "
}

let errorIdCounter = 0;

export class ErrorWidgetList extends React.Component<ErrorWidgetListProps, ErrorWidgetListState> {
    constructor(props: ErrorWidgetListProps) {
        super(props);

        this.state = {
            hintList: []
        };

        this.props.engine.getModel().registerListener({
            eventDidFire: (event) => {
                this.addErrorEvent(event.function);
            }
        })
    }

    addErrorEvent(eventName: string) {
        if (eventName in errorMessageDictionary) {
            let errorWidgetProps = {
                errorID: `error-${errorIdCounter++}`,
                errorCode: eventName,
                errorString: errorMessageDictionary[eventName],
                engine: this.props.engine,
                removeError: (e) => this.removeError(e)
            }
            let errorWidget = <ErrorWidget {...errorWidgetProps} />;
            this.setState((prevState: ErrorWidgetListState) => ({
                hintList: [...prevState.hintList, errorWidget]
            }));
        }
    }

    removeError(errorID: string) {
        this.setState((prevState: ErrorWidgetListState) => ({
            hintList: [...prevState.hintList.filter((errorWidget) => errorWidget.props.errorID !== errorID)]
        }));
    }

    render() {
        return (
            <div className="error-widget-list">
                {this.state.hintList.map((errorWidget, index) => (
                    <div key={errorWidget.props.errorID}>
                        {errorWidget}
                    </div>
                ))}
            </div>
        )
    }
}
