export enum MaterialColor {
    Primary, Secondary, Tertiary, Error, Normal
}

export function getColorString(color: MaterialColor): string {
    switch (color) {
        case MaterialColor.Primary:
            return 'primary'
        case MaterialColor.Secondary:
            return 'secondary'
        case MaterialColor.Tertiary:
            return 'tertiary'
        case MaterialColor.Error:
            return 'error'
        case MaterialColor.Normal:
            return 'normal'
    }
}

export function getContainerColor(color: MaterialColor): string {
    if (color === MaterialColor.Normal) return 'var(--surface)'
    return `var(--${getColorString(color)}-container)`
}

export function getOnContainerColorString(color: MaterialColor): string {
    if (color === MaterialColor.Normal) return 'var(--on-background)'
    return `var(--on-${getColorString(color)}-container)`
}

export function getHighContainerColor(color: MaterialColor) {
    if (color === MaterialColor.Normal) return 'var(--surface-bright)'
    return `var(--${getColorString(color)})`
}

export function getOnHighContainerColor(color: MaterialColor) {
    if (color === MaterialColor.Normal) return 'var(--on-background)'
    return `var(--on-${getColorString(color)})`
}

